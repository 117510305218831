nav.lyve-tabs {
  line-height: 20px;
  border-bottom: 2px solid var(--gray-6);

  .nav-item.nav-link {
    background-color: transparent !important;
    border: none;
    color: var(--gray-10);
    padding: 0.8125rem 0;
    font-size: 14px;
    font-weight: 500;
    margin-right: 24px;
    transition: border 0.3s, color 0.3s;

    &:hover,&:active {
      color: var(--gray-14);
    }

    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: '';
      height: 2px;
      background-color: transparent;
      display: block;
      position: absolute;
      top: 45px;
      transition: all 1s;
    }
  }

  .nav-item.nav-link.active {
    background-color: transparent !important;
    color: var(--gray-14) !important;
    border-bottom: none !important;
    transition: color 0.3s;
    position: relative;

    &::after {
      content: '';
      height: 2px;
      background-color: var(--gray-14);
      width: 100%;
      display: block;
      position: absolute;
      top: 45px;
    }
  }

  &-compact {
    .nav-item.nav-link {
      padding: 0.3125rem 0;
    }
  }
}

.lyve-tabs + .tab-content {
  padding-top: 24px;
}