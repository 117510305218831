.table-menu {
    .dropdown-menu.show {
        transform: translate3d(-170px, 30px, 0px);
        left: -10px !important;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 1px #e6e6e6;
    }

    .dropdown-toggle::after {
        display:none !important;
    }

    .dropdown-item {
        color: #1a1a1a !important;
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        padding: 8px 16px;
        cursor: pointer;
        font-weight: 400;
        white-space: nowrap;
        font-family: 'Roboto', sans-serif;
        border-bottom: 1px solid #e6e6e6;

        span {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            i {
                font-size: 20px;
                margin-right: 8px;
              }
        }

        &:hover {
            color: #1a1a1a !important;
            background-color: #f5f5f5;  
        }

        &:active {
            color: #1a1a1a !important;
            background-color: #f5f5f5;        
        } 

        &:last-child {
            border-bottom: none;       
        } 
    }

}
