.replication-rules-table .rdt_TableRow {
	min-height: 75px;
	align-items: flex-start;
	&:last-child {
		border-bottom: 0px !important;
	}
	&:hover {
		background: #fff !important;
		outline: none !important;
		&:not(:last-child) {
			border-bottom: 1px solid #ccc !important;
		}
	}
}
