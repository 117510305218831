@mixin grid_design($sidebar-width) {

	// < 1366px
	@media (max-width: 1365px) {
		width: calc(100vw - #{$sidebar-width});

		.mainContent {
			min-width: calc(1365px - #{$sidebar-width});
		}
	}

	// between 1365px and 1920px
	@media screen and (max-width: 1920px) and (min-width: 1365px) {
		width: 100vw;
	}

	// > 1920px
	@media only screen and (min-width: 1920px) {
		width: calc(1920px - #{$sidebar-width});
	}
}

.topbar-right-element {
	align-items: center;

	.topbar-right-icon {
		display: flex;
		line-height: 20px;
		font-size: 14px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: #fafafa;
		border-radius: 4px;
		padding: 0px 10px;
		height: 32px;

		.topbar-right-label {
			margin-left: 8px;
			color: #fafafa;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 276px;

			&:hover {
				font-weight: 400;
			}
		}

		&.topbar-pressed-icon {
			background-color: #4d4d4d;
		}

		&:hover {
			background-color: #333333;
		}

		.f-logout-tooltip {
			margin-top: 18px !important;
			width: auto;
			height: auto;
			background-color: #333333;
			padding: 10px 10px;
			color: #ffffff;
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;
		}

		.f-logout-tooltip::after {
			border-bottom-color: #333333 !important;
		}
	}

	.option-header {
		font-size: 14px;
		color: #1a1a1a;
		font-weight: 600;
		line-height: 20px;
		font-family: 'Roboto', sans-serif;
		white-space: break-spaces;
	}

	.option-title {
		font-size: 14px;
		color: #1a1a1a;
		font-weight: 400;
		line-height: 20px;
		font-family: 'Roboto', sans-serif;
		padding-bottom: 2px;
		white-space: break-spaces;
	}

	.expiration-status {
		padding-top: 8px;
		font-size: 12px;
		color: var(--gray-10);
		line-height: 16px;

		&-danger {
			color: #F93946;
		}

		&-dropdown.dropdown-menu.show {
			right: calc(100% + 16px);
			top: -40px;
		}

		&-container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			i {
				color: #497F31 !important;
			}

			svg {
				height: 20px;
				width: 20px;
				color: var(--gray-14);
			}

			.default-button.btn.btn-link {
				font-size: 12px;
			}
		}
	}

	.option-subtitle {
		color: #616161;
		font-size: 12px;
		line-height: 18px;
	}

	.dev-version {
		display: flex;
		font-size: 12px;
		line-height: 18px;
		color: var(--gray-15);
	}

	.option-error {
		color: #F93946;
		font-size: 12px;
		line-height: 16px;
		white-space: nowrap;
	}
}

.lyve-topbar-dropdown {
	.dropdown-menu.show {
		left: auto;
		top: 0px;
		background-color: #ffffff;
		padding: 10px;
		box-sizing: border-box;
		border-radius: 4px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, .12);
		overflow-y: unset;
		min-width: 240px;
		max-width: 320px;
	}

	.dropdown-toggle::after {
		display: none !important;
	}

	.dropdown-menu {
		border: none;
		max-height: none;
		overflow-y: unset;
	}

	.dropdown-header {
		padding: 6px 10px;
		font-size: 10px;
		min-height: 32px;
	}

	.dropdown-divider {
		margin: 10px 10px 10px;
		border-top: 1px solid #e6e6e6;
	}

	.dropdown-item {
		min-height: 32px;
		font: unset;
		padding: 6px 10px;
		font-size: 10px;
		white-space: nowrap;
		//overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			color: #1a1a1a;
			background-color: #ffffff;
		}

		&:active {
			color: #1a1a1a;
			background-color: #ffffff;
		}
	}

	.dropdown-action {
		cursor: pointer;

		&:hover {
			color: #1a1a1a;
			background-color: #f5f5f5;
			border-radius: 4px;
		}

		&:active {
			color: #1a1a1a;
			background-color: #f0f0f0;
			border-radius: 4px;
		}
	}
}

.click-here-link {
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
}

.lyve-global-sidebar {
	background-color: #ffffff;
	font-family: "Roboto", sans-serif;
	height: calc(100vh - var(--top-navbar-height));
	overflow: auto;
	box-sizing: border-box;
	flex: 0 0 136px;

	&+.lyve-page-wrapper {
		@include grid_design(136px);
	}
}

.smart-start-kit-tooltip {
	z-index: 10;
}

#expiration-dropdown:before {
	display: none;
}