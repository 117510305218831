.lyve-dropdown {
    .dropdown-menu.show {
        left: auto;
        top: 0;
        right: 0;
        background-color: #ffffff;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0px 10px 20px rgba(0,0,0,.12);
        overflow-y: unset !important;
    }

    .dropdown-toggle::after {
        display: none !important;
    }

    .dropdown-menu {
        border: none;
        overflow-y: unset !important;
    }

    .dropdown-header {
        padding: 6px 10px;
        font-size: 10px;
        min-height: 32px;
    }

    .dropdown-divider {
        margin: 10px;
        border-top: 1px solid #e6e6e6;
    }

    .dropdown-item {
        min-height: 32px;
        font: unset;
        padding: 6px 10px;
        font-size: 10px;
        white-space: nowrap;
        //overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            color: #1a1a1a;
            background-color: #ffffff;  
        }

        &:active {
            color: #1a1a1a;
            background-color: #ffffff;        
        } 
    }

    .dropdown-action {
       cursor: pointer;

        &:hover {
            color: #1a1a1a;
            background-color: #f5f5f5;
            border-radius: 4px;
        }

        &:active {
            color: #1a1a1a;
            background-color: #f0f0f0;   
            border-radius: 4px;     
        } 
    }
}

.popover.smart-start-popover {
    transform: translate3d(calc(100vw - 625px), 173px, 0) !important;
}
