.lyve-breadcrumb {
    margin-bottom: 16px;
    .breadcrumb {
        padding:0;
        margin-bottom: 0;

        .breadcrumb-item {
            line-height: 20px;
            a {
                color: #737373;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                &.active {
                    color: #1a1a1a;
                    font-weight: 500;
                }

                &:active {
                    color: #1a1a1a;
                    text-decoration: underline;
                }
    
                &:hover {
                    color: #4D4D4D;
                    text-decoration: underline;
                }
            }

            &.active {
                color: #1a1a1a;
                font-weight: 500;
            }
            
        }
    
        .breadcrumb-item + .breadcrumb-item::before {
            color: #737373;
            content: ">";
        }
    }
}

.lyve-heading-breadcrumb {
    margin-bottom: 32px;
    h1 {
        color: #1A1A1A;
        font-size: 24px;
        line-height: 32px;
        font-weight: 300;
    }
}

.lyve-breadcrumb-section {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    width: 75%;
    height: 20px;

    .breadCrumb-tooltip {
        max-width: 350px;
        word-break: break-all;
    }

    .breadcrumb-link {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            text-decoration: underline;
            color: #4d4d4d;
            cursor: pointer;
        }

        &.active {
            color: #253F18;
            font-weight: 500;
        }
    }

    svg {
        width: 16px !important;
        height: 16px !important;
        margin: 0 2px 0 2px;
    }

    .dots-icon {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        &:hover {
            background-color: #cccccc;
            border-radius: 4px;
        }
    }

    .breadCrumb-card {
        top: 90%;
        left: 50%;
        z-index: 1;
        position: absolute;
        background-color: var(--white);
        border-radius: 4px;
        box-shadow: 0px 10px 20px #0000001F;
        max-width: 320px;
        max-height: 320px;
        min-width: 200px;
        overflow: auto;
    }
    
    .breadCrumb-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 10px;
        background-color: var(--white);
    }
    
    .breadCrumb-title {
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        color: #1a1a1a;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 305px;
        padding: 6px 10px;

        &:hover {
            background-color: #f0f0f0;
            cursor: pointer;
        }
    }
}