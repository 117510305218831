.lyve-date-range-select {
  width: 224px;

  .lyve-select__control {
    min-height: 40px;
  }

  .custom-menu-wrapper {
    display: flex;
  }
}

.custom-datepicker-wrapper {
  padding: 20px;
  border-right: 1px solid var(--gray-5);
}
