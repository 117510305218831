.buckets-container {
  --container-top-margin: 32px;

  width: 100%;
  margin-top: var(--container-top-margin);
  margin-bottom: var(--container-top-margin);

  .add-icon,
  .delete-icon {
    margin-right: 4px;
  }

  .no-data-text {
    font-weight: 300;
    margin: 40px 0 12px;
  }

  .create-bucket-card {
    width: 325px;

    .card-body {
      padding: 28px;
    }
  }

  .data-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      color: var(--green-3);
      width: 48px;
      height: 48px;
    }
  }

  a.caseNumber {
    font-weight: 500;
  }
}

.loading-error-bucket-state-modal {
  &.modal-dialog-centered {
      top: 10px;
      align-items: flex-start;
   }
   
  .title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      color: var(--gray-14);
  }

  .subtitle {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: var(--gray-14);
      margin-top: 28px;
      margin-bottom: 26px;

      span {
        color: #6EBE49; 

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
  } 
}
