@mixin base_checkbox_style {
  width: 16px;
  height: 14px;
  margin-bottom: 2px;
  display: inline-block;
  visibility: hidden;
  border: 1px solid var(--gray-7);
  appearance: initial;
  -moz-appearance: initial;
}

.lyve-datatable-loading {
  .rdt_TableHead ~ div {
    min-height: 250px;
  }
}

.lyve-table-card {
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  overflow: auto;

  button[aria-label = 'First Page'] {
    pointer-events: none;
  }

  button[aria-label = 'Last Page'] {
    pointer-events: none;
  }

  .rdt_TableCell,
  .rdt_TableCol {
    label.custom-check {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 5px;
      margin: 0;
    }

    .form-check-input {
      position: static;
    }

    .custom-check.checkbox {
      border-radius: 4px;
      background-color: var(--white);
      border: 1px solid var(--gray-7);
    }

    input[type=checkbox] {
      background-color: var(--white);
      border-radius: 4px;
      @include base_checkbox_style;

      &:disabled {
        background-color: var(--gray-7);
      }

      &:checked + .custom-check.checkbox {
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z'/></svg>");
        background-size: 14px 16px;
        background-repeat: no-repeat;
        background-color: var(--green-3);
        border-color: var(--green-3);
      }
  
      &:disabled:checked + .custom-check.checkbox {
        background-color: var(--gray-7);
        border-color: var(--gray-7);
      }
    }
  }

  .rdt_TableCol input[type=checkbox] {
    visibility: visible;
  }

  // for overrides
  .rdt_TableCol_Sortable {
    &:hover,
    &:hover:not(:focus) {
      color: var(--gray-14);
      opacity: 1;

      span {
        opacity: 1;
      }
    }

    span.__rdt_custom_sort_icon__ {
      &.asc,
      &.desc {
        margin-left: 6px;
      }

      svg {
        font-size: inherit !important;
        width: inherit !important;
        height: inherit !important;
      }
    }
  }
}

.single-select-table {
  min-height: unset !important;
  
  .rdt_TableCell,
  .rdt_TableCol {
    label.custom-check {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 5px;
      margin: 0;
    }

    .form-check-input {
      position: static;
    }

    .custom-check.checkbox {
      border-radius: 50% !important;
      background-color: var(--white);
      border: 1px solid var(--gray-7);
    }

    input[type=checkbox] {
      background-color: var(--white);
      border-radius: 50% !important;
      @include base_checkbox_style;

      &:disabled {
        background-color: var(--gray-7);
      }

      &:checked + .custom-check.checkbox {
        background-image: unset !important;
        background-size: unset !important;
        background-repeat: unset !important;
        background-color: unset !important;
        border: 5px solid var(--green-3) !important;
      }
  
      &:disabled:checked + .custom-check.checkbox {
        background-color: var(--white) !important;
        border-color: var(--gray-7);
      }
    }
  }

  .rdt_TableCol input[type=checkbox] {
    visibility: visible !important;
  }
}
  