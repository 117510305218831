@import "../../../../../../theme/mixins.scss";

.create-service-account-module {
	.create-service-account-title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 24px;
		line-height: 26px;
		color: var(--gray-14);
	}

	.form-group {
		margin-bottom: 0;
	}

	.form-section {
		margin-bottom: 24px;
	}

	.form-input-label {
		@include font_label;
		margin-bottom: 8px;
	}

	.allow-all-access {
		padding: 9px;
	}

	.validation-container {
		margin: 4px 0 8px;
	}

	.validation-item {
		display: flex;
		align-items: flex-start;
		font-size: 14px;
		color: var(--gray-12);
	}

	.validation-error {
		font-size: 14px;
		color: var(--red-3);
		margin-top: 6px;
	}

	.validation-icon {
		font-size: 14px;
		margin-right: 6px;
		line-height: 20px;
		color: var(--gray-6);
		flex: 0 0 14px;
		margin-top: 3px;

		&.valid {
			color: var(--green-3);
		}
	}

	.submit-button {
		font-size: 14px;
		text-transform: capitalize;
		padding: 10px 16px;
		font-weight: 500;
		line-height: 20px;
		background-color: var(--green-3);
	}

	.spinner {
		width: 20px;
		height: 20px;
	}

	.lyve-table-card {
		box-shadow: none;
	}

	.all-access {
		padding-left: 2px;
	}

	a.helplink {
		font-size: 14px;
		font-weight: normal;
		color: #6ebe49 !important;
		text-decoration: none;
	}

	a.helplink:hover {
		color: #5ca63a !important;
		// text-decoration: underline;
		cursor: pointer;
	}

	a.helplink:active {
		color: #438027 !important;
		// text-decoration: underline;
	}

	.expires-wrapper {
		margin-bottom: 25px;
		.expires-col {
			margin-left: 46px;
		}

		.sa-key {
			font-size: 14px;
			line-height: 16px;
			color: var(--gray-12);
			margin-bottom: 10px;
		}

		.sa-value {
			font-size: 16px;
			line-height: 20px;
			color: var(--gray-14)
		}
    }

	.question-mark-icon {
        margin-left: 8px;
    }

    .secret-key-expiration-duration-tooltip {
        width: 400px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
		background: #000000;
		color: #fff;
		padding: 4px 8px !important;
    }
}
