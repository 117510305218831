.toggle-container {
  display: flex;
  flex-direction: row;
  width: 64px;
  background-color: #65b64a;
  cursor: pointer;
  user-select: none;
  border-radius: 25px;
  padding: 2px;
  height: 25px;
  position: relative;
}
.toggle-container-off {
  background-color: #B3B3B3;
}
.toggle-container .on {
  font-size: 14px;
  font-weight: 400;
  padding: 0 8px;
  color: white;
}
.toggle-container .off {
  font-size: 14px;
  font-weight: 400;
  /* padding: 5px 5px; */
  color: white;
  padding-left: 26px;
}

.toggle-dialog-button {
  height: 20px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: unset;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 40px;
  transition: all 0.3s ease;
}

.toggle-disabled {
  background-color: #FFFFFF;
  left: 3px;
}
