.subaccount-metrics {
  display: flex;
  background: white;
  padding: 10px 16px;
  margin-bottom: 16px;
  height: 66px;
  border-radius: 4px;

  .metrics-column {
    margin-right: auto;
  }

  p {
    margin-bottom: 0;
  }

  .column-title {
    font-size: 14px;
    color: #4D4D4D;
    margin-bottom: 6px;
    line-height: 20px;
  }

  .column-value {
    color: var(--gray-14);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    span {
      color: #4D4D4D;
      font-weight: normal;
      font-size: 14px;
      margin-left: 10px;
    }
  }
}

.main-header {
  margin-bottom: 4px;
  font-size: 24px;
  display: block;
  line-height: 32px;
  color: var(--gray-14);
  font-weight: 300;
}

.sub-header {
  color: var(--gray-10);
  font-size: 16px;
}

.subaccount-view-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .lyve-date-range-select {
    margin-right: 10px;
  }

  .dashboard-graph-title {
    font-size: 20px;
    line-height: 26px;
    color: var(--gray-14);
    font-weight: 300;

    span {
      display: inline-block;
    }
  }
}

a.nav-link.active {
  border-top: 0 !important;
  border-bottom: 3px solid #707070 !important;
}

.nav-tabs {
  background-color: #f5f5f5 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0px !important;
}

.nav-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 24px !important;
}

.setings-info-row {
  display: flex;
}

.org-info-card {
  .settings-info-block {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.settings-info-block {
  margin-right: 40px;

  .settings-info-title {
    font-size: 14px;
    color: #4D4D4D;
    margin-bottom: 6px;
    line-height: 20px;
  }

  .settings-info-value {
    color: var(--gray-14);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }


}

.last {
  height: 48px
}

.settings-trial {
  display: flex;
  align-items: center;
  color: var(--gray-9);
  font-size: 14px;
  line-height: 20px;
}

.danger-zone-title {
  font-weight: 300;
  line-height: 26px;
  color: var(--gray-14);
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.action-title {
  display: flex;
  justify-content: space-between;

  .header {
    padding-bottom: 8px;
    color: var(--gray-14);
  }

  button {
    height: 40px;
  }
}

.account-subaccount-wrapper {
  margin-top: 32px;
  margin-bottom: 32px;

  .card-deck:last-child {
    margin-bottom: 0;
  }

  .card-deck {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .data-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      color: var(--green-3);
      width: 48px;
      height: 48px;
    }
  }
}
