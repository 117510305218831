@import "../../theme/mixins";

.custom-dropzone-container{
    @media (min-width: 576px) {
        max-width: 100%;  
    }    
    .dropzone{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        height: 84px;
        border-width: 1px;
        border-radius: 3px;
        border: 1px dashed var(--gray-6);
        overflow: hidden;
        outline: none;
        opacity: 1;
        transition: border .24s ease-in-out;
        cursor: pointer;
        &:hover{
            background: var(--gray-2)  0% 0% no-repeat padding-box;
            border: 1px dashed var(--gray-12);
        }
    }
    .dropzone.on-drag-style{
        background: var(--green-1) 0% 0% no-repeat padding-box;
        border: 1px solid var(--green-3);
        opacity: 0.45 !important;
        &:hover{
            background: var(--green-1)  0% 0% no-repeat padding-box;
            border: 1px solid var(--green-3);
            opacity: 45%;
        }
    }
    p{
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: 500;
        letter-spacing: 0px;
        font: normal normal medium 14px/20px Roboto;
        a{
            color: var(--green-3);
            &:hover{
                color: var(--green-4);
            }
        }  
    }
}

