$card-secondary-color: var(--gray-9);

@mixin icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  &:hover {
    background-color: var(--gray-11);
  }
  &:active {
    background-color: var(--gray-10);
  }
}

@keyframes step-scale-in {
  from { transform: scaleX(0) }
  to { transform: scaleX(1) }
}

@keyframes step-slide-in {
  from { transform: translateX(-30px); opacity: 0 }
  to { transform: translateX(0); opacity: 1 }
}

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

.modal.fade.add-bucket-card-modal-backdrop {
  z-index: 1030;
}

.add-bucket-card {
  .card-img {
    height: 420px;
  }

  .overlay {
    display: flex;
    flex-flow: column;
    color: white;
    padding: 47px 32px 30px;
    height: 100%;

    .overlay-btn {
      @include icon-button;
      color: var(--gray-8);
      padding: 0;
      &:focus {
        outline: none;
      }
      &-disabled {
        cursor: not-allowed;
      }
    }

    .title {
      padding-bottom: 4px;
      div {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  &-modal {
    min-width: 750px;

    .modal-body {
      padding: 0;
      .card {
        background: none;
      }
      .card-img {
        border-radius: 12px;
      }
    }
    .modal-content {
      border: none;
      background: none;
    }
  }

  .process-bucket {
    padding-top: 41px;
    padding-bottom: 54px;
  }

  .divider {
    border-top: 2px solid var(--gray-9);
    width: 64px;
    padding-bottom: 24px;
    &-animation {
      animation: step-scale-in 0.4s;
    }
  }

  .step {
    display: flex;
    margin-right: 88px;

    &:last-child {
      margin-right: 0;
    }

    &-text-animation {
      animation: step-slide-in 0.4s;
    }

    &-animation {
      animation: fade-in 0.4s;
    }

    .icon-container {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
    &-spinner {
      color: var(--black);
      width: 1rem;
      height: 1rem;
      border-width: 2px;
    }
    &-wip {
      .icon-container {
        background-color: var(--green-3);
        svg {
          fill: var(--black);
        }
      }
      .step-state {
        & > div {
          color: var(--green-3);
        }
      }
    }
    &-done {
      .step-state {
        & > div {
          color: var(--green-3);
        }
      }
    }
    &-default {
      .icon-container {
        background-color: var(--gray-13);
        color: var(--gray-7);
      }
      .step-state {
        & > div {
          color: var(--gray-9);
        }
      }
    }

    &-state {
      display: flex;
      flex-flow: column wrap;
      padding-left: 10px;

      & > div:first-child {
        font-size: 11px;
        height: 13px;
        line-height: 13px;
        font-weight: 500;
      }

      & > div:last-child {
        font-size: 16px;
        height: 19px;
        line-height: 19px;
        font-weight: 500;
      }
    }
  }

  .info {
    border-top: 1px solid var(--gray-9);
    padding-bottom: 29px;

    &-primary {
      color: var(--green-3);
      padding-bottom: 16px;
      font-weight: 500;
    }
    &-secondary {
      padding-bottom: 26px;
    }
  }

  .next-btn {
    margin: auto auto 0 0;
  }

  //  show bucket info
  .pair-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 581px;
    margin-left: 3.5px
  }

  .subtitle {
    color: var(--gray-8);
  }

  .note {
    padding-top: 40px;
    padding-bottom: 32px;
    color: var(--green-3);
    font-weight: 500;
    margin-bottom: 0;
  }

  .pair {
    height: 32px;
    padding-bottom: 8px;
    &-key {
      color: var(--gray-8);
    }
  }
  .downloads {
    padding-top: 16px;
    button {
      padding: 10px 17px;
      height: 40px;
      font-weight: 500;
      a {
        color: inherit;
        font-weight: inherit;
      }
    }
  }

  .icon-btn {
    @include icon-button;
    color: var(--gray-4);
    margin-left: 14px;
    svg {
      width: 14px;
      height: 14px;
    }
    &:focus {
      outline: none;
    }
  }
}

.modal-dialog.exit-modal {
  .modal-body {
    .confirm-btn {
      margin-right: 16px;
    }

    .confirmation {
      padding-bottom: 24px;
    }
  }
}

.bucket-error {
  padding: 47px 32px;

  .msg {
    color: var(--white);
    padding-bottom: 26px;
    &-err {
      color: var(--red-3);
      padding-top: 43px;
      padding-bottom: 21px;
    }
  }
}

.bucket-celebration {
  &-title {
    padding-top: 18px;
    color: var(--gray-14);
    font-weight: 500;
  }
  &-subtitle {
    color: var(--gray-14);
  }
}

.base-card-loading {
  background: #FFF6DB;
  color: #8F6B00;
  padding: 13px 16px 10px 16px ;
  font-size: 16px;
  border: 1px solid #F0E4C0;
  border-radius: 4px;
}