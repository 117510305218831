.lyve-popover-primary.bs-popover-left > .arrow::before, .lyve-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-left > .arrow::after, .lyve-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-right > .arrow::before, .lyve-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-right > .arrow::after, .lyve-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-top > .arrow::before, .lyve-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-top > .arrow::after, .lyve-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-bottom > .arrow::before, .lyve-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: var(--black) !important;
}

.lyve-popover-primary.bs-popover-bottom > .arrow::after, .lyve-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: var(--black) !important;
}

.popover.lyve-popover {
  padding: 10px 16px;
  max-width: 336px;

  &-primary {
    background-color: var(--black);
    color: var(--white);
  }

  //&-bottom {
  //  padding: 5px 10px;
  //  font-size: small;
  //  margin-left: -5px;
  //}
  //&-top {
  //  padding: 5px 10px;
  //  font-size: small;
  //  margin-left: -5px;
  //}
  //&-left {
  //  padding: 5px 10px;
  //  font-size: small;
  //  margin-left: -5px;
  //}
}
