.lyve-goodbye {
    padding: 75px 22px;
    min-height: 100vh;
    background: url("./../../images/Lyve_Brand_Pattern_116-2560x1160.png") center bottom no-repeat;
    background-position-y: 24%;
  
    .info {
      max-width: 75%;
    }
  
    .title {
      padding-top: 100px;
      font-size: 36px;
      line-height: 44px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 500;
    }
  
    .subtitle {
      font-size: 22px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.7);
      margin: 1em 0 0;
      font-weight: 400;
  
      a {
        font-weight: 400;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  