.service-accounts-container {
  --container-top-margin: 32px;

  width: 100%;
  margin-top: var(--container-top-margin);
  margin-bottom: var(--container-top-margin);

  .add-icon,
  .delete-icon,
  .edit-icon,
  .view-icon,
  .key-icon{
    margin-right: 4px;
  }

  .no-data-text {
    font-weight: 300;
    margin: 40px 0 12px;
  }

  .status-spinner {
    color: var(--green-3);
  }

  .data-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      color: var(--green-3);
      width: 48px;
      height: 48px;
    }
  }

  .prerequisite-text {
    color: var(--gray-13);
    font-size: 20px;
    font-weight: 300;
    margin: 40px 0 12px;
  }


  .permission-link,
  .permission-link:hover {
    color: var(--green-4);
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
  }
}

.sa-expired {
  color: var(--red-3)
}
