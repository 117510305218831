.partner-support-wrapper {
    margin-top: 32px;
  
    .ticket-filter {
      width: 200px;
    }
   
    .filter-div {
      display: flex;
      align-items: center;
    }
  
    .filter-span {
      margin-right: 8px;
    }
  }
  
.support-ticket-count-root {
  margin-bottom: 32px;

  .metric-card {
    min-height: 121px;
  }

  .metric-value-section {
    display: flex;
    align-items: flex-start;
  }

  .label-section .label {
    margin-right: 10px;
  }

  .refresh-icon {
    margin: 10px 5px;
    cursor: pointer;
  }

  .subtext {
    color: #1A1A1A;
    font-size: 32px;
    font-weight: 400;
  }
}
