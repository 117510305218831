@import "../../../../../../theme/mixins.scss";

.create-permission-module {
	.create-permission-title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 24px;
		line-height: 26px;
		color: var(--gray-14);
	}

	.form-group {
		margin-bottom: 0;
	}

	.form-section {
		margin-bottom: 24px;
	}

	.form-input-label {
		@include font_label;
		margin-bottom: 8px;
	}

	.validation-container {
		margin: 4px 0 16px;
		padding: 1px;
	}

	.validation-item {
		display: flex;
		align-items: center;
		margin-right: 4px;
		color: var(--gray-11);
		margin-bottom: 8px;
		font-size: 14px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.validation-error {
		font-size: 14px;
		color: var(--red-3);
		margin-top: 6px;
	}

	.validation-icon {
		font-size: 14px;
		margin-right: 3px;
		color: var(--gray-6);
		flex: 0 0 14px;
		margin-top: 1px;

		&.valid {
			color: var(--green-3);
		}
	}

	a.helplink {
		font-weight: normal;
		font-size: 14px;
		color: #6ebe49 !important;
		text-decoration: none;
	}

	a.helplink:hover {
		font-size: 14px;

		color: #5ca63a !important;
		// text-decoration: underline;
		cursor: pointer;
	}

	a.helplink:active {
		font-size: 14px;
		color: #438027 !important;
		// text-decoration: underline;
	}

	.submit-button {
		font-size: 14px;
		text-transform: capitalize;
		padding: 10px 16px;
		font-weight: 500;
		line-height: 20px;
		background-color: var(--green-3);
	}

	.spinner {
		width: 20px;
		height: 20px;
	}

	.bucket-permission-description {
		position: absolute;
		left: -25px;
		width: calc(100% + 50px);
		background-color: var(--gray-11);
		border-radius: 0 0 4px 4px;
		overflow-x: auto;
		padding: 16px 24px;
		margin-top: 20px;

		label {
			color: var(--gray-6);
			font-size: 14px;
			margin-bottom: 4px;
		}

		.value {
			color: var(--white);
			font-size: 16px;
			line-height: 1.375;
			margin: 0;
		}
	}

	.label-text {
		text-transform: none;
	}

	.warningText {
		color: var(--red-3);
		font-size: 14px;
		margin-right: 1rem;
	}
}
