@mixin btn-common {
    font-size: 14px;
    border-radius: 4px;
    height: 40px;
    font-weight: 500;
    line-height: 20px;
    font-family: "Roboto", sans-serif;
    padding: 10px 16px;
    text-align: center;
    text-transform: capitalize;
    min-width: 64px;
}

.lyve-btn-group {
    .btn {
        border: 0;
    }

    .enable-btn {
        &.btn-group>.btn-group:not(:first-child), 
        &.btn-group>.btn:not(:first-child) {
            margin-left: 0!important;
        }

        &.btn-light:not(:disabled):not(.disabled).active,
        &.btn-light:not(:disabled):not(.disabled):active,
        &.show>.btn-light.dropdown-toggle,
        &.btn-light:not(:disabled):not(.disabled).is-active{
            color: #fff;
            background-color: #6ebe49;
            border-color: #6ebe49;
        }
    }

    .disable-btn{
        &.btn-group>.btn-group:not(:first-child), 
        &.btn-group>.btn:not(:first-child) {
            margin-left: 0;
        }

        &.btn-light:not(:disabled):not(.disabled).active,
        &.btn-light:not(:disabled):not(.disabled):active,
        .show>.btn-light.dropdown-toggle,
        &.btn-light:not(:disabled):not(.disabled).is-active {
            background-color: #dc1f2e;
            border-color: #dc1f2e;
            color: #ffffff;
        }
    }
}


button.default-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.btn-primary {
        color: #fff;
        background-color: #6ebe49;
        border-color: #6ebe49;
        font-size: 14px;
        border-radius: 4px;
        height: 40px;
        font-weight: 500;
        line-height: 20px;
        font-family: "Roboto", sans-serif;
        padding: 10px 16px;
        text-align: center;
        text-transform: capitalize;
        min-width: 64px;
        border:0 !important;
    
        i, svg {
            margin-right: 4px;
        }
    
        &:hover, &:focus {
            background-color: #5ca63a;
            border-color: #5ca63a;
        }
    
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background-color: #438027;
            border-color: #438027;
            color: #ffffff;
        }
    
        &:disabled, &.disabled {
            background-color: #6ebe49;
            border-color: #6ebe49;
            opacity: 0.45;
            cursor: not-allowed;
        }

        &.btn-sm {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 4px;
            height: 32px;
            min-width: 52px;
    
            svg {
                height: 15px;
                width: 15px;
            }
        }
    }
    
    &.btn-secondary {
        color: #4d4d4d;
        background-color: transparent;
        border-color: #4d4d4d;
        font-size: 14px;
        border-radius: 4px;
        height: 40px;
        font-weight: 500;
        line-height: 20px;
        font-family: "Roboto", sans-serif;
        padding: 10px 16px;
        text-align: center;
        text-transform: capitalize;
        border: 0;
    
        i, svg {
            margin-right: 4px;
        }
    
        &:hover,
        &:focus,
        &:active,
        &.disabled,
        &:disabled {
            background-color: transparent;
            border: none;
            color: #4d4d4d;
        }
    
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &.show > &.dropdown-toggle {
            color: #4d4d4d;
            background-color: transparent;
            border: none;
        }

        &.btn-sm {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 4px;
            height: 32px;
            min-width: 52px;
    
            svg {
                height: 15px;
                width: 15px;
            }
        }
    }

    &.btn-dark {
        color: #000000;
        background-color: #6ebe49;
        border-color: #6ebe49;
        @include btn-common;

        i, svg {
            margin-right: 4px;
        }

        &:hover, &:focus {
            background-color: #81D959;
            border-color: #81D959;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background-color: #97F26D;
            border-color: #97F26D;
            color: #000000;
        }

        &:disabled, &.disabled {
            background-color: #6ebe49;
            border-color: #6ebe49;
            opacity: 0.45;
            cursor: not-allowed;
        }

        &.btn-sm {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 4px;
            height: 32px;
            min-width: 52px;

            svg {
                height: 15px;
                width: 15px;
            }
        }
    }

    /** Begin outline buttons */
    &.btn-outline-secondary {
        color: #737373;
        background-color: transparent;
        border-color: #4d4d4d;
        color: #4d4d4d;
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 40px;
        font-weight: 500;
        line-height: 20px;
        font-family: "Roboto", sans-serif;
        padding: 10px 16px;
        min-width: 64px;
    
        i, svg {
            margin-right: 4px;
        }
    
        &:hover,
        &:focus {
            background-color: #4d4d4d;
            border-color: #4d4d4d;
            color: #ffffff;
        }
    
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
            background-color: #1a1a1a;
            border-color: #1a1a1a;
            color: #ffffff;
        }
    
        &.disabled,
        &:disabled {
            background-color: transparent;
            border-color: #4d4d4d;
            color: #4d4d4d;
            opacity: 0.45;
            cursor: not-allowed;
        }
    
        &.btn-sm {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 4px;
            height: 32px;
            min-width: 52px;
    
            svg {
                height: 15px;
                width: 15px;
            }
        }
    }

    &.btn-outline-light {
        color: #f0f0f0;
        background-color: transparent;
        border: 1px solid #f0f0f0;
        @include btn-common;

        i, svg {
            margin-right: 4px;
        }

        &:hover, &:focus {
            background-color: #f0f0f0;
            color: #000000;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background-color: #fff;
            color: #000000;
        }

        &:disabled, &.disabled {
            background-color: #6ebe49;
            border-color: #6ebe49;
            opacity: 0.45;
            cursor: not-allowed;
        }

        &.btn-sm {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 4px;
            height: 32px;
            min-width: 52px;

            svg {
                height: 15px;
                width: 15px;
            }
        }
    }
    /** End outline buttons */
    
    /** Begin negative buttons */
    &.btn-danger {
        background-color: #dc1f2e;
        border-color: #dc1f2e;
        color: #ffffff;
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 40px;
        font-weight: 500;
        line-height: 20px;
        font-family: "Roboto", sans-serif;
        padding: 10px 16px;
        min-width: 64px;
        border: 0;
    
        i, svg {
            margin-right: 4px;
        }
    
        &:hover,
        &:focus {
            background-color: #c21322;
            border-color: #c21322;
        }
    
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
            background-color: #990915;
            border-color: #990915;
        }
    
        &.disabled,
        &:disabled {
            background-color: #dc1f2e;
            border-color: #dc1f2e;
            opacity: 0.45;
        }

        &.btn-sm {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 4px;
            height: 32px;
            min-width: 52px;
    
            svg {
                height: 15px;
                width: 15px;
            }
        }
    }
    /** End negative buttons */
}

.default-button.btn.btn-link {
    padding: 0;
    height: unset;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    border: none;
    color: #497F31 !important;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        color: #438027;
        font-weight: 500;
    }
}