@mixin base-link {
  color: var(--gray-14);
  font-size: 14px;
  display: flex;
  align-items: center;
}

@mixin base-card-button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  @include base-link;
}

.modal-dialog.video-card-dialog {
  max-width: 788px;
  width: 788px;
  height: max-content;

  .modal-content {
    border: none;
    background-color: transparent;
  }
  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
  }
  button {
    background-color: var(--gray-14);
    border: none;
    height: 40px;
    width: 40px;
    padding: 0;
  }
}

.card.resources-card {
  .links {
    padding-bottom: 8px;

    a {
      font-size: 14px;
      color: var(--gray-14);
      &:hover {
        font-weight: normal;
        text-decoration: underline;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
  }
}

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes fade-out {
  from { opacity: 0 }
  to { opacity: 1 }
}

.card.sthree-clients-card {
  .card-body {
    padding: 0;
  }
}

.card-link button.base-card-link, .card button.base-card-link {
  @include base-card-button;
  &:hover {
    font-weight: normal;
    text-decoration: underline;
  }
}

.card-link button.base-card-footer-link, .card button.base-card-footer-link {
  @include base-card-button;
  padding-top: 18px;
  &:hover {
    font-weight: normal;
    text-decoration: underline;
  }
}

.card-link a.base-card-link, .card a.base-card-link {
  @include base-link;
  &:hover {
    font-weight: normal;
    text-decoration: underline;
  }
}

.card-link a.base-card-footer-link, .card a.base-card-footer-link {
  @include base-link;
  padding-top: 18px;
  &:hover {
    font-weight: normal;
    text-decoration: underline;
  }
}

button.default-button.base-card-btn {
  padding: 8px 13px;
  height: 32px;
  margin-right: 16px;
}
