.delete-bucket-module {
  .delete-bucket-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 26px;
    color: var(--gray-13);
    overflow-wrap: break-word;
  }

  .delete-bucket-title2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 24px;
    color: var(--gray-13);
    overflow-wrap: break-word;
  }

  .delete-bucket-subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
 
  .bucket-label {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-10);
    margin-bottom: 8px;
  } 

  .bucket-name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 32px;
    overflow-wrap: break-word;
  }

  .bucket-delete-error{
    background-color: #ffebed;
    font-size: 15px;
    color: #c21322;
    border-radius: 5px;
    border: 1px solid #c21322;
    margin-bottom: 24px;
  }

  .validation-error {
    font-size: 11px;
    color: var(--red-3);
    margin-top: 6px;
  }

  .spinner {
    width: 20px;
    height: 20px;
  }
}