.permissions-container {
  --container-top-margin: 32px;

  width: 100%;
  margin-top: var(--container-top-margin);
  margin-bottom: var(--container-top-margin);

  .create-permission-modal-btn{
    margin-right: 10px;
  }

  .add-icon,
  .delete-icon,
  .edit-icon {
    margin-right: 4px;
  }

  button.default-button.btn-outline-secondary .upload-icon {
    margin-right: 10px ;
  }

  .no-data-text {
    font-weight: 300;
    margin: 40px 0 12px;
  }

  .data-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      color: var(--green-3);
      width: 48px;
      height: 48px;
    }
  }

  .prerequisite-text {
    color: var(--gray-13);
    font-size: 20px;
    font-weight: 300;
    margin: 40px 0 12px;
  }


  .bucket-link,
  .bucket-link:hover {
    color: var(--green-4);
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
  }

  .serviceAccountCard{
    top: 70%;
    left: 50%;
    z-index: 1;
    position: absolute;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    max-width: 320px;
    max-height: 320px;
    min-width: 224px;
    overflow: auto;
  }

  .serviceAccountBody {
    flex: 1 1 auto;
    min-height: 1px;
    background-color: var(--white);
  }

  .serviceAccountCard p {
    padding: 1.25rem;
    padding-bottom: 0;
    color: var(--gray-14);
  }
  
  .serviceAccountCard hr {
    margin-top: 0;
    margin-bottom: 0;
  }
    
  .tooltipText{
    text-transform: none;
    white-space: nowrap;
  }

  .permissions-table {
    overflow: unset;
  }

}

.create-perm-popup {
  .form-group {
    margin-bottom: 0 !important;
  }
}