* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #333333;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Update Bootstrap component According to Theme */
.btn-outline-primary {
  color: #6ebe49;
  border-color: #6ebe49;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
  text-align: center;
  text-transform: capitalize;
  min-width: 64px;
}

.btn-outline-primary i,
.btn-outline-primary .MuiSvgIcon-root {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 4px;
}

.btn-outline-primary:hover {
  color: #6ebe49;
  background-color: transparent;
  border-color: #6ebe49;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #6ebe49;
  background-color: transparent;
  border-color: #6ebe49;
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  background-color: transparent;
  border-color: #6ebe49;
  color: #6ebe49;
  opacity: 0.45;
}

.btn.btn-link {
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
}

.btn-primary {
  color: #fff;
  background-color: #6ebe49;
  border-color: #6ebe49;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
  text-align: center;
  text-transform: capitalize;
  min-width: 64px;
}

.btn-primary i,
.btn-primary .MuiSvgIcon-root {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 4px;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #5ca63a;
  border-color: #5ca63a;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #438027;
  border-color: #438027;
  color: #ffffff;
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #6ebe49;
  border-color: #6ebe49;
  opacity: 0.45;
  cursor: not-allowed;
}

.btn-secondary {
  color: #4d4d4d;
  background-color: transparent;
  border-color: #4d4d4d;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
  text-align: center;
  text-transform: capitalize;
}

.btn-secondary i,
.btn-secondary .MuiSvgIcon-root {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 4px;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: transparent;
  border: none;
  color: #4d4d4d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #4d4d4d;
  background-color: transparent;
  border: none;
}

.btn-edit {
  color: #4d4d4d;
  background-color: transparent;
  border: none;
  font-size: 14px;
  border-radius: 4px;
}

.btn-edit:hover,
.btn-edit:focus,
.btn-edit:active {
  color: #333333;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.btn-edit img {
  border: 1px solid transparent !important;
}
.btn-edit img:hover {
  border: 1px solid transparent !important;
}

.btn-edit:not(:disabled):not(.disabled).active,
.btn-edit:not(:disabled):not(.disabled):active,
.show > .btn-edit.dropdown-toggle {
  color: #333333;
  background-color: #cccccc;
  border: none;
}

.btn-edit.disabled,
.btn-edit:disabled {
  color: #333333;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 3px;
  cursor: not-allowed;
}
/** Begin outline buttons */

.btn-outline-secondary {
  color: #737373;
  background-color: transparent;
  border-color: #4d4d4d;
  color: #4d4d4d;
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
  min-width: 64px;
}

.btn-outline-secondary i,
.btn-outline-secondary .MuiSvgIcon-root {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 20px;
  margin-right: 4px;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  background-color: #4d4d4d;
  border-color: #4d4d4d;
  color: #ffffff;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  color: #ffffff;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  background-color: transparent;
  border-color: #4d4d4d;
  color: #4d4d4d;
  opacity: 0.45;
  cursor: not-allowed;
}

/** End outline buttons */

/** Begin negative buttons */
.btn-danger {
  background-color: #dc1f2e;
  border-color: #dc1f2e;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
  min-width: 64px;
}

.btn-danger i,
.btn-danger .MuiSvgIcon-root {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 20px;
  margin-right: 4px;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: #c21322;
  border-color: #c21322;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background-color: #990915;
  border-color: #990915;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #dc1f2e;
  border-color: #dc1f2e;
  opacity: 0.45;
}
/** End negative buttons */

/** Begin bootstrap modal*/
.lyve-popup .modal-dialog {
  width: 640px;
  min-width: 640px;
}

.lyve-modal .modal-content,
.lyve-modal .modal-dialog {
  width: 614px !important;
  min-width: 614px !important;
}

.lyve-popup .modal-content,
.lyve-modal .modal-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.modal-note {
  margin-bottom: 32px;
}
.lyve-modal .modal-title,
.lyve-popup .modal-title {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}
.lyve-modal .modal-header .close,
.lyve-popup .modal-header .close {
  margin: 0 !important;
  height: 20px;
  width: 20px;
  display: inline-block;
  top: 4px;
  position: relative;
  font-weight: 300;
  color: #666;
  line-height: 20px;
  opacity: 1;
}

.lyve-modal .modal-header .close:active {
  outline: none;
}

.lyve-modal .modal-header,
.lyve-modal .modal-body,
.lyve-popup .modal-header,
.lyve-popup .modal-body {
  padding: 24px !important;
}

.lyve-modal .model-error {
  color: #dc3545 !important;
  font-size: 14px;
  color: var(--red-3);
  margin-top: 6px;
  padding-bottom: 0.5rem !important;
}

.lyve-modal .modal-body,
.lyve-popup .modal-body {
  padding-top: 0 !important;
}

.lyve-modal .form-group {
  margin-bottom: 24px;
}
.lyve-modal .form-group.last-group,

.lyve-popup .modal-footer {
  padding: 24px;
  border-top: 0;
  justify-content: flex-start;
}

.lyve-modal .modal-footer {
  padding: 0 24px 24px 24px;
  border-top: 0;
  justify-content: flex-start;
}

.lyve-popup .modal-footer .btn,
.lyve-modal .modal-footer .btn {
  margin: 0 !important;
}
.lyve-popup .modal-footer .btn:first-child,
.lyve-modal .modal-footer .btn:first-child {
  margin-right: 10px !important;
}

/** Radio button selection color */
.MuiRadio-colorSecondary.Mui-checked {
  color: #6ebe49 !important;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 640px !important;
  }
}

/** End bootstrap modal*/

.btn-icon {
  height: 32px;
  min-width: 32px;
  border-radius: 4px;
  text-align: center;
  padding: 4px 0;
  background-color: transparent;
  color: #4d4d4d;
}

.btn-icon:hover {
  background-color: #e6e6e6;
  color: #1a1a1a;
}

.btn-icon:active {
  background-color: #cccccc;
  color: #1a1a1a;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  height: 32px;
  min-width: 52px;
}
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000000d;
  border: none;
}

h4.theme-title,
.navbar-brand {
  color: #737373 !important;
  display: inline-block;
  font-size: 24px;
  font-weight: 300;
}

.theme-title--2 {
  color: #333333 !important;
  font: 400 20px/26px Roboto;
  margin-bottom: 20px;
  display: inline-block;
}

.theme-title--2:hover {
  text-decoration: none;
}

.theme-para {
  text-align: left;
  font: 400 16px/22px Roboto;
  letter-spacing: 0;
  color: #333333;
  margin-bottom: 20px;
}

.theme-label-small {
  text-align: left;
  font: 400 14px/20px Roboto;
  letter-spacing: 0;
  color: #737373;
}

.card .card-body .form-group label {
  color: #737373;
  font: 300 14px/20px "Roboto";
}

/* .card .card-body .form-group .form-control {
  border: 1px solid #cccccc;
  color: #999999;
} */

.card .card-body .form-group input[type="file"] {
  color: #999999;
  font-size: 14px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    left: auto;
    right: 0;
  }
}

.remove-carret > .dropdown-toggle::after {
  content: none;
}

.dropdown-item.sub-heading {
  font: 300 14px/20px "Roboto";
  color: #737373;
}

.dropdown-item {
  font: 300 16px/22px "Roboto";
  color: #333333;
}

.breadcrumb {
  background-color: transparent;
  padding-top: 0;
}

.breadcrumb-item {
  color: #333333;
  font: 300 14px/32px "Roboto";
}

.breadcrumb-item a {
  color: #333333;
}

.breadcrumb-item.active a {
  color: #cccccc;
}

.page-info {
  text-align: left;
  font: 200 20px/26px Roboto;
  letter-spacing: 0;
  color: #333333;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 570px;
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
  color: #737373;
  font: 400 16px/20px "Roboto";
  line-height: 14px !important;
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 8px;
  height: 40px;
}

.btn-file .edit-icons {
  background: url(./images/group.png) 0% 0% no-repeat padding-box;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.btn-file .btn-title {
  display: inline-block;
  position: relative;
  top: 0px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

@media (min-width: 576px) {
  .form-inline .form-control.support-search {
    width: 360px;
    /* There is no image available in the design so for now putted edit icon*/
    background: url(./images/group.png) 10px 6.5px no-repeat;
    padding-left: 35px;
  }
}

.card.support-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none;
  border-radius: 4px;
  text-decoration: none;
  color: #3661231a;
}

.card.card.support-card .card-header {
  margin-bottom: 0;
  border-bottom: 2px solid #eee;
  background: #fff;
  text-align: left;
  font: 500 25px/30px Roboto;
  letter-spacing: 0;
  min-height: 60px;
  line-height: 60px;
  opacity: 1;
  border-bottom: 1px solid #ebedf2;
  padding: 0;
}

.card.card.support-card .card-header .card-title {
  line-height: 60px;
  padding: 0 25px;
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: #737373;
}

.card.card.support-card label,
.form-control {
  color: #737373;
}

.card.support-card button,
.card.support-card a[role="button"] {
  text-align: center;
  font: 300 14px/20px Roboto;
  letter-spacing: 0;
  opacity: 1;
}

.table {
  border-bottom: 1px solid #fafafa;
}

.table thead th {
  text-align: left;
  font: 500 14px/20px Roboto;
  letter-spacing: 0;
  color: #1a1a1a;
  background: #fafafa;
  border-top: 0;
}

.table td {
  text-align: left;
  font: 300 14px/20px Roboto;
  letter-spacing: 0;
  color: #333333;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover,
.btn-success:hover {
  color: #fff;
  background-color: #6ebe49;
  border-color: #6ebe49;
}

/* .card.support-card.min-height {
  min-height: 800px;
}

.card.support-card .card-body {
  flex: 0 1 auto !important;
} */

/* .card.support-card .icon-area .MuiSvgIcon-root {
  font-size: 30px;
   margin-bottom: 10px;
  position: absolute;
  opacity: 0.1;
  z-index: 0;
  left: 5px;
  top: 5px;
} */

/* .card.support-card .icon-area {
  height: 48px;
  width: 48px;
  position: absolute;
  right: 25px;
  top: 46px;
}
.card.support-card .icon-area img {
  height: 48px;
  width: 48px;
} */

.card.support-card h3 {
  font-size: 20px;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 26px;
  margin-bottom: 0;
}

.card.support-card h2 {
  font-size: 48px;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;
  line-height: 56px;
  font-weight: 500;
  margin-bottom: 0;
}

.logo {
  width: 150px;
}

.sea-blue {
  color: #2c77f4;
}

.sea-green {
  color: #1ec9b6;
}

.sea-yellow {
  color: #ffb822;
}

.sea-red {
  color: #fc397a;
}

.table thead th {
  vertical-align: middle !important;
  border-bottom: 2px solid #dee2e6 !important;
}

.table thead th span {
  cursor: pointer;
}

.table thead tr {
  height: 56px !important;
  background: #fafafa 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.table tbody tr {
  height: 56px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.table.condensed-table tbody tr {
  height: 45px !important;
}

.table tbody td {
  vertical-align: middle !important;
  border-bottom: 1px solid #dee2e6 !important;
  font: 300 14px/20px "Roboto";
}

.table tbody td a {
  color: #5ca63a;
}

table thead .sort span {
  position: relative;
  cursor: pointer;
}

table thead .sort.asc span:after {
  opacity: 1;
}

table thead .sort.desc span:before {
  opacity: 1;
}

table thead .sort span:before,
table thead .sort span::after {
  position: absolute;
  bottom: 0;
  display: block;
  opacity: 0.3;
}

table thead .sort span:before {
  right: -15px;
  content: "\2191";
}

table thead .sort span:after {
  right: -10px;
  content: "\2193";
}

.attachment-table tbody td:first-child {
  padding-left: 0px !important;
}

.attachment-table tbody td {
  vertical-align: middle !important;
  border-bottom: none !important;
  border-top: none !important;
  font-weight: 400;
  font-size: 14px;
}

.attachment-table tbody td a {
  color: #333333;
  text-decoration: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: auto;
  max-width: 310px;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.attachment-table tbody tr {
  height: 24px !important;
  background: #f2f2f2 !important;
  border-bottom: 10px solid #ffffff !important;
}

.attachment-table tbody td:first-child {
  padding-left: 8px !important;
}

/* .attachment-table tbody td:last-child {
  padding-right: 8px !important;
} */

.attachment-table tbody td a:hover {
  font-weight: 400;
  font-size: 14px;
  color: #6ebe49 !important;
}

.attachment-table-view {
  margin-bottom: 0px !important;
  border-bottom: 0px !important;
}

.attachment-table-view tbody tr {
  height: 24px !important;
  background: transparent !important;
}

.attachment-table-view tbody td {
  vertical-align: middle !important;
  border-bottom: none !important;
  border-top: none !important;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000d9;
  font-weight: 400;
}

.attachment-table-view tbody td span {
  font-size: 14px;
  letter-spacing: 0px;
  color: #999999;
  font-weight: 400;
}

.attachment-table-view tbody td:first-child {
  padding-left: 0px !important;
}

.attachment-table-view tbody td:last-child {
  padding-right: 24px !important;
}

.attachment-table-view tbody td a {
  color: #000000d9;
  text-decoration: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: auto;
  max-width: 310px;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.attachment-table-view tbody td a:hover {
  font-weight: 400;
  font-size: 14px;
  color: #6ebe49 !important;
}

.modal-header .close {
  padding: 0px;
  /* margin: 0; */
  margin: -10px -4px 0px 0px;
}

.modal-title {
  font: 400 24px/32px "Roboto";
}

.modal-header {
  border: none !important;
}

#pagination-area .table-length {
  float: right;
}

#pagination-area .table-length .label {
  color: #333333;
  margin-right: 5px;
}

#pagination-area .table-length .length select {
  border: 0;
  background: white;
  outline: none;
  color: #333333;
}

#pagination-area .info {
  display: flex;
}

#pagination-area .info .pages {
  margin-right: 50px;
  margin-left: 50px;
  display: inline-block;
  color: #333333;
  font: 500 14px/32px Roboto;
}

#pagination-area .info .pagination {
  margin-bottom: 1rem;
  line-height: 20px;
  color: #333333;
}

#pagination-area .info .pagination a.page-link {
  border: none;
  padding: 5px 15px;
  color: #cccccc;
  font-size: 30px;
  top: -5px;
}

.loader-content {
  position: relative;
}

.loader-content .loader {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}

.errMsg {
  color: #e03a48;
  font-size: 11px;
  margin-top: 0;
}

#login .container #login-row #login-column #login-box {
  margin-top: 120px;
  max-width: 600px;
  height: 350px;
  border: 1px solid #9c9c9c;
  background-color: #eaeaea;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}
#login
  .container
  #login-row
  #login-column
  #login-box
  #login-form
  #register-link {
  margin-top: -85px;
}

.create-ticket-title {
  position: absolute;
  margin: 10px 0 0 16px;
}

.required {
  color: red;
}

/* .ticket-count-card {
  margin: auto;
  border-radius: 4px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 0px;
} */

.last-card {
  padding-right: 20px;
}

/* .ticket-count-card-body {
  padding: 20px;
} */

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  padding-right: 12px !important;
}

#service-account-modal {
	min-width: 1300px;
}

#service-account-modal .modal-content {
	width: inherit;
}

#service-account-modal .modal-header{
	padding: 1.5rem;
	padding-bottom: 0px;
}

#service-account-modal .close{
	color: #666;
	padding-right: 14px;
}

#service-account-modal .modal-body{
	padding: 1.5rem !important;
}

#service-account-modal {
	min-width: 1300px;
}

#service-account-modal .modal-content {
	width: inherit;
}

#service-account-modal .modal-header{
	padding: 1.5rem;
	padding-bottom: 0px;
}

#service-account-modal .close{
	color: #666;
	padding-right: 14px;
}

#service-account-modal .modal-body{
	padding: 1.5rem !important;
}

.modal-content.monthpicker {
  width: 400px;
}

.error-messages {
  display: block;
  color: #dc1f2e !important;
  font-size: 14px;
  margin-bottom: 10px;
  float: left;
  margin-right: 5px;
}

.error-messages-retry {
  display: block;
  color: #5ca63a;
  font-size: 14px;
  margin-left: 24px;
  margin-bottom: 10px;
  cursor: pointer;
}

.case-number {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #4d4d4d;
}

.case-subject {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  float: left;
  font-size: 20px;
  margin-bottom: 24px;
}

.case-ticket-card-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  float: left;
  margin-bottom: 0;
}

.case-ticket-card-title.case-subject {
  margin-bottom: 24px;
}

.case-state-new {
  text-align: left;
  letter-spacing: 0px;
  color: #00719b;
  float: left;
  background: #e6f6fc 0% 0% no-repeat padding-box;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 10px;
  font-size: 14px !important;
  font-weight: 500;
}

.case-state-resolved {
  text-align: left;
  letter-spacing: 0px;
  color: #4d8533;
  float: left;
  background: #f1f9ed 0% 0% no-repeat padding-box;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 10px;
  font-size: 14px !important;
  font-weight: 500;
}

.case-state-inprogress {
  text-align: left;
  letter-spacing: 0px;
  color: #ae850f;
  float: left;
  background: #fef9e8 0% 0% no-repeat padding-box;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 10px;
  font-size: 14px !important;
  font-weight: 500;
}

.case-edit {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #737373;
  float: right;
  cursor: pointer;
}

.case-edit img {
  margin-right: 5px;
}

.case-edit:hover {
  border: none;
}

.case-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #737373;
  width: 120px;
  margin-bottom: 0px;
}

.case-text {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000d9;
  margin-bottom: 0px;
  /* padding-left: 24px; */
  /* width: 100%; */
  margin-left: 24px;
}

.case-largetext {
  word-break: break-all;
}

.text-primary {
	color: #6ebe49 !important;
}

.case-resolution {
  margin-left: 0px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1a1a1a;
}

.case-content-area {
    padding: 20px;
    margin-bottom: 32px;
    background: #fff!important;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,.08);
}

.case-content-area .btn-outline-secondary {
  margin: 0;
}

.resolution .name {
  margin-right: 10px;
  color: #333333;
  font-size: 14px !important;
}
.resolution .comment-date {
  margin-right: 10px;
  color: #999999;
  font-size: 14px !important;
}

.comments {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
}

.case-text-attachment {
  font-size: 14px;
  letter-spacing: 0px;
  color: #999999;
}

.lmargin {
  margin-left: 16px;
}

.rmargin {
  margin-right: 16px;
}

.update-form {
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 0px;
}

.update-form-select {
  width: auto;
  min-width: 180px;
}

.update-form-upload {
  width: -webkit-fill-available;
  margin-bottom: 10px;
  margin-right: 0px;
}

.support-ticket-details .update-form-text label {
  display: none;
}

.update-form-text {
  /* margin-left: 24px; */
  margin-bottom: 0px;
  /* border: 1px solid #cccccc; */
  /* border-radius: 4px; */
  /* height: 32px; */
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000d9;
  margin-left: 24px;
  width: 100%;
}

.attachemnt-wrapper {
  flex-grow: 10;
  margin-left: 24px;
}

/* .editForm .invalid-feedback {
  margin-left: 24px;
} */

.cancelAttachment {
  position: absolute;
  right: 0px;
  top: -2px;
  border: none;
  background: transparent;
}

.rdt_TableHead {
  background: #fafafa !important;
}

.rdt_TableHeadRow {
  background: #fafafa !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #999999 !important;
  /* height: 56px !important; */
}

.rdt_TableBody {
  background: #fafafa !important;
}

.rdt_TableRow {
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #e6e9fa !important;
  /* height: 56px !important; */
}

.rdt_TableRow:hover {
  background: #fafafa !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #e6e9fa !important;
  outline-style: solid !important;
  outline-width: 1px !important;
  outline-color: #e6e9fa !important;
}

.rdt_TableRow a {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #5ca63a !important;
}

.rdt_Pagination select {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 32px;
  width: 72px;
}

.MuiTablePagination-toolbar .MuiTablePagination-selectRoot {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 32px;
  width: 72px;
  max-width: 100px;
  width: auto;
}

.MuiTablePagination-toolbar .MuiSelect-nativeInput{
  position: inherit !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 50px !important;
}

.rdt_Pagination span {
  margin-right: 9px;
}

.rdt_Pagination svg {
  top: 4px !important;
}

.attachmant-holder {
  /* margin-bottom: 10px !important; */
  width: 100%;
}

.new-attachments {
  background: #f2f2f2 !important;
  border-radius: 2px;
  padding-left: 8px;
}

.new-attachments .attachment-link {
  color: #333333 !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: auto;
  max-width: 310px;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.new-attachments .attachment-link:hover {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #6ebe49 !important;
}

.new-attachments .attachment-icon svg {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.attachment-icon svg {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.new-attachments span {
  color: #999999;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px !important;
}

.modal-open .support-resource-video-modal.modal {
  overflow: auto;
}

/* body.modal-open .modal {
  display: flex !important;
  height: 100%;
} */

/* body.modal-open .modal .modal-dialog {
  margin: auto;
} */

.highlight-new-row {
  background-color: #ecfae6;
  animation-name: newRow;
  animation-duration: 3s;
}

@keyframes newRow {
  0% {
    background-color: #ecfae6;
  }
  100% {
    background-color: transparent;
  }
}

.btn-cancel {
  color: #4d4d4d;
  background-color: transparent;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  margin-left: 8px;
}

.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active,
.btn-cancel.disabled,
.btn-cancel:disabled {
  background-color: transparent;
  border: none;
  color: #4d4d4d;
}

/** Layout CSS */

.lyve-main-wrapper {
  background-color: var(--gray-3);
}

.lyve-wrapper {
  display: flex;
  /* Grid limits */
  min-width: var(--min-screen-width);
  max-width: var(--max-screen-width);
}

.lyve-global-sidebar {
  background-color: #333333 !important;
  height: 100vh;
  width: 136px;
  flex: 0 0 136px;
}

.lyve-page-wrapper {
  height: calc(100vh - var(--top-navbar-height));
  overflow: auto;
}

.lyve-top-trial-banner {
  height: calc(100vh - var(--top-navbar-height) - var(--top-banner-height));
  overflow: auto;
}

.lyve-page-wrapper .mainContent {
  padding-left: 32px;
  padding-right: 32px;
}

/** Begin standardize card */
.card.lyve-card {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}
.card.lyve-card .card-body {
  padding: 20px;
}

.card.lyve-card .card-body .card-icon {
  display: block;
  height: 48px;
  width: 48px;
  font-size: 32px;
  line-height: 48px;
  padding: 0;
}

.card.lyve-card .card-body .card-subtitle {
  display: block;
  color: #6ebe49;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 8px 0;
}

.card.lyve-card .card-body .card-subtitle a {
  font-size: 20px;
  text-decoration: none;
  color: #6ebe49;
}

.card.lyve-card .card-body .card-subtitle a:hover,
.card.lyve-card .card-body .card-subtitle a:active {
  text-decoration: none !important;
  color: #6ebe49 !important;
  font-weight: 400 !important;
}

.card.lyve-card .card-body .card-text {
  color: #737373;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

/** End standardize card */

.table-three-dots-icon {
  height: 32px;
  min-width: 32px;
  border-radius: 4px;
  text-align: center;
  padding: 4px 0;
  background-color: transparent;
  color: #1a1a1a;
}

.table-three-dots-icon:hover {
  background-color: #e6e6e6;
}

.table-three-dots-icon:active {
  background-color: #cccccc;
}

.table-three-dots-icon-active {
  background-color: #cccccc;
}

.lyve-breadcrumb {
  height: 20px;
  line-height: 20px;
}

.lyve-breadcrumb .text-btn {
  color: var(--gray-10);
  font-size: 14px;
  padding: 0;
  text-decoration: none;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
}

.lyve-breadcrumb .text-btn:hover {
  color: var(--gray-12);
  text-decoration: underline;
}

.lyve-breadcrumb .text-btn:active {
  color: var(--gray-14);
  text-decoration: underline;
}
