.settings-container {
	--container-top-margin: 32px;

	width: 100%;
	margin-top: var(--container-top-margin);
	margin-bottom: var(--container-top-margin);

	.data-loader {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.spinner {
			color: var(--green-3);
			width: 48px;
			height: 48px;
		}
	}

	/* Override default font for form labels inside cards */
	.card .card-body .form-group label {
		color: inherit;
		font: inherit;
	}

	.model-error {
		color: #dc3545 !important;
		font-size: 14px;
		color: var(--red-3);
		margin-top: 6px;
		padding-bottom: 0.5rem !important;
	}
}

a.helplink {
	color: #6ebe49 !important;
	text-decoration: none;
}

a.helplink:hover {
	color: #5ca63a !important;
	// text-decoration: underline;
	cursor: pointer;
}

a.helplink:active {
	color: #438027 !important;
	// text-decoration: underline;
}
