.lyve-terms-logo {
  background-color: transparent;
  border: none;
  outline: none !important;
}

.app-header {
  justify-content: unset;
  background-color: #000000;
  padding: 5px 32px;
  color: #ffffff;
  font-size: 18px;
  min-height: 40px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  position: sticky;
  top: 0;

  svg {
    cursor: pointer;
  }
}

.lyve-terms-container {
  display: flex;
  justify-content: center;
  padding: 35px;
  height: 100vh;
  background: var(--gray-3);

  .lyve-terms {
    text-align: left;
  }

  h4 {
    padding-bottom: 26px;
    font-weight: 300;
  }

  .conditions {
    display: flex;
    flex-direction: column;
    padding-top: 18px;
    padding-bottom: 12px;

    .condition {
      display: flex;
      padding-bottom: 14px;

      .custom-form-group.form-group {
        margin-bottom: 0;

        .invalid-feedback {
          margin-left: 24px;
        }
      }
    }
  }
  .lyve-terms-confirm-btn {
    margin-right: 10px
  }
}
