@import "../../../../../../theme/mixins.scss";

.create-policy-permission-module {
	.alert {
		-webkit-animation: fadeIn 0.2s;
		animation: fadeIn 0.2s;
		.alert-text {
			overflow-wrap: break-word;
		}
	}
	.policy-permission-form .title-group {
		margin-bottom: 24px;
		.create-policy-permission-title {
			font-size: 20px;
			font-weight: 500;
			line-height: 26px;
			color: var(--gray-14);
		}
	}

	.form-group {
		margin-bottom: 0;
	}

	.form-section {
		margin-bottom: 24px;
	}

	.form-input-label {
		@include font_label;
		margin-bottom: 8px;
	}

	.validation-container {
		margin: 4px 0 16px;
		padding: 1px;
	}

	.validation-item {
		display: flex;
		align-items: center;
		margin-right: 4px;
		font-size: 14px;
		color: var(--gray-11);
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.validation-error {
		font-size: 14px;
		color: var(--red-3);
		margin-top: 6px;
	}

	.validation-icon {
		font-size: 14px;
		margin-right: 3px;
		color: var(--gray-6);

		&.valid {
			color: var(--green-3);
		}
	}
	.policy-file-group {
		label {
			color: var(--gray-12);
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			text-transform: capitalize;
			margin-bottom: 8px;
		}
		.alert-link {
			float: right;
			color: var(--green-3);
			font-weight: 400;
			&:hover {
				color: var(--green-4);
			}
		}
		.error-alert {
			margin-top: 10px;
		}
	}

	.submit-button {
		font-size: 14px;
		text-transform: capitalize;
		padding: 10px 16px;
		font-weight: 500;
		line-height: 20px;
		background-color: var(--green-3);
	}

	.spinner {
		width: 20px;
		height: 20px;
	}

	a.helplink {
		color: #6ebe49 !important;
		text-decoration: none;
	}

	a.helplink:hover {
		color: #5ca63a !important;
		// text-decoration: underline;
		cursor: pointer;
	}

	a.helplink:active {
		color: #438027 !important;
		// text-decoration: underline;
	}
}
