.lyve-badge.badge {
  padding: 4px 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }

  &.badge-pill {
    &.md {
      padding: 2px 16px;
    }

    &.sm {
      font-size: 12px;
      line-height: 16px;
      padding: 1px 9px;
    }
  }

  &.light {
    background-color: var(--gray-4);
    color: var(--gray-12);
  }

  &.dark {
    background-color: var(--gray-12);
    color: var(--white);
  }

  &.success {
    background-color: var(--green-1);
    color: var(--green-4);
  }

  &.warning {
    background-color: var(--yellow-1);
    color: var(--yellow-4);
  }

  &.info {
    background-color: var(--blue-1);
    color: var(--blue-4);
  }
}

.lyve-status-dot {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-14);
  display: flex;
  align-items: center;
}
