.customer-subaccount-wrapper {
  .action-btns {
    padding-bottom: 30px;
  }

  .label-info {
    color: var(--gray-9);
  }

  .form-group {
    margin-bottom: 24px;
  }

  input[readonly] {
    height: 40px;
    padding: 7px 12px;
  }

  .form-input-row {
    display: flex;
    & > div {
      flex: 1;
    }
    & > div:first-child {
      margin-right: 16px;
    }
  }

  .validation-error {
    font-size: 14px;
    color: var(--red-3);
    padding-bottom: 24px;
    margin: 0;
  }
  
  .alert-wrapper {
    margin-bottom: 24px;
    max-width: 840px;
  }
}