.lyve-dropdown {
  .dropdown-menu.show {
    left: auto;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0px 0px 2px 1px var(--gray-6);
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-header {
    padding: 6px 10px;
    font-size: 10px;
    min-height: 32px;
  }

  .dropdown-item {
    color: var(--gray-14);
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      color: var(--gray-13);
      background-color: var(--white);
    }

    &:active {
      color: var(--gray-13);
      background-color: var(--white);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .dropdown-action {
    cursor: pointer;

    &:hover {
      color: var(--gray-14);
      background-color: var(--gray-3);
      border-radius: 4px;
    }

    &:active {
      color: var(--gray-14);
      background-color: var(--gray-4);
      border-radius: 4px;
    }
  }
}
