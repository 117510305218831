.modal-dialog {
  .custom-close-icon {
    position: absolute;
    top: 17px;
    right: 15px;
    cursor: pointer;
    z-index: 1;
    background: transparent;
    border: 1px solid transparent;
    outline: none;
    padding: 0;
    border-radius: 4px;

    &:focus,
    &:focus-within {
      border: 1px solid var(--gray-13);
    }
  }

  &.modal-sm {
    .modal-content {
      max-width: var(--modal-width-small);
    }
  }

  &.modal-lg {
    max-width: var(--modal-width-large);
    width: 100%;
    
    @media screen and (min-width: 1366px) {
      max-width: var(--modal-width-large);
    }

    .modal-content {
      width: 100%;
    }
  }

  .modal-body {
    padding: 24px;
  }
}

.lyve-popup {
  .modal-dialog {
    max-width: 640px;
    margin-top: 40px !important;

    .modal-content {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      padding: 24px;

      .popup-subtitle {
        color: #333333;
        padding-bottom: 18px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &.modal-lg {
      max-width: var(--modal-width-large);
    }

    .no-header-close-btn {
      align-self: flex-end;
    }

    .modal-header {
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .modal-title {
        font-size: 20px;
        font-weight: 500;
        padding-right: 28px;
        line-height: 26px;
        color: var(--gray-14);
      }

      .title-container {
        display: flex;
        flex-direction: column;
      }

      .subtitle {
        font-size: 14px;
        color: var(--gray-9);
        line-height: 20px;
        padding-top: 9px;
      }

      .disable-click {
        cursor: not-allowed;
      }

      .close {
        top: 0;
      }

      &.close:active {
        outline: none;
      }
    }

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
    }

    .no-header-body {
      padding: 0;
    }

    .modal-footer {
      padding: 24px 0 0;
      border: none;
      justify-content: flex-start;

      & > * {
        margin: 0;
      }
    }

    .form-group.last-group {
      margin-bottom: 32px !important;
    }
  }
}