.customer-support-wrapper {
	margin-top: 32px;

	.ticket-filter {
		width: 200px;
	}

	.filter-div {
		display: flex;
		align-items: center;
	}

	.filter-span {
		margin-right: 8px;
	}

	.button-area {
		display: flex;
		align-items: center;
	}

	.external-link {
		color: var(--gray-14);
		margin-right: 10px;

		&:hover {
			font-weight: 400;
			text-decoration: underline;
		}
	}
}

.heading-area {
	margin-right: 64px;

	.lyve-page-main-title {
		display: block;
		line-height: 32px;
		font-weight: 300;
		margin-bottom: 8px;
		font-size: 24px;
		color: #1a1a1a;

		&.m-remove {
			margin-bottom: 0;
		}
	}

	.lyve-page-sub-title {
		font-size: 16px;
		font-weight: 400;
		color: #737373;
		line-height: 24px;
		display: block;
		margin-bottom: 0;
	}
}

a.helplink {
	color: #6ebe49 !important;
	text-decoration: none;
}

a.helplink:hover {
	color: #5ca63a !important;
	// text-decoration: underline;
	cursor: pointer;
}

a.helplink:active {
	color: #438027 !important;
	// text-decoration: underline;
}

.data-loader {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.spinner {
	  color: var(--green-3);
	  width: 48px;
	  height: 48px;
	}
  }
