.change-priority-container {
  display: flex;
  flex-direction: column;
}

.change-priority-title {
  display: flex;
}

.change-priority-name {
  font: 300 24px/32px Roboto;
  margin: 16px 0 20px 0;
  color: #1a1a1a;
}

.change-priority-main {
  display: flex;
  flex-direction: column;

  .lyve-page-title {
    margin-bottom: 24px !important;
  }

  .lyve-page-main-title {
    font: normal 20px/26px Roboto !important;
    margin-bottom: 8px !important;
  }

  .lyve-page-sub-title {
    font: normal 14px/20px Roboto !important;
    color: #808080 !important;

    a.helplink{
      color: #497F31 !important;

      &:hover {
        color: #375F25 !important;
        text-decoration: underline;
      }
      &:active {
        color: #253F18 !important;
        text-decoration: underline;
      }
    }
  }
  
}

.change-priority-table {
  margin-top: 16px;

  .lyve-table-card {
    box-shadow: none !important;
  }

  .rdt_TableHead {
    border-bottom: 1px solid #707070 !important;
  }

  .rdt_TableHeadRow {
    height: unset !important;
    border: none !important;
    margin: 16px 0 16px 0;
    min-height: unset !important;
  }

  .rdt_TableCol_Sortable {
    line-height: unset !important;
  }

  .rdt_TableCol_Sortable:hover {
    opacity: unset !important;
  }
}

.change-priority-button-div {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .priority-spinner {
    width: 20px !important;
    height: 20px !important;
  }
}

.priority-input {
  width: 160px;
  height: 30px;
  border: solid;
  border-width: 1px;
  border-color: #cccccc;
  text-indent: 5px;
}

.priority-input:focus {
  border-color: #cccccc !important;
  padding-left: 8px !important;
  border: solid !important;
  border-width: 1px !important;
}

.priority-validation-error {
  color: #DC1E2E;
  opacity: 1;
  display: block;
  margin-top: 5px;
}

.priority-error-msg {
  font-size: 14px;
  color: var(--red-3);
  margin-top: 10px;
}

.priority-no-data-div {
  margin: 20px 0 100px 0;
}

.has-error {
  border-color: red;
}

.priority-search-filter-style {
  display: flex;

  .search-filter-div {
    width: 360px !important;
  }
}
