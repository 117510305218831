@import "../../theme/mixins.scss";

.loading-state-modal {
    &.modal-dialog-centered {
       top: 10px;
       align-items: flex-start;
    }

    .title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: var(--gray-14);
        max-width: 100%;
        word-break: break-all;
    }

    .subtitle {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: var(--gray-9);
        margin-top: 9px;
    }
}

.loading-error-state-modal {
    &.modal-dialog-centered {
        top: 10px;
        align-items: flex-start;
     }
     
    .title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: var(--gray-14);
    }

    .subtitle {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: var(--gray-14);
        margin-top: 28px;
        margin-bottom: 26px;
    } 

    .validation-error {
		font-size: 14px;
		color: var(--red-3);
		margin-top: 6px;
	}

    .spinner {
		width: 20px;
		height: 20px;
	}
}