.error-page-wrapper {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-size: 1rem;
    line-height: 1.5;
    background-color: "#fff";

    .main-container {
        padding: 60px 40px 0;
        margin: 0 auto;
    }

    .logo {
        width: 326px;
        height: 24px;
        max-width: 100%;
        margin-bottom: 40px;

        .logo-img {
            max-width: 100%;
            display: block;
        }
        .logo-heading {
            opacity: 0;
            display: none;
        }
    }


    .message {
        display: flex;
        .message-text {

            .message-heading {
                font-size: 28px;
                line-height: 36px;
                color: rgba(0,0,0,.7);
            }
            .message-description {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0,0,0,.7);
                margin: 1em 0 0;
            }
        }

        .message-visual {}
    }
}


@media (min-width: 992px) {
    .error-page-wrapper {
        background: url("../../images/Lyve_Brand_Pattern_116-2560x1160.png") center bottom no-repeat;

        .main-container {
            max-width: 960px;
            padding: 80px 0 0;
        }

        .logo {
            margin-bottom: 100px;
        }
    
        .message {
            .message-text {
                padding-right: 10%;
    
                .message-heading {
                    font-size: 36px;
                    line-height: 44px;
                }
    
                .message-description {
                    font-size: 22px;
                    line-height: 30px;
                }
            }

            .message-visual {
                flex: 1 1 auto;
                background-size: contain !important;
                min-width: 360px;
                min-height: 286px;
            }
        }
    }
}

@media (max-height: 1160px) {
    .error-page-wrapper {
        background-position-y: 24%;
    }
}

@media (min-width: 1280px) {
    .error-page-wrapper {
        .main-container {
            max-width: 1110px;
            padding: 100px 0 0;
        }

        .logo {
            margin-bottom: 140px;
        }

        .message {
            .message-text {
                .message-heading {
                    font-size: 44px;
                    line-height: 55px;
                }

                .message-description {
                    font-size: 26px;
                    line-height: 34px;
                }
            }

            .message-visual {
                flex: 1 1 auto;
                min-width: 421px;
                min-height: 335px;
            }
        }
    }
    
}


@media (min-height: 30px) and (min-width: 992px) {
    .error-page-wrapper {
        .main-container {
            padding: 0;
            padding: 9.4vh 0 0;
        }

        .logo {
            margin-bottom: 12.5vh;
        }
    }
}