@mixin grid_design($sidebar-width) {
  // < 1366px
  @media (max-width: 1365px) {
    width: calc(100vw - #{$sidebar-width});
    .mainContent {
      min-width: calc(1365px - #{$sidebar-width});
    }
  }

  // between 1365px and 1920px
  @media screen and (max-width: 1920px) and (min-width: 1365px) {
    width: 100vw;
  }

  // > 1920px
  @media only screen and (min-width: 1920px) {
    width: calc(1920px - #{$sidebar-width});
  }
}

@media (max-height: 541px) {
  .collapse-toggle-btn-wrapper {
    border-top: 1px solid #e6e6e6;
  }
}

@media (max-height: 588px) {
  .expand-toggle-btn-wrapper {
    border-top: 1px solid #e6e6e6;
  }
}

.top-navbar {
  .navbar {
    background-color: #000000;
    flex: 1;
    padding: 0 10px 0 0;
    color: #ffffff;
    font-size: 18px;
    height: 40px;
    font-family: "Roboto", sans-serif;
  }

  .lyve-topbar-logo {
    margin-right: 32px;
    display: flex;
    font-size: 18px;
    padding: 13px 14px 13px 20px;

    svg {
      cursor: pointer;
      outline: none;
    }
  }

  .navbar-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .partner-name {
    font-weight: 400;
    white-space: nowrap;
    display: flex;
    align-items: center;
    .dev-version {
      display: block;
      text-align: center;
      font-size: 10px;
    }
    .dev-version-spacing {
      margin-left: 20px;
    }
  }

  .customer-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
  }

  .customer-name {
    margin: 0;
    line-height: 20px;
    font-weight: 500;
  }

  .logout-section {
    font-weight: 400;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .f-logout-tooltip {
    margin-top: 18px !important;
    background-color: #4d4d4d;
    padding: 10px 16px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .f-logout-tooltip::after {
    border-bottom-color: #4d4d4d !important;
  }
}

.lyve-sidebar {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  height: calc(100vh - var(--top-navbar-height));
  overflow: auto;
  box-sizing: border-box;
  flex: 0 0 var(--sidebar-width-expanded);

  .navigation-container {
    padding: 32px 10px 0px;
    margin-bottom: 45px;

    .sidebar-section {
      margin-top: 16px;

      .sidebar-section-collapse-item {
        height: 16px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .sidebar-section-collapse-text {
          width: 16px;
          height: 2px;
          background-color: #e6e6e6;
        }
      }

      .sidebar-section-item {
        height: 32px;
        padding: 7px 10px;

        .sidebar-section-text {
          font-size: 12px;
          text-transform: uppercase;
          color: #616161;
          line-height: 18px;
          font-weight: 400;
          letter-spacing: 0.05em;
        }
      }
    }

    .active-nav-link {
      background-color: #f0f0f0 !important;

      .sidebar-nav-text {
        font-weight: 400 !important;
      }
    }

    .sidebar-nav-wrapper-collapse {
      width: 36px;
    }

    .sidebar-nav-wrapper-margin {
      margin-top: 16px;
    }

    .sidebar-nav-wrapper {
      display: flex;
      height: 32px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &:active {
        background-color: #f0f0f0;
      }

      .sidebar-nav-item {
        display: flex;
        align-items: center;
        line-height: 20px;
        padding: 0 10px;
        width: 100%;
        color: #1a1a1a;

        .sidebar-nav-text {
          flex: 1;
          margin-left: 8px;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          min-width: 100px;
        }

        .sidebar-nav-empty-icon {
          width: 16px;
          height: 16px;
          background-color: #1a1a1a;
          border-radius: 4px;
        }
      }
    }
  }

  & + .lyve-page-wrapper {
    @include grid_design(var(--sidebar-width-expanded));
  }
}

.lyve-sidebar-collapse {
  flex: 0 0 (var(--sidebar-width-collapsed));

  & + .lyve-page-wrapper {
    @include grid_design(var(--sidebar-width-collapsed));
  }
}

.toggle-btn-wrapper {
  width: var(--sidebar-width-expanded);
  display: flex;
  align-items: center;
  height: 40px;
  bottom: 0;
  z-index: 1;
  position: fixed;
  background-color: #ffffff;
  padding: 0px 10px;

  .toggle-btn {
    display: flex;
    width: 36px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &:active {
      background-color: #f0f0f0;
    }

    .toggle-btn-icon-wrapper {
      display: flex;
      align-items: center;
      padding: 6px 10px;
    }

    .toggle-btn-icon-wrapper-empty-icon {
      width: 16px;
      height: 16px;
      background-color: #1a1a1a;
      border-radius: 4px;
    }
  }
}

.toggle-btn-wrappe-collapse {
  width: 56px;
}

.add-sidebar-banner-height {
  height: calc(100vh - var(--top-navbar-height) - var(--top-banner-height));
}
