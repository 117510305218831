@keyframes smart-slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes smart-slide-out {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.smart-slide-in {
  animation: smart-slide-in 0.3s forwards;
}

.smart-slide-out {
  animation: smart-slide-out 0.2s forwards;
}

.smart-start {
  // styles to discard top-bar styles
  color: var(--gray-14);
  font-size: 16px;

  background-color: var(--gray-2);
  width: 320px;

  &-shadow {
    box-shadow: -2px 0 6px rgba(0,0,0,.16);
  }

  &.overlay {
    z-index: 2;
    position: fixed;
    right: 0;
    top: 40px
  }

  .smart-container {
    height: calc(100vh - 104px);
    overflow: auto;

    .component {
      box-sizing: content-box;
    }
  }

  .title-bar {
    border-bottom: 1px solid var(--gray-5);
    color: var(--gray-14);
    font-size: 20px;
    padding: 18px;
    height: 64px;
    align-items: center;
    & span {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .close-button {
    cursor: pointer;
  }
}

.card.base-card.base-card-last {
  cursor: pointer;
  font-size: 14px;
  .card-body {
    padding: 0;
    svg {
      margin-top: 3px;
    }
    span {
      margin-left: 8px;
    }
  }
}