.create-resources-popup {
  button.custom-close-icon {
    position: static;
  }

  .confirmation-title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .confirmation-title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: var(--gray-14);
  }

  .confirmation-subtitle {
    padding-top: 9px;
    padding-bottom: 8px;
    color: var(--gray-9);
    font-size: 14px;
  }

  .title {
    padding-top: 16px;
  }

  .lcm-loading-wrapper {
    svg {
      height: 211px;
    }
  }

  .creation-failed {
    display: flex;
    color: var(--gray-14);

    .default-button.btn.btn-link {
      width: 135px;
    }
  }
}