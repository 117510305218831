.lyvecard {
  padding: 20px;
  max-width: 840px;
  margin-bottom: 24px;

  .title {
    line-height: 26px;
    font-size: 20px;
    color: var(--gray-14);
    padding-bottom: 8px;
  }

  .sub-title {
    color: grey;
    font-size: 14px;
    padding-bottom: 24px;
    line-height: 20px;
  }
}