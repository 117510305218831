.button-dropdown {
  margin-left: 16px;

  .lyve-select {
    &__control {
      background-color: #6ebe49;
      border-color: #6ebe49;
      height: 40px;
      &:hover, &:focus {
        background-color: #5ca63a;
        border-color: #6ebe49;
        cursor: pointer;
      }
      &:active {
        background-color: #438027;
      }
    }
    &__value-container {
      padding-left: 12px;
    }
    &__placeholder, &__single-value {
      color: white;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
      margin-right: 0;
      position: static;
      transform: none;
      max-width: 100%;
    }
    &__indicator {
      padding-left: 0;
      svg {
        fill: white;
      }
      &-separator {
        display: none;
      }
    }
    &__menu {
      right: 0;
      min-width: 160px;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
    }
  }
}

.confirm-popup {
  .modal-body {
    color: #1A1A1A;

    .body-title {
      font-weight: bold;
      padding-bottom: 6px;
    }

    .body-subtitle {
      font-size: 14px;
      color: var(--gray-9);
    }
  }
}

.icon-btn {
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }
}

.highcharts-legend-item {
  path {
    stroke-width: 1;
  }
  text {
    font-weight: normal !important;
  }
}