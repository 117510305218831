.notification-unsub {
  height: 100vh;
  width: 100vw;
  padding: 0 0 0 0;
  display: grid;
  place-items: center;
  background-color: black;

  .card {
    text-align: center;
    width: auto;
    border-radius: 5px;
    padding: 20px 50px;
    background-color: white;
  }

  .links {
    font-size: 11px;
    color: #a3a3a3;
    padding: 5px;
  }
  .title {
    margin: 20px 0 0 0;
    padding: 0;
    font-size: 25px;
    font-weight: bold;
  }

  .body {
    margin: 15px 0 40px 0;
    padding: 0;
    font-size: 15px;
  }

  .btn-div-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
    width: 30%;
  }

  .logo1 {
    padding-top: 10px;
  }

  .email {
    font-weight: 500;
  }
}
