.lc-banner {
  padding: 12px 20px;
  line-height: 16px;
  font-weight: 400;



  &-warning {
    background-color: var(--yellow-1);
    color: #99760C;
    border: 1px solid var(--yellow-2);
  }

}



.information-banner {
  padding: 12px 20px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;

  &-error {
    background-color: var(--red-4);
    color: var(--white);
  }

  &-warning {
    background-color: var(--yellow-1);
    color: #99760C;
    border: 1px solid var(--yellow-2);
  }

  .support-message {
    margin: 0 !important;
    font-weight: 500;
  }

  .message-url {
    text-decoration: revert !important;
    font-weight: 800 !important;
    color: black !important;
    line-height: normal;
  }

  .close-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &-top {
    border-radius: 4px;
  }

  span {
    font-size: 14px;
    height: 16px;
  }
}