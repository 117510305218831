.customers-wrapper {
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
}

.table-menu {
    .dropdown-menu.show {
        left: auto !important;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 1px #e6e6e6;
    }
}