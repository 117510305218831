.error-sa-module {
  .error-sa-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 26px;
    color: var(--gray-13);
  }

  .error-sa-subtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }
}