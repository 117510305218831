
.subaccount-list-page {
  .subaccounts-wrapper {
      margin-top: 32px;
      margin-bottom: 32px;
      width: 100%;

    .lyve-datatable + div {
      width: unset;
      display: inline-block;
      float: right;
    }

    .rdt_Pagination {
      height: 48px !important;
      min-height: 48px !important;
    }
  }

  .table-menu {
      .dropdown-menu.show {
          left: auto !important;
          background-color: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 0px 2px 1px #e6e6e6;
      }

    .dropdown-item {
      display: flex;
    }

    .table-menu-option {
      display: flex;
      align-items: center;
    }
  }

  .subheader-div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    button {
      text-transform: none
    }
  }

  .search-input {
    height: 40px;
    min-width: 415px !important;
  }

  .create-button {
    margin-right: 10px;
  }

  .MuiInputBase-root {
    height: 40px;
    background: white;
  }

  .MuiFormLabel-root {
    top: -6px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }

  .table-footer {
    display: flex;
    align-items: center;
    height: 48px;
    border-top-color: #cccccc !important;
    border-top: solid;
    border-top-width: thin;
    padding: 0 16px;
    font-weight: 500;
  }

  .lyve-page-title {
    margin-bottom: 24px;
  }

  .lyve-page-title .heading-area .lyve-page-main-title {
    margin-bottom: 4px !important
  }

  .table-footer {
    border-top: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--gray-14);
  }

  .table-menu .table-menu-option {
    font-weight: 500;
    span {
      color: #1A1A1A;
    }
  }

  .gTesuy {
    max-height: 48px;
  }

  .alert-wrapper {
    margin-bottom: 24px;
  }
}
