/* Scss Document */
@import url(./fonts/fonts-googleapis.css);
@import url(green-theme.css);
@import url(./fonts/fonts-googleapis-montserrat.css);

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./material-ui-font/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(./material-ui-font/MaterialIcons-Regular.woff2) format("woff2"),
    url(./material-ui-font/MaterialIcons-Regular.woff) format("woff"),
    url(./material-ui-font/MaterialIcons-Regular.ttf) format("truetype");
}


.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/*Base Color Seagate Green*/
/*Dark Gray to White Color Family*/
/*Red Color Family - Error*/
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9,
h10,
.siteName,
.pageTitle,
.modalLabel,
.modalCenter,
.nameSignIn,
.orgName,
.username,
.formLabel,
.login.contactUs,
.loginFooter {
  font-family: "Roboto" !important;
  overflow: hidden !important;
  /*font-size: calc(16px + 6 * ((100vw - 320px) / 680));*/
}

/* Additional Breakpoints aside from BootStrap 4 Standard */
/*@media all and (min-width:1200px){ }*/
/*@media all and (min-width: 960px) and (max-width: 1199px) { } For Ipad Lanscape*/
/*For Ipad Portrait*/
/*For Mobile Landscape*/
/*@media all and (max-width: 599px) { }*/
/*Extra small devices (portrait phones, less than 576px)*/
/*=========================================================================================*/
/*==================== SMALL DEVICES (Landscape Phone, 576px and up)=======================*/
/*=========================================================================================*/
/*=========================================================================================*/
/*==================== MEDIUM DEVICES (Tablets, 768px and up)==============================*/
/*=========================================================================================*/
@media only screen and (min-width: 768px) {
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 25% !important;
    left: 15%;
    z-index: 10;
  }

  .btn.btn-light.mainNav3 {
    font-size: 10px !important;
  }

  .btnNavCtn3 i {
    /* font-size: 1rem !important; */
    color: #fafafa;
    z-index: 1 !important;
  }

  .btnNavCtn3 i:hover {
    color: #f5f5f5;
    cursor: pointer;
  }
  /* .btnNavCtn3 div i.material-icons {
    font-size: 1rem !important;
  } */
  .upperCtn h4 {
    color: red !important;
  }

  .upperCtn p {
    color: orange !important;
  }
}

/*=========================================================================================*/
/*==================== LARGE DEVICES (Desktops, 992px and up)==============================*/
/*=========================================================================================*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel-caption {
    margin-bottom: 0px;
  }

  .bannerTexts.lastSlide {
    margin: 0px 0px 0 200px !important;
  }

  .bannerTexts.lastSlide h2 span.primary.smallest {
    font-size: 50px !important;
  }

  h5 span.bigger.drGray {
    color: #1a1a1a !important;
    font-size: 23px !important;
  }

  .btn.btn-primary.bordered.glow {
    margin-bottom: 0px !important;
  }

  .btn.btn-light.mainNav3 {
    font-size: 10px !important;
  }

  .btnNavCtn3 i {
    /* font-size: 1rem !important; */
    color: #fafafa;
    z-index: 1 !important;
  }

  .btnNavCtn3 i:hover {
    color: #f5f5f5;
    cursor: pointer;
  }

  /* .btnNavCtn3 div i.material-icons {
    font-size: 1rem !important;
  } */

  /* .kpi.title {
    color: red !important;
  } */

  .featuresCtn {
    margin-top: 50px !important;
  }

  .feature.name {
    font-size: 20px !important;
    margin-top: 30px !important;
  }

  .feature.intro p {
    font-size: 16px !important;
  }

  .infoFold.right .upperCtn h4 {
    font-size: 30px !important;
  }

  .infoFold.right .upperCtn p {
    font-size: 17px !important;
    line-height: 2 !important;
  }

  .infoFold.right .bottomCtn h5 {
    font-size: 27px !important;
  }

  .infoFold.right .upperCtn {
    padding: 110px 40px 0px 90px !important;
  }

  .infoFold.right .bottomCtn {
    padding: 20px 40px 80px 90px !important;
  }

  .infoFold.right .bottomCtn button.btn.btn-base lg {
    font-size: 15px !important;
    line-height: 1.5 !important;
  }
}

/*=========================================================================================*/
/*====================EXTRA LARGE DEVICES (Large desktops, 1200px and up)==================*/
/*=========================================================================================*/
@media screen and (min-width: 1025px) {
  .bannerTexts.lastSlide {
    margin-top: 230px !important;
  }

  .bannerTexts h4 {
    margin-bottom: 60px !important;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 25%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }

  .bannerTexts.lastSlide {
    margin: 0px 0px 0 280px !important;
  }

  .bannerTexts.lastSlide h5 span.bigger.drGray {
    font-size: 30px;
  }

  .bannerTexts.lastSlide h2 {
    font-size: 60px;
  }

  .bannerTexts.lastSlide h4 span.base.noShadow {
    font-size: 18px;
    margin-top: 40px;
  }
}

/*========================*/
/*========Typography======*/
/*========================*/
.siteName {
  color: #1a1a1a;
  font-size: 40px;
  font-weight: 300;
}

.pageTitle {
  color: #1a1a1a;
  font-size: 26px;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

.pageHeader {
  color: #737373;
  font-size: 22px;
  font-weight: 300;
}

.pageHeader {
  color: #737373;
  font-size: 22px;
  font-weight: 300;
}

.pageDescription {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
}

.modalTitle {
  color: #1a1a1a;
  font-size: 22px;
  font-weight: 500;
}

.cardTitle {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 500;
}

.leadParagraph {
  color: #1a1a1a;
  font-size: 26px;
  font-weight: 300;
}

.lgText {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 500;
}

.regText {
  color: #1a1a1a;
  font-size: 22px;
  font-weight: 500;
}

.orgName {
  margin: 0 0 0 50px;
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}

.navCtn.nameSignIn {
  margin: 0 0 0 50px;
  font-size: 14px;
  color: #737373;
}

.nameSignIn.hdr {
  margin: 0 0 0 0px;
  font-size: 14px;
  color: #999999;
}

.logoutIcn {
  font-size: 18px;
  /* margin: 4px 0 0 0; */
}

.logoutIcn i {
  font-size: 18px;
  margin: 20px 5px 0 0;
  color: #737373;
  cursor: pointer;
}

.logoutIcn i:hover {
  color: #6ebe49;
}

/*==================Dark Header User Profile=============*/
/* .nameSignIn.hdr.dark {
  margin: 0 0 0 0px;
  font-size: 14px;
  color: #fafafa;
} */

.btn.btn-link.hdr.dark.logout i {
  font-size: 22px;
  margin: 2px 5px 0 0;
  color: #fafafa;
  cursor: pointer;
}

/*========================================================*/
.tenantInfo {
  margin: 10px 0 0 15px;
  font-size: 12px;
  color: #737373;
  font-weight: 400;
}

.createdUser.userName,
.partnerName {
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.createdUser.orgName,
.tenantCount {
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin: 0px;
  padding: 5px 0 0 17px;
}

.createdUser.email,
.tenantName {
  color: #333333;
  font-size: 16px;
  font-weight: 500 !important;
  text-align: left;
  margin: 0px;
  padding: 5px 16px 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tenantName i {
  margin-right: 10px !important;
  color: #868589;
}

.createdUser.control {
  padding: 0px 0 0 17px;
  margin: -20px 0 0 0;
}

.controlLabel {
  font-size: 14px;
  color: #8c8c8c;
  margin-left: 17px;
}

.createdUser.control input {
  color: #ffffff !important;
}

.login.contactUs {
  color: #707070;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

.login.contactUs a:link {
  color: #6ebe49;
}

.login.contactUs a:hover {
  color: #6ebe49;
  text-decoration: none;
  font-weight: 500;
}

.login.contactUs a:active,
.login.contactUs a:visited {
  color: #6ebe49;
  text-decoration: none;
}

.loginFooter {
  color: #999999;
  font-size: 12px;
  margin-top: 10px;
}
.loginFooter a:link,
a:visited {
  color: #999999;
}

/*=====================*/
/*=====Modal Window====*/
/*=====================*/

.removeEmail {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
}

.modal-header.modalCenter h5.modal-title {
  width: 100%;
  text-align: left !important;
  font-size: 18px !important;
  font-weight: 500;
  margin-bottom: 24px;
  line-height: 22px;
}

.modal-header.modalCenter h5.modal-title.title-centered {
  text-align: center !important;
}

label.modalLabel {
  color: #737373 !important;
  font-size: 14px;
}

.form-control.modalForm {
  color: #1a1a1a;
  font-size: 16px;
}

.modal-body.less-padding {
  padding: 1rem !important;
}

.modal-footer.center {
  border-top: 0px !important;
  padding-top: 0px;
}

.modal-footer.center button {
  display: block;
  margin: 0 auto !important;
}

/*=====================*/
/*======Form Group=====*/
/*=====================*/
.form-header h5.form-title {
  width: 100%;
  text-align: center !important;
  font-size: 20px !important;
}

h5.form-title {
  text-align: center !important;
  font-size: 20px !important;
  color: #1a1a1a;
}

label.formLabel {
  color: #737373 !important;
  font-size: 14px;
}

/*=====================*/
/*======List Style=====*/
/*=====================*/
/*======================================Input requirement list styling STARTS here====================== */
.inputRequire {
  margin-top: 10px;
}

.inputRequire li {
  list-style: none;
  color: #737373;
  font-size: 11px !important;
  line-height: 1.7 !important;
}

.inputRequire li.valid {
  background: url("../images/list-inputRequire-valid.svg") no-repeat;
  /*background: url("../images/list-inputRequire-valid@2x.png") no-repeat; */
}

.inputRequire li.invalid {
  background: url("../images/check-circle.svg") no-repeat;
}

.inputRequire li.require {
  background: url("../images/list-inputRequire-bullet.svg") no-repeat;
}

.inputRequire li.valid img,
.inputRequire li.invalid img,
.inputRequire li.require img {
  padding-top: -5px;
  width: 20px;
  height: 20px;
}

.inputRequire li.valid,
.inputRequire li.invalid,
.inputRequire li.require {
  display: block;
  padding-left: 25px;
  margin-left: -25px;
  padding-top: -3px;
}

/*Input requirement list styling ENDS here */
/*=======================*/
/*======Containers=======*/
/*=======================*/
/*=======================================Mainbody content containers==================================*/
.pad-wrapper {
  padding-left: 32px;
  padding-right: 32px;
}

.no-gutter > [class*="col"] {
  padding-right: 0;
  padding-left: 0;
}

/*=============================Create Partner, User and Tenant Containers styling START here==========*/
.createdUser.userCtn,
.createdPartner.pNameCtn,
.createdTenant.tenantCtn,
.elevatedCtn {
  background: #ffffff;
  box-shadow: 0px 0px 10px #3661231a;
  border-radius: 6px;
  padding: 15px;
}

.createdUser.userCtn {
  height: 250px;
}

.createdTenant.tenantCtn {
  height: 150px;
  margin-bottom: 32px;
}

.elevatedCtn {
  margin-top: 25px;
}

.bordered {
  border: 1px dashed #8c8c8c;
}

.createdTenant.tenantCtn.bordered {
  padding: 7px;
}

.createdUser.userCtn.bordered,
.createdTenant.tenantCtn.bordered {
  border: 1px dashed #8c8c8c;
  background: rgba(255, 255, 255, 0);
}

.createdUser.userCtn.bordered:hover,
.createdTenant.tenantCtn.bordered:hover {
  background: #ffffff !important;
  cursor: pointer;
}

.partnerLogin.bordered {
  background: #ffffff;
  box-shadow: 0px 0px 10px #3661231a;
  border-radius: 6px;
  width: 456px;
  padding: 40px 48px;
}

.ownerLbl {
  /*User for Owner indicator for User List/Card*/
  width: 100%;
  border-radius: 15px;
  padding: 4px 15px;
  background: #e6e6e6;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.tenantCount {
  margin-left: -10px;
}

/*========================*/
/*=========Buttons========*/
/*========================*/
/*==================================Main Navigation Buttons styles START here==============*/
/* .navButtons {
  margin-left: 0px;
  margin-top: 30px;
} */

.navbar.sideNav {
  padding: 0px !important;
}

nav.nav {
  width: 100%;
  margin: 0px;
}

.navButtons nav .btn {
  width: 100% !important;
  height: 56px;
}

.btn.btn-link.hdr.logout {
  margin: -5px -15px 0 0;
}

.btn.btn-light.mainNav.logout {
  bottom: 0;
}

.btn.btn-light.mainNav {
  text-align: left;
  font-size: 14px;
  color: #333333;
  background-color: white;
  border: 0px;
  padding-left: 50px;
  display: inline-flex;
  vertical-align: middle;
  border-radius: 0px;
}

.btn.btn-light.mainNav i {
  color: #999999;
  margin-right: 15px;
}

.btn.btn-light.mainNav:hover {
  text-align: left;
  background-color: #edfae6;
  font-weight: 400;
  color: #5ca63a;
}

.btn.btn-light.mainNav.active {
  background-color: #edfae6 !important;
  font-weight: 400 !important;
  color: #5ca63a !important;
}

/*.btn.btn-light.mainNav:active,*/
.btn.btn-light.mainNav:focus {
  background-color: white !important;
}

.btn.btn-light.mainNav:hover > i {
  color: #5ca63a;
}

/*=====================================2nd Option of Navigatio Bar=======================*/
.btnNavCtn {
  height: 65px;
  border-left: 5px solid #ffffff;
  margin-top: 20px;
}

.btnNavCtn:hover {
  border-left: 5px solid #6ebe49;
}

.btnNavCtn i {
  font-size: 40px;
  color: #333333;
  z-index: 1 !important;
}

.btnNavCtn i:hover {
  color: #6ebe49;
  cursor: pointer;
}

.btn.btn-light.mainNav2 {
  color: #999999;
  font-size: 12px;
  background-color: transparent !important;
  text-transform: uppercase;
  padding: 0px !important;
  border: 0px !important;
  width: 100% !important;
  margin-top: -25px;
}

.btn.btn-light.mainNav2:hover {
  color: #6ebe49;
  text-decoration: #6ebe49;
}

.btn.btn-light.mainNav2:active,
.btn.btn-light.mainNav2:focus {
  border: 0px !important;
}

/*===================================== Partner Dashboard card icon =========================================*/
.dashboard.customerIcon {
  background: url("../images/partner-customers.svg") no-repeat;
  width: 30px;
  height: 28px;
  margin-left: 80%;
  padding: 0px;
  cursor: pointer;
}

.dashboard.bucketIcon {
  background: url("../images/partner-buckets.svg") no-repeat;
  width: 30px;
  height: 28px;
  margin-left: 80%;
  padding: 0px;
  cursor: pointer;
}

.dashboard.usageIcon {
  background: url("../images/partner-usage.svg") no-repeat;
  width: 30px;
  height: 28px;
  margin-left: 80%;
  padding: 0px;
  cursor: pointer;
}

.dashboard.ticketIcon {
  background: url("../images/partner-tickets.svg") no-repeat;
  width: 30px;
  height: 28px;
  margin-left: 80%;
  padding: 0px;
  cursor: pointer;
}

.dashboard.usage {
  width: 80%;
  height: 40px;
  margin-left: 10px;
  font-size: 20px;
  margin-bottom: 10px;
}

/* .dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
} */

.recharts-default-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(0, 0, 0) !important;
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  color: white;
}

/*=====================================3rd Option of Navigatio Bar===================================*/
/*=====================================3rd Option of Navigatio Bar===================================*/
.btn-light:hover {
  border: 0px;
}

.logoDisplay3 {
  background-image: url("../images/Logo-navigation3@2x.png");
  width: 100px;
  height: 40px;
  margin: 5px 0 0px 10px;
  padding: 0px;
  cursor: pointer;
}

/* .partnerName.dark {
  font-size: 18px;
  color: #fafafa !important;
  font-weight: 500;
  margin-top: -5px;
} */

.no-scroll {
  overflow: hidden !important;
}

/* .row.darkHdr {
  background: #1a1a1a;
  position: sticky !important;
  z-index: 1 !important;
} */

.navigation {
  height: 100vh !important;
}

/*.firstBtn button {
	margin-top:100px !important;
}*/
.navbar.sideNav.dark {
  background: primary-color-130;
  position: sticky;
}

nav.nav {
  position: sticky;
}

/* .btnNavCtn3 {
  border-left: 5px solid #333333;
  width: 100%;
  display: block;
  margin: 40px auto 0px auto !important;
}

.btnNavCtn3:hover {
  border-left: 5px solid #6ebe49;
}

.btnNavCtn3 i {
  font-size: 2.5vw;
  color: #fafafa;
  z-index: 1 !important;
}

.btnNavCtn3 i:hover {
  color: #6ebe49;
  cursor: pointer;
} */

/* .btn.btn-light.mainNav3 {
  color: #e6e6e6;
  font-size: 13px;
  background-color: transparent !important;
  text-transform: uppercase;
  text-align: center;
  padding: 0px !important;
  border: 0px !important;
  width: 100% !important;
  margin-top: -20px;
} */

.btn.btn-light.mainNav3:hover {
  color: #6ebe49;
  text-decoration: #6ebe49;
}

.btn.btn-light.mainNav3:active,
.btn.btn-light.mainNav3:focus {
  border: 0px !important;
  color: #ffffff !important;
}

.btn.btn-light.mainNav3:pressed,
.btn-light.mainNav3:not(:disabled):not(.disabled).active:focus,
.btn-light.mainNav3:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.mainNav3.dropdown-toggle:focus {
  border: 0px !important;
  color: #ffffff !important;
}

/*=====================================Main Navigation Buttons styles ENDS here==========*/
/*=====================================Add User Button styles STARTS here================*/
.btn.btn-link.addUser {
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  padding: 0px;
}

.btn.btn-link.addUser i {
  color: #8c8c8c;
  font-weight: 500;
  font-size: 44px !important;
  text-decoration: none;
}

.buttonEnabled,
.buttonDisabled {
  margin-left: 0;
}

/*Enabled State Styling*/
.buttonEnabled .btn.btn-base {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #438027;
  border-right: 0px;
}

.buttonEnabled .btn.btn-primary.bordered {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #454545;
  border-left: 0px;
}

.buttonEnabled .btn.btn-primary.bordered.grey {
  border-radius: 4px 0px 0px 4px;
  border-right: 0px;
  color: #999999;
  border: 1px solid #787878;
  border-right: 0px;
}

.buttonEnabled .btn.btn-primary.bordered:hover {
  border-left: 0px;
}

/*Disabled State Styling*/
.buttonDisabled .btn.btn-primary.bordered {
  color: #999999;
  border: 1px solid #787878;
  border-radius: 4px 0px 0px 4px;
  border-right: 0px;
}

.buttonDisabled .btn.btn-primary.bordered.grey {
  border-radius: 0px 4px 4px 0px;
  border-left: 0px !important;
  color: #999999;
  border: 1px solid #787878;
  border-left: 0px;
}

.buttonDisabled .btn.btn-primary.bordered:hover {
  border-right: 0px;
}

.buttonDisabled .btn.btn-secondaryL {
  color: #ffffff;
  border: 1px solid #990915 !important;
  border-radius: 0px 4px 4px 0px;
  border-left: 0px !important;
}

.buttonDisabled .btn.btn-secondaryL:hover {
  border: 1px solid #990915 !important;
  border-left: 0px !important;
}

/*=================================Add User Button styles ENDS here====================*/
/*=================================Add Tenant Button styles STARTS here================*/
.btn.btn-link.addTenant {
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 500 !important;
  text-align: center !important;
  padding: -2px !important;
  text-decoration: none;
  display: block;
  margin: 10px auto !important;
  width: 150px;
}

.btn.btn-link.addTenant i {
  color: #8c8c8c;
  font-weight: 500;
  font-size: 24px !important;
  text-decoration: none;
}

/*Add Tenant Button styles ENDS here*/
/*Forgot Password and Small Btn Green Button styles STARTS here*/
.btn.btn-link.forgotPWlink {
  color: #6ebe49;
  font-size: 13px;
  padding: 0px 3px 0px 0px;
  text-align: right !important;
}

.btn.btn-link.forgotPWlink:hover {
  color: #373737;
  font-size: 13px;
  text-decoration: none;
}

/*Login Button styles STARTS here*/
.btn.btn-success.partnerLogin,
.btn.btn-success.btnBaseColor {
  color: #ffffff;
  background: #6ebe49;
  font-size: 14px;
  font-weight: 400 !important;
  text-align: center !important;
  text-decoration: none;
  border: 1px solid #6ebe49;
  /*display: block;
  	margin: 10px auto !important;
      width: 150px;*/
}

.btn.btn-success.btnBaseColor.metric {
  color: #ffffff;
  background: #6ebe49;
  font-size: 14px;
  font-weight: 400 !important;
  text-align: center !important;
  text-decoration: none;
  margin-left: 10px;
}

.btn.btn-light.btnBaseColor.metric {
  color: #6ebe49;
  font-size: 14px;
  font-weight: 600 !important;
  text-align: center !important;
  text-decoration: none;
  margin-left: 10px;
}

.btn.btn-success.partnerLogin:hover,
.btn.btn-success.btnBaseColor:hover {
  background: #5ca63a;
  border: 1px solid #5ca63a;
}

.btn.btn-success.btnWite {
  color: #737373;
  background: #ffffff;
  font-size: 14px;
  font-weight: 400 !important;
  text-align: center !important;
  text-decoration: none;
  margin-left: 10px;
}

.btn.btn-success.btnWite:hover {
  background: #ecf1e9;
  border: 1px solid #4f504e;
}

/*Login Button styles ENDS here*/
/*Images*/
/*Logo Navigation*/
.logoDisplay {
  background-image: url("../images/Logo-navigation@2x.png");
  width: 159px;
  height: 60px;
  margin: 20px 0 30px 50px;
}

.logoDisplay2 {
  background-image: url("../images/Logo-navigation2@2x.png");
  width: 103px;
  height: 42px;
  margin: 40px 0 30px 20px;
}

.logoDisplay.login {
  margin-left: 0px;
}

.bg {
  /* The image used */
  background-image: url("../images/background-image@2x.png");
  /* width: 100%;

  Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*Material UI Icons*/
.userAccess i {
  color: #737373;
  font-size: 16px;
}

/*Toggle Button*/
input.switchInput[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.switchLbl {
  cursor: pointer;
  /*text-indent: -9999px;*/
  text-indent: 15px;
  padding-top: 3px;
  width: 180px;
  height: 32px;
  background: grey;
  display: block;
  border-radius: 4px;
  position: relative;
  z-index: 1 !important;
}

label.switchLbl:after {
  content: "Enabled";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 90px;
  height: 30px;
  background: #fff;
  border-radius: 4px;
  transition: 0.3s;
  font-size: 14px;
  color: #909090;
  padding-top: 4px !important;
}

input.switchInput:checked + label {
  background: #6ebe49;
  color: white !important;
}

input.switchInput:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
  content: "Disabled";
  font-size: 14px;
  color: #909090;
}

label.switchLbl:active:after {
  width: 130px;
}

span.enabled {
  margin-right: 45px;
  font-size: 14px;
  color: white;
}

span.disabled {
  font-size: 14px;
  color: #efefef;
}

/*// centering*/
/*============================Landing page =====================*/
.videoCtn {
  background: #000000;
  position: relative !important;
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.videoCtn video {
  opacity: 0.5;
  width: 100% !important;
  height: auto !important;
  z-index: 0;
}

.landingTitle {
  width: 100vw;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  bottom: 0 !important;
  position: sticky;
  vertical-align: bottom !important;
}

.videoCtn i {
  color: #ffffff;
  font-weight: 500;
  font-size: 110px !important;
  text-align: center;
  text-align: center;
  width: 100vw;
  margin-bottom: 200px;
  margin-top: 25%;
  opacity: 0.7;
  cursor: pointer;
}

.videoCtn h5 {
  color: #ffffff;
  font-weight: 500;
  font-size: 30px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  text-align: center;
}

.videoCtn h2 {
  color: #ffffff;
  font-weight: 500;
  font-size: 60px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 800;
  text-align: center;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/*=================================New Dashboard Styling======================*/
.iconKPI i {
  font-size: 4.2vw;
  color: #e6e6e6;
  z-index: 1px;
}

.iconKPI i.fa,
.iconKPI i.fas {
  font-size: 4vw;
  height: 65px;
  color: #e6e6e6;
  z-index: 1px;
}

.kpi.customerCt,
.kpi.userCt,
.kpi.bucketCt,
.kpi.usageCt,
.kpi.ticketCt {
  font-size: 48px;
  font-weight: 600;
  color: #333333;
  margin-top: -30px;
  text-align: left;
}

.kpi.ticketCt.total {
  font-size: 16px;
  font-weight: 400;
  color: black !important;
  margin-top: -50px;
  height: 50px;
}

.kpi.ticketCt.span {
  font-size: 24px;
  margin-top: -10px;
  margin-left: 10px;
  padding-right: 5px;
}

.kpi.newTicketCt {
  height: 40px;
  margin-top: -5px;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.kpi.ticketCt.noMargin.small {
  margin-top: 50px !important;
  text-align: center;
  font-size: 30px !important;
  font-weight: 400 !important;
}

.kpi.usageCt .small {
  font-size: 40px;
}

.kpi.title {
  font-size: 20px;
  font-weight: 400;
  color: #4d4d4d;
  text-align: left;
  margin-top: 0px;
}

.kpi.title.plus {
  margin-top: 2px;
}

/* .row.trend {
  margin-top: -20px;
} */

.trend .cardTitle,
.custDist.title .cardTitle {
  text-align: left;
  margin-left: 10px;
  font-weight: normal;
}

.custDist.title .cardTitle {
  margin: 20px 0 20px 0;
}

.graph.customerUsage,
.graph.totalUsage {
  text-align: center;
  /* padding: 20px 0 10px 0; */
}

.usagePercentage.f1 {
  background: #428027;
  color: #ffffff;
}

.usagePercentage.f2 {
  background: #5ca63a;
  color: #ffffff;
}

.usagePercentage.f3 {
  background: #6ebe49;
  color: #333333;
}

.usagePercentage {
  background: #a9de90;
  color: #333333;
}

.usagePercentage.light {
  background: #bce6a9;
  color: #333333;
}

/*=========== Dropdown style =========*/
.dropdown-menu > button.dropdown-item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  background: #fff;
  border: none;
}

.dropdown-menu > button.dropdown-item:focus,
.dropdown-menu > button.dropdown-item:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

/*=====================================3rd Option of Navigatio Bar===================================*/
.btn-light:hover {
  border: 0px;
}

.logoDisplay3 {
  background-image: url("../images/Logo-navigation3@2x.png");
  width: 100px;
  height: 40px;
  margin: 5px 0 0px 10px;
  padding: 0px;
}

.partnerName.dark {
  font-size: 16px;
  color: #ffffff !important;
  font-weight: 500;
  /* margin-top: -5px; */
}

.row.darkHdr {
  background: #333333;
  padding: 12px 25px 12px 23px;
  max-height: 64px;
}
.topbar-logo {
  width: 99px;
  height: 32px;
}

.navbar.sideNav.dark {
  background: #4d4d4d;
}

.btnNavCtn3 {
  height: auto;
  min-height: 70px;
  padding: 16px 14px;
  font-size: 14px;
  width: 100%;
  position: relative;
}
.btnNavCtn3 .nav-item-color {
  color: #cccccc;
}
.btnNavCtn3 .nav-item-label {
  font-family: "Roboto", sans-serif;
  color: #cccccc;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
}
.btnNavCtn3:hover {
  cursor: pointer;
  background-color: #404040;
}

.btnNavCtn3:hover .nav-item-color {
  color: #f5f5f5;
}

.sidebar-nav-icon:hover {
  color: #f5f5f5 !important;
}

.sidebar-navigation {
  max-width: 136px;
}
.btnNavCtn3.active-link {
  background-color: #333333;
  color: #6ebe49;
}

.btnNavCtn3.active-link::after {
  content: "";
  background-color: #6ebe49;
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.btnNavCtn3.active-link:hover {
  background-color: #333333;
}

.btnNavCtn3 i {
  font-size: 25px;
  color: #fafafa;
  z-index: 1 !important;
}

.btnNavCtn3.active-link .nav-item-color {
  color: #6ebe49;
}

.btnNavCtn3 .navigation-icon {
  height: 32px;
  width: 32px;
  display: inline-block;
}

.btnNavCtn3 .navigation-icon.icon-dashboard {
  background: url("../images/icons/icon-material-dashboard-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3:hover .navigation-icon.icon-dashboard {
  background: url("../images/icons/icon-material-dashboard-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-dashboard {
  background: url("../images/icons/icon-material-dashboard-active-32-pad.svg");
}

.btnNavCtn3 .navigation-icon.icon-shopping {
  background: url("../images/icons/icon-metro-shopping-basket2-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3:hover .navigation-icon.icon-shopping {
  background: url("../images/icons/icon-metro-shopping-basket2-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-shopping {
  background: url("../images/icons/icon-metro-shopping-basket2-active-32-pad.svg");
}

.btnNavCtn3 .navigation-icon.icon-shield {
  background: url("../images/icons/icon-awesome-shield-alt-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3:hover .navigation-icon.icon-shield {
  background: url("../images/icons/icon-awesome-shield-alt-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-shield {
  background: url("../images/icons/icon-awesome-shield-alt-active-32-pad.svg");
}

.btnNavCtn3 .navigation-icon.icon-assignment {
  background: url("../images/icons/icon-material-assignment-turned-in-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3:hover .navigation-icon.icon-assignment {
  background: url("../images/icons/icon-material-assignment-turned-in-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-assignment {
  background: url("../images/icons/icon-material-assignment-turned-in-active-32-pad.svg");
}

.btnNavCtn3 .navigation-icon.icon-users {
  background: url("../images/icons/icon-awesome-user-cog-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3 .navigation-icon.icon-customers {
  background: url("../images/icons/icon-awesome-users-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3 .navigation-icon.icon-settings {
  background: url("../images/icons/icon-awesome-cog-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3:hover .navigation-icon.icon-customers {
  background: url("../images/icons/icon-awesome-users-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-customers {
  background: url("../images/icons/icon-awesome-users-active-32-pad.svg");
}

.btnNavCtn3:hover .navigation-icon.icon-settings {
  background: url("../images/icons/icon-awesome-cog-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-settings {
  background: url("../images/icons/icon-awesome-cog-active-32-pad.svg");
}

.btnNavCtn3:hover .navigation-icon.icon-users {
  background: url("../images/icons/icon-awesome-user-cog-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-users {
  background: url("../images/icons/icon-awesome-user-cog-active-32-pad.svg");
}

.btnNavCtn3 .navigation-icon.icon-hands-helping {
  background: url("../images/icons/icon-awesome-hands-helping-normal-32-pad.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btnNavCtn3:hover .navigation-icon.icon-hands-helping {
  background: url("../images/icons/icon-awesome-hands-helping-hover-32-pad.svg");
}

.btnNavCtn3.active-link .navigation-icon.icon-hands-helping {
  background: url("../images/icons/icon-awesome-hands-helping-active-32-pad.svg");
}

.btn.btn-light.mainNav3 {
  color: #e6e6e6;
  font-size: 12px;
  background-color: transparent !important;
  text-transform: uppercase;
  padding: 0px !important;
  border: 0px !important;
  width: 100% !important;
  margin-top: -5px;
}

.btn.btn-light.mainNav3:hover {
  color: #6ebe49;
  text-decoration: #6ebe49;
}

.btn.btn-light.mainNav3:active,
.btn.btn-light.mainNav3:focus {
  border: 0px !important;
  color: #ffffff !important;
}

.btn.btn-light.mainNav3:pressed,
.btn-light.mainNav3:not(:disabled):not(.disabled).active:focus,
.btn-light.mainNav3:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.mainNav3.dropdown-toggle:focus {
  border: 0px !important;
  color: #ffffff !important;
}

.logoutIcn i {
  font-size: 18px;
  margin: 2px 5px 0 0;
  color: #737373;
  cursor: pointer;
}

.logoutIcn i:hover {
  color: #6ebe49;
}

.nameSignIn.hdr.dark {
  /* margin: 0 0 0 0px; */
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.btn.btn-link.hdr.dark.logout i {
  font-size: 22px;
  margin: 2px 5px 0 0;
  color: #fafafa;
  cursor: pointer;
}

/*=====================================Main Navigation Buttons styles ENDS here==========*/

/*=================================Account Switcher Dropdown===========================*/
.dropdown-menu.plain.accountSwitcher {
  width: 250px;
  color: #1a1a1a;
  margin-right: 20px;
  box-shadow: 0px 5px 15px #ededed;
}

.dropdown-menu.plain.accountSwitcher .userName {
  border-bottom: 1px solid #cccccc;
  padding: 0 0 10px 0;
}

.dropdown-menu.plain.accountSwitcher .userName h6 {
  margin: 0px;
  color: #1a1a1a !important;
}

.dropdown-menu.plain.accountSwitcher .userName p {
  color: #868589 !important;
  font-size: 11px;
  padding: 0px;
  margin: 0px;
}

.dropdown-menu.plain.accountSwitcher .userName .btn.btn-base-link {
  font-size: 13px;
  font-weight: bold;
  padding: 0px;
  color: #1a1a1a;
}

.dropdown-menu.plain.accountSwitcher .userName .circle {
  width: 55px;
  height: 55px;
  background: #737373;
  border-radius: 50%;
  margin: 5px 0 0 15px;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item.wAccess.last {
  border-bottom: 0px !important;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item.wAccess {
  color: #6ebe49 !important;
  font-size: 13px !important;
  font-weight: bold;
  padding: 12px 5px 12px 15px;
  border-bottom: 1px solid #e6e6e6;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item.woAccess {
  color: #333333 !important;
  font-size: 13px !important;
  font-weight: normal !important;
  padding: 12px 5px 12px 15px;
  height: 45px;
  border-bottom: 1px solid #e6e6e6;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item.wAccess.cust,
.dropdown-menu.plain.accountSwitcher a.dropdown-item.woAccess.cust {
  padding-left: 30px;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item i {
  color: #4d4d4d !important;
  margin: 0px 5px 0 0 !important;
  padding-top: 3px !important;
  font-size: 16px;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item i .goTrigger i.fas,
.dropdown-menu.plain.accountSwitcher .goTrigger i.fa,
.dropdown-menu.plain.accountSwitcher .goTrigger i {
  font-size: 12px !important;
  color: #868589;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item i .goTrigger i.fas :hover,
.dropdown-menu.plain.accountSwitcher .goTrigger i.fa :hover,
.dropdown-menu.plain.accountSwitcher .goTrigger i :hover {
  color: red !important;
}

.dropdown-menu.plain.accountSwitcher a.dropdown-item i .goTrigger i:hover {
  color: red !important;
}

.dropdown-menu.plain.accountSwitcher button.btn.btn-primary.bordered {
  display: block;
  margin: 5px auto !important;
  width: 120px;
}

.bottomCtn button.btn.btn-base.lg {
  margin-top: 22px;
  font-family: "Montserrat", sans-serif !important;
}

button.btn.btn-base.lg {
  background: #6ebe49;
  color: #ffffff;
  font-size: 17px !important;
}

button.btn.btn-base.lg.hovered,
button.btn.btn-base.lg:hover {
  background: #5ca63a;
  color: #ffffff;
}

button.btn.btn-base.lg.pressed,
button.btn.btn-base.lg:active {
  background: #428027;
  color: #ffffff;
}

button.btn.btn-base.lg:disabled,
button.btn.btn-base.lg[disabled] {
  background: #428027;
  color: #ffffff;
}

/*=============================Landing With Image Background/First Fold=======================*/
/* .img-fluid.landingImgCtn {
  background-image: url("../images/landing-img-bg-1@2x.png");
  background-repeat: no-repeat;
  width: 100vw !important;
  height: 100vh !important;
  opacity: 1 !important;
} */

.logoLanding.float {
  background-image: url("../images/Logo-landing.png");
  width: 115px;
  height: 45px;
  margin: 10px 0 0 30px !important;
  opacity: 1 !important;
}

.navbar.navbar-expand.floatHdr {
  background: #000;
  opacity: 0.5;
  height: auto;
  font-family: "Montserrat", sans-serif !important;
  padding-bottom: 10px !important;
  z-index: 1 !important;
}

.navbar.navbar-expand.floatHdr.opaque {
  background: #000;
  opacity: 1;
  height: auto;
  font-family: "Montserrat", sans-serif !important;
  padding-bottom: 10px !important;
  z-index: 1 !important;
}

.navbar.navbar-expand.floatHdr li.nav-item a {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
}

.navbar.navbar-expand.floatHdr li.nav-item a:hover {
  color: #fafafa;
  font-weight: 600;
}

.navbar-expand.floatHdr .navbar-nav .dropdown-menu {
  background: #000000;
}

.dropdown-item:focus,
.dropdown-item:hover {
  /* background: #1a1a1a !important; */
  color: #6ebe49 !important;
}

.navbar.navbar-expand.floatHdr li.nav-item.active a {
  color: #6ebe49 !important;
}

.navbar.navbar-expand.floatHdr li.nav-item a.nav-link.disabled {
  color: #cccccc !important;
}

.nav-item a.nav-link.active {
  font-weight: bold;
  color: #6ebe49 !important;
}

.navbar.navbar-expand.floatHdr li.nav-item.active a {
  font-weight: bold;
  color: #6ebe49 !important;
}

.navbar.navbar-expand.floatHdr li.nav-item.active a:hover {
  color: #ffffff !important;
}

/*==========================================================================*/
/*=============================NAVIGATION for FOOTER========================*/
/*==========================================================================*/
.footerCtn {
  padding-right: 30px;
  width: 100% !important;
}

.footerLogo {
  background-image: url("../images/footer-logo.png");
  width: 136px;
  height: 49px;
  margin: 20px 0 0 100px;
  float: left;
}

.footerCtn button.btn {
  margin-top: 20px !important;
  font-family: "Montserrat", sans-serif !important;
}

.footerNav {
  margin: 25px 0 0 0;
  float: right;
}

ul.footerList li.nav-item a.nav-link {
  display: inline;
  color: #fafafa;
  font-size: 15px;
}

/*======================Banner Styling of Texts======================*/
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 25% !important;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.bannerTexts {
  text-align: left;
  padding-top: 0 !important;
  opacity: 0.8;
}

.bannerTexts.center {
  text-align: center;
}

.bannerTexts h2 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 5.2vw;
}

.bannerTexts h2 .smaller {
  font-size: 4.9vw;
}

.bannerTexts h2 .primary {
  color: #1a1a1a !important;
}

.bannerTexts h2 .primary .smaller {
  font-size: 3.5vw !important;
}

.bannerTexts.odd h2 span.primary.smallest {
  font-size: 4.6vw;
  line-height: 1;
}

.bannerTexts h2 p,
.bannerTexts h4 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 200;
  font-size: 2.2vw;
}

.bannerTexts h2 p .base,
.bannerTexts h4 .base {
  color: #6ebe49 !important;
  text-shadow: 1px 1px 2px #000;
}

.bannerTexts h4 .base.noShadow {
  font-family: "Montserrat", sans-serif !important;
  text-shadow: none;
  font-size: 2vw !important;
}

.bannerTexts h5 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 200;
  font-size: 2vw;
}

.bannerTexts h5 .bigger {
  font-size: 2.7vw;
}

.bannerTexts h5 .bigger .drGray {
  color: #333333;
}

.bannerTexts h5 .bigger.drGray {
  color: #333333;
}

.bannerTexts.odd h2 span.primary.smallest {
  font-size: 4.1vw !important;
  line-height: 0.7 !important;
}

p.center {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  width: 30%;
  display: block;
  margin: 50px auto 0 auto !important;
}

.bannerTexts button.btn.btn-base-link {
  font-family: "Montserrat", sans-serif !important;
}

.btn.btn-primary.bordered.glow {
  color: #e6e6e6;
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  padding: 0px 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  text-decoration: none;
  background: #1a1a1a;
  border: 2px solid #6ebe49;
  box-shadow: 0px 0px 5px 1px #61be35;
  height: 45px;
}

.btn.btn-primary.bordered.glow:hover {
  color: #e6e6e6 !important;
  text-decoration: none !important;
  font-size: 18px !important;
  background: #000000;
  height: 45px;
  border: 2px solid #ffd110 !important;
  box-shadow: 0px 0px 5px 1px #ffd110;
}

.btn.btn-primary.bordered.glow i {
  color: #8c8c8c;
  font-weight: 500;
  font-size: 24px !important;
  text-decoration: none;
}

/*Forgot Password and Small Btn Green Button styles STARTS here*/
.btn.btn-link.forgotPWlink {
  color: #6ebe49;
  font-size: 13px;
  padding: 0px 3px 0px 0px;
  text-align: right !important;
}

.btn.btn-link.forgotPWlink:hover {
  color: #373737;
  font-size: 13px;
  text-decoration: none;
}

/*============================Start of 2nd Fold===================*/
.featuresFold {
  padding-top: 7%;
  padding: 100px 0 100px 0;
}

.featuresFold.landingText {
  /*height: 100vh;*/
  background: #000000;
}

.featuresFold.landingText h2 {
  color: #ffffff !important;
  font-size: 3vw;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  text-align: center;
}

.featuresFold.landingText h5 {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 2.2vw !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  text-align: center;
}

.featuresFold.landingText.scroll {
  height: auto !important;
  background: #000000;
}

.featuresFold.landingText.scroll .feature.name {
  text-align: left !important;
  font-weight: bold;
}

.featuresFold.landingText.scroll h5 {
  color: #999999 !important;
}

.featuresFold.landingText.scroll p {
  color: #e6e6e6 !important;
}

.featuresFold.landingText.scroll ul li {
  font-size: 17px;
  margin-bottom: 15px;
  color: #999999 !important;
}

.featuresFold.landingText.scroll .feature.cloudCog {
  display: block;
  margin: 0 auto !important;
}

.featuresFold.landingText.scroll .feature.cloudStorage {
  display: block;
  margin: 0 auto !important;
}

.featuresFold.landingText.scroll .feature.centralised {
  display: block;
  margin: 0 auto !important;
}

/*Features Icons*/
.featuresCtn {
  margin-top: 120px;
}

.feature.cloudCog {
  background-image: url("../images/feature-cog-cloud@2x.png");
  background-repeat: no-repeat;
  height: 190px;
  width: 250px;
  display: block;
  margin: 0 auto;
}

.feature.cloudStorage {
  background-image: url("../images/feature-storage@2x.png");
  background-repeat: no-repeat;
  height: 190px;
  width: 250px;
  display: block;
  margin: 0 auto;
}

.feature.centralised {
  background-image: url("../images/feature-centralised@2x.png");
  background-repeat: no-repeat;
  height: 190px;
  width: 250px;
  display: block;
  margin: 0 auto;
}

.feature.name {
  font-weight: 500;
  color: #6ebe49;
  font-family: "Montserrat", sans-serif !important;
  font-size: 23px;
  margin: 0 0 20px 0;
  text-align: center;
}

.feature.go {
  background-image: url("../images/arrow-forward@2x.png");
  background-image: no-repeat;
  width: 65px;
  height: 40px;
  margin: 0 0 20px 0;
}

.feature.intro {
  font-family: "Montserrat", sans-serif !important;
  color: #999999;
  font-size: 18px;
}

.feature.display.block {
  width: 75%;
  display: block;
  margin: 0px auto !important;
}

/*=========================Start of 3rd Fold===================*/
.infoFold.left {
  /*height:100vh !important;*/
  height: 100%;
  background: #000000;
  padding: 100px 50px 0 70px;
}

.infoFold.left h1 {
  font-size: 5.3vw;
  line-height: 1.2 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  color: #ffffff;
}

.infoFold.left .green {
  color: #6ebe49;
}

.infoFold.right .upperCtn {
  padding: 120px 50px 50px 180px;
}

.infoFold.right .upperCtn h4 {
  font-size: 2.4vw;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  color: #1a1a1a;
  margin-bottom: 0px;
  width: 100%;
}

.infoFold.right .upperCtn p {
  font-size: 1.3vw;
  font-weight: 100;
  font-family: "Montserrat", sans-serif !important;
  color: #333333;
  line-height: 1.4 !important;
  width: 85%;
  margin-top: 0px;
}

.infoFold.right .upperCtn ul {
  margin-top: 45px;
}

.infoFold.right .upperCtn ul li {
  font-size: 1.2vw;
  font-weight: 100;
  font-family: "Montserrat", sans-serif !important;
  color: #737373;
  line-height: 1.4 !important;
  width: 85%;
  margin-top: 0px;
  margin-bottom: 25px;
}

.infoFold.right .upperCtn button.btn {
  font-family: "Montserrat", sans-serif !important;
  margin: 80px 0 0 40px;
}

.infoFold.right .bottomCtn {
  background: #e6e6e6;
  padding: 50px 50px 80px 180px;
  position: absolute;
  width: 100%;
  bottom: 0 !important;
}

.infoFold.right .bottomCtn h5 {
  font-size: 2.2vw;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  color: #1a1a1a;
  width: 100%;
}

.infoFold.right .bottomCtn .input-group.mb-3 {
  width: 60% !important;
  margin-top: 30px;
}

.infoFold.right .bottomCtn .input-group.mb-3 .form-control {
  margin-right: 10px;
  border-radius: 4px !important;
  font-family: "Montserrat", sans-serif !important;
}

.infoFold.right
  .bottomCtn
  .input-group.mb-3
  .input-group-append
  .input-group-text {
  color: #4d4d4d;
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  padding: 0px 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  text-decoration: none;
  background: #e6e6e6;
  border: 2px solid #6ebe49;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px #61be35;
  height: 50px;
  cursor: pointer;
}

.infoFold.right
  .bottomCtn
  .input-group.mb-3
  .input-group-append
  .input-group-text:hover {
  color: #000000 !important;
  background: #e6e6e6;
  text-decoration: none !important;
  font-size: 18px !important;
  height: 50px;
  border: 2px solid #ffd110 !important;
  box-shadow: 0px 0px 5px 1px #ffd110;
}

.infoFold.right
  .bottomCtn
  .input-group.mb-3
  .input-group-append
  .input-group-text
  i {
  color: #8c8c8c;
  font-weight: 500;
  font-size: 24px !important;
  text-decoration: none;
}

/*================================Start of Contact Us Fold====================*/
.contactUs {
  height: auto !important;
  background: #000000;
  padding-bottom: 100px !important;
  margin-bottom: 90px !important;
}

.contactUs .leftCtn {
  padding: 0px 0 0 0;
}

.contactUs .leftCtn h4 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  font-size: 2.2wv;
  font-weight: 700;
  margin: 60px 0 0 90px;
}

.contactUs .leftCtn p {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.4wv;
  margin: 20px 0 0 90px;
}

.contactUs .rightCtn {
  padding: 80px 0 0 50px;
}

.contactUs .rightCtn form {
  width: 80%;
  font-family: "Montserrat", sans-serif !important;
}

.contactUs .rightCtn form span {
  font-family: "Montserrat", sans-serif !important;
  color: #ffffff !important;
}

.contactUs .rightCtn form button {
  width: 40%;
  min-width: 150px;
  margin: 30px 0 0 0;
  float: right;
}

.contactUs .footerCtn {
  /*background:#343434;*/
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0 !important;
}

.contactUs .footerCtn .footerNav .footerList {
  display: inline !important;
}

.contactUs .footerCtn .footerNav ul.footerList li.nav-item {
  display: inline !important;
  list-style: none !important;
  font-family: "Montserrat", sans-serif !important;
}

/*================================End of Landing Page==========================*/

/*===========Modal for Be Our Partner============*/
.modal.fade.partnerModal {
  padding: 0px 0 0 0 !important;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 7%;
}

.modal.fade.partnerModal .modal-dialog.wider {
  width: 750px !important;
  max-width: 900px !important;
  padding: 0px !important;
}

.modal.fade.partnerModal .modal-dialog.wider .modal-body {
  padding: 0rem;
}

.leftModal {
  width: 45%;
  padding: 50px 40px 30px 40px;
  float: left;
}

.leftModal h6 {
  color: #6ebe49;
  font-size: 25px;
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 25px;
  font-weight: 100 !important;
}

.leftModal h5 {
  color: #000000;
  font-size: 16px;
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 30px;
}

.leftModal p {
  color: #333333;
  font-size: 13px;
  font-family: "Montserrat", sans-serif !important;
}

.rightModal {
  width: 55%;
  height: auto !important;
  padding: 20px 40px 78px 40px;
  float: right;
  background: #e6e6e6 !important;
  border-radius: 0px 7px 7px 0;
}

.rightModal form {
  width: 100% !important;
  font-family: "Montserrat", sans-serif !important;
}

.rightModal form label {
  font-family: "Montserrat", sans-serif !important;
}

.rightModal form button {
  margin-top: 40px;
}

.rightModal div.close {
  position: relative;
}

.rightModal .formCtn {
  margin-top: 70px !important;
}

/*================================End of Be Our Partner==========================*/

.modal-with-gutters .modal-body {
  padding: 0 !important;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

/*================================== Edit Text CSS BEGINGS ==============*/
.my-view-container {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  padding: 5px;
}

.my-view-container[editext="view-container"]:hover {
  background: #eee;
}

.my-view-container[editext="edit-container"] {
  display: flex;
  border: 1px solid #444;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.my-view-container[editext="edit-container"] input,
.my-view-container[editext="edit-container"] textarea {
  padding: 0;
  border: none;
  font-weight: 400;
  color: #848484;
}

.my-view-container[editext="edit-container"] textarea {
  min-height: 90px;
}

.my-view-container div[editext="view"] {
  font: 300 16px/22px Roboto;
  letter-spacing: 0;
  color: #333333;
}

.my-view-container .edittext-button {
  width: 1.8em;
  height: 1.8em;
  margin-left: 2px;
  border-radius: 50%;
  padding: 0;
}

.my-view-container .edittext-button[editext="save-button"] {
  background: #6ecb42;
  color: white;
  border-color: #6ecb42;
}

.my-view-container .edittext-button[editext="edit-button"] .MuiSvgIcon-root {
  font-size: 1rem;
  margin-top: -1px;
}

.my-view-container .edittext-button .MuiSvgIcon-root {
  font-size: 1.5rem;
  margin-top: 0px;
}

[class*="styles_Editext__main_container"] {
  display: block;
}

/*================================== Edit Text CSS ENDS ==============*/

.table.table-free tbody tr,
.table.table-free thead tr {
  height: auto !important;
}

.attachment-area {
  /* overflow-x: auto; */
  /* margin-left: 24px; */
  /* margin-top: 10px; */
}

.attachment-area a {
  color: #333333;
  text-decoration: none;
  font-weight: 400;
}

.attachment-area span {
  color: #999999;
  text-decoration: none;
  font-weight: 400;
}

.attachment-area a:hover,
.attachment-area a:link,
.attachment-area a:visited {
  color: #333333;
  text-decoration: none;
  font-weight: 400;
}

.attachment-area .attachment span .MuiSvgIcon-root {
  transform: rotate(90deg);
}

.modal-content--area {
  padding: 1rem;
  height: calc(100% - 1px);
}

.modal-content--area.colored {
  background: #eee;
}

.load-content--2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.2);
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[class*="styles_Editext__validation_message"] {
  color: #dc3545 !important;
  font-size: 12.8px !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
}

.comment-area {
  overflow-x: auto;
}

.comment-area .comment {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d7d7d7;
}

.comment-area .comment:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.comment-area .comment .comment-text {
  font-size: 16px;
}

.comment-area .comment p {
  font-size: 14px !important;
  color: #000000d9;
  margin-bottom: 0;
  font-weight: 400;
}

.comment-area .comment .name {
  margin-right: 10px;
  color: #333333;
  font-size: 14px !important;
}
.comment-area .comment .comment-date {
  margin-right: 10px;
  color: #999999;
  font-size: 14px !important;
}

.progress {
  height: 24px;
  position: relative;
  margin-left: 24px;
  border: 1px solid #cccccc;
  margin-bottom: 6px;
}

.progress .progress-bar {
  background-color: #d0f2c0;
}

.progress .progress-bar span {
  position: absolute;
  z-index: 1;
  color: #333333;
  left: 10px;
  font-size: 14px;
  font-weight: 400;
}

.heading--1 {
  display: flex;
}

.heading--1 h2 {
  font-size: 20px;
}

.heading--1 h2 span {
  border-left: 4px solid hsl(101, 47%, 52%);
  padding-left: 15px;
}

/*=================== Support Resources Styling ======================*/
.media-card .image-container {
  transition: 0.3s ease;
}

.media-card .image-container:hover {
  /* border: 1px solid var(--white); */
  outline: -webkit-focus-ring-color auto 1px;
  transition: 0.3s ease;
}

.support-resource .card-deck-row {
  padding-top: 15px;
  padding-bottom: 15px;
}

.resource-video {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resource-video .overlay-icon {
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.media-card .image-container:hover i {
  color: var(--white);
  transition: 0.3s ease;
}

.resource-video .overlay-icon i {
  font-size: 80px;
  color: var(--gray-7);
  transition: 0.3s ease;
}

.support-resource-video.player-wrapper .react-player:focus {
  outline: none !important;
}
.support-resource-video.player-wrapper .react-player video:focus {
  outline: none !important;
}
.support-resource-video.player-wrapper:focus {
  outline: none;
}
.support-resource-video-modal .modal-content {
  border: none !important;
  background: none !important;
}
.support-resource-video-modal .support-resource-video-modal-header {
  padding: 0px !important;
}
.support-resource-video-modal
  .support-resource-video-modal-header
  button.close {
  margin: -0.5rem -1.5rem -1rem auto;
  opacity: 0.45;
  width: 24px;
  height: 26px;
  color: #ffffff;
  text-shadow: none;
  background-color: #333;
}
.support-resource-video-modal
  .support-resource-video-modal-header
  button.close:focus {
  outline: none !important;
  opacity: 0.75;
}
.support-resource-video-modal
  .support-resource-video-modal-header
  button.close:hover {
  opacity: 0.75;
}
.support-resource-video-modal .support-resource-video-modal-body {
  padding: 0px !important;
  top: -10px;
}
.support-resource-video-modal .support-resource-video-modal-body:focus {
  outline: none !important;
}
/*=================================Animated Login Styling===========================*/
.imageCtn {
  margin: auto;
  width: 850px;
  display: block;
}

.ltCtn {
  background: #ffffff;
  height: 491px;
  border-radius: 10px 0px 0px 10px;
  box-shadow: -1px -1px 10px 4px #101010;
  float: left;
  width: 425px;
  border: 1px solid #232323;
  border-right: 0px !important;
}

.rtCtn {
  background: #121212;
  height: 490px;
  border-radius: 0px 10px 10px 0px;
  width: 425px;
  float: right;
  border: 1px solid #232323;
  border-left: 0px !important;
  box-shadow: 12px 4px 10px 4px #101010;
}

.imageCtn.firstVw {
  background-image: url("../images/view1.png");
  background-repeat: no-repeat;
  z-index: 1 !important;
  width: 380px !important;
  height: 250px !important;
  position: absolute;
  top: 70px;
  margin-left: 25px;
}

.imageCtn.secondVw {
  background-image: url("../images/view2.png");
  background-repeat: no-repeat;
  z-index: 1 !important;
  width: 380px !important;
  height: 250px !important;
  position: absolute;
  top: 70px;
  margin-left: 25px;
}

.imageCtn.thirdVw {
  background-image: url("../images/view3.png");
  background-repeat: no-repeat;
  z-index: 3 !important;
  width: 380px !important;
  height: 250px !important;
  position: absolute;
  top: 70px;
  margin-left: 25px;
}

.imageCtn.fourthVw {
  background-image: url("../images/view4.png");
  background-repeat: no-repeat;
  z-index: 3 !important;
  width: 380px !important;
  height: 250px !important;
  position: absolute;
  top: 70px;
  margin-left: 25px;
}

.loginForm {
  padding: 20px 50px;
  height: 400px;
}

.loginForm .form-group span {
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4d4d4d;
}

.loginForm label {
  font-size: 24px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: bold;
  width: 70px;
  height: 40px;
  margin-left: 130px;
}

.loginForm .form-group input {
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
  color: #1a1a1a;
  height: 45px !important;
}

.loginForm .form-group small {
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  color: #868589 !important;
}

.loginForm .form-check {
  margin: 20px 0 0 0;
}

.loginForm .form-check span {
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4d4d4d;
}

.loginForm .form-check checkbox {
  background: #6ebe49;
  fill: green;
}

.loginForm .form-check a:link,
.loginForm .form-check a:visited {
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4d4d4d;
}

.loginForm .form-check a:hover,
.loginForm .form-check a:active {
  font-family: "Roboto", sans-serif !important;
  color: #6ebe49 !important;
  text-decoration: none;
}

.loginForm .btnCtn {
  background-color: #6ebe49;
  margin: 20px 0px 40px 0px !important;
}

.checkbox {
  width: 20px;
  margin: 15px auto;

  display: block;
}

.checkbox input[type="checkbox"]:checked ~ label::before {
  color: #7bbe72;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.loginForm .loginFooter {
  font-family: "Roboto", sans-serif !important;
  color: #999999 !important;
  text-align: center;
  font-size: 11px;
}

.logoDisplay.login {
  display: block;
  margin: 25px auto 10px auto !important;
}

.carouse.third {
  height: 300px !important;
}

/*1st Slide Texts*/
.textCtn.ft1 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  z-index: 2 !important;
  position: absolute;
  margin: -260px 0 0 70px;
  text-align: center;
  text-shadow: 1px 1px 2px #454545;
}

.ft1-h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: -3px !important;
  font-family: "Montserrat", sans-serif !important;
}

.ft1-h7 {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
}

.ft1-h8 {
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  font-family: "Montserrat", sans-serif !important;
}

.textCtn.ft1 {
  color: #ffffff;
}

/*2nd Slide Texts*/
.textCtn.ft2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  z-index: 2 !important;
  position: absolute;
  margin: -80px 0 0 70px;
  text-align: center;
  text-shadow: 1px 1px 2px #454545;
}

.ft2-h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: -3px !important;
  font-family: "Montserrat", sans-serif !important;
}

.ft2-h8 {
  color: #ffffff;
  font-weight: 400;
  font-size: 17px;
  font-family: "Montserrat", sans-serif !important;
}

.ft2-h7 {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif !important;
}

/*3rd Slide Texts*/
.textCtn.ft3 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  z-index: 2 !important;
  position: absolute !important;
  margin: -1610px 0 0 40px;
  text-align: center;
  text-shadow: 1px 1px 9px #313131;
}

.ft3-h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: -3px !important;
  font-family: "Montserrat", sans-serif !important;
}

.ft3-h8 {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 18px !important;
}

.ft3-h7 {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif !important;
}

/*4th Slide Texts*/
.textCtn.ft4 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  z-index: 1 !important;
  position: absolute !important;
  margin: -2420px 0 0 20px;
  text-align: center;
  text-shadow: 1px 1px 9px #313131;
}

.ft4-h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: -3px !important;
  font-family: "Montserrat", sans-serif !important;
}

.ft4-h8 {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 18px !important;
}

.ft4-h7 {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif !important;
}

.shape1 {
  position: relative;
  top: 62px;
  width: 400px;
}

.shape2 {
  position: relative;
  top: -435px;
  float: right;
  width: 250px !important;
  z-index: 1 !important;
}

.shape3 {
  position: relative;
  top: -650px;
  right: -2px;
  float: right;
  width: 300px !important;
}

.shape4 {
  position: relative;
  top: -390px !important;
  left: 150px;
  width: 100px;
}

.shape5 {
  position: relative;
  top: -160px !important;
  left: 320px;
  width: 80px;
}

.shape6 {
  position: relative;
  top: 30px;
  width: 159px !important;
  margin-left: -2px;
}

.shape7 {
  position: relative;
  top: -462px;
  right: -1px;
  width: 280px !important;
  float: right;
}

.shape8 {
  position: relative;
  top: -602px;
  width: 154px !important;
  float: right;
  z-index: 1 !important;
}

.shape9,
.shape10,
.shape11,
.shape12,
.shape15,
.shape16 {
  opacity: 0.8 !important;
}

.shape9 {
  position: relative;
  top: 32px;
  width: 457px !important;
}

.shape10 {
  /*Long Yellow Diagonal*/
  position: relative;
  top: -400px;
  margin-left: 130px;
  width: 274px !important;
}

.shape11 {
  position: relative;
  float: right;
  top: -820px !important;
  width: 274px !important;
}

.shape12 {
  position: relative;
  top: -915px !important;
  margin-left: 74px;
  width: 107px !important;
}

.shape13 {
  /*Lower Big Yellow Green With Radius*/
  position: relative;
  top: -830px;
  margin-left: 250px;
  width: 169px !important;
  z-index: 2;
}

.shape14 {
  /*Diagonal Teal Small*/
  position: relative;
  top: -1198px;
  width: 153px !important;
  margin-left: 253px;
}

.shape15 {
  /*Diagonal Teal Extra Small and Thin*/
  position: relative;
  top: -1238px;
  width: 262px !important;
  margin-left: 74px;
}

.shape16 {
  /*Truncated Yellow on bottom*/
  position: relative;
  top: -1334px;
  width: 93px !important;
  margin-left: 183px;
}

.shape17 {
  /*Upper Big Yellow Green With Radius*/
  position: relative;
  top: -1780px;
  margin-left: 125px;
  width: 159px !important;
  z-index: 2;
}

.shape18 {
  /*First Shape in 4th slide*/
  position: relative;
  width: 126px !important;
  top: 53px !important;
}

.shape19 {
  position: relative;
  width: 122px !important;
  margin-left: 64px;
  top: -287px !important;
}

.shape20 {
  position: relative;
  width: 48px !important;
  top: -628px !important;
  margin-left: 171px;
}

.shape21 {
  position: relative;
  width: 66.8px !important;
  top: -700px !important;
  margin-left: 208px;
}

.shape22 {
  position: relative;
  width: 158px !important;
  top: -750px !important;
  margin-left: 217px;
}

.shape23 {
  position: relative;
  width: 99.5px !important;
  top: -922px !important;
  margin-left: 273px;
}

.shape24 {
  position: relative;
  width: 152px !important;
  top: -1140px !important;
  margin-left: 270px;
}

.shape25 {
  position: relative;
  width: 71.6px !important;
  top: -1218px !important;
  margin-left: 352px;
}

.shape26 {
  position: relative;
  width: 207.1px !important;
  top: -1349px !important;
  margin-left: 219px;
}

.shape27 {
  position: relative;
  width: 201.6px !important;
  top: -1555px !important;
  margin-left: 180px;
}

.shape28 {
  position: relative;
  width: 108.7px !important;
  top: -1827px !important;
  margin-left: 186px;
}

.shape29 {
  position: relative;
  width: 108.7px !important;
  top: -2099px !important;
  margin-left: 124px;
}

.shape30 {
  position: relative;
  width: 88.2px !important;
  top: -2183px !important;
  margin-left: 296px;
}

.shape31 {
  position: relative;
  width: 67.6px !important;
  top: -2364px !important;
  margin-left: 356px;
}

.carousel.login {
  height: 490px !important;
}

.carousel-control-prev.hide,
.carousel-control-next.hide {
  opacity: 0.1 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  margin-top: 300px;
  visibility: hidden !important;
}

.imageCtn .carousel-indicators {
  position: absolute;
  right: 0;
  top: 450px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

a.btn.btn-base {
  background: #6ebe49;
  color: #ffffff !important;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  line-height: 2;
}

a.btn.btn-base.pressed,
a.btn.btn-base:active {
  background: #428027;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
}

a.btn.btn-base.hovered,
a.btn.btn-base:hover {
  background: #5ca63a;
  color: #ffffff !important;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
}

.rdt_TableCol {
  text-align: left;
  font: 500 14px/20px "Roboto", sans-serif !important;
  letter-spacing: 0;
  color: #1a1a1a;
  background: #fafafa;
  border-top: 0;
}

.rdt_TableCell {
  font: 400 14px/20px "Roboto", sans-serif !important;
}

.navigation {
  height: 100vh;
  background-color: #4d4d4d;
}

.contactSup button.btn.btn-light.mainNav3 {
  position: absolute !important;
  bottom: 90px !important;
  margin-bottom: 0px !important;
  font-size: 10px;
  color: #898989 !important;
}

.contactSup button.btn.btn-light.mainNav3 i {
  font-size: 20px !important;
  color: #909090 !important;
  margin-bottom: 5px;
}

.dashboardcard {
  width: 25% !important;
  /* margin-left: 1% !important; */
}

.form-control:focus {
  border-color: black;
  box-shadow: 0 0 0 0;
}

.form-control.is-invalid:focus {
  border-color: red;
  box-shadow: 0 0 0 0;
}

.auto {
  cursor: auto;
}

.default {
  cursor: default;
}

.none {
  cursor: none;
}

.context-menu {
  cursor: context-menu;
}

.help {
  cursor: help;
}

.pointer {
  cursor: pointer;
}

.progress {
  cursor: progress;
}

.wait {
  cursor: wait;
}

.cell {
  cursor: cell;
}

.crosshair {
  cursor: crosshair;
}

.text {
  cursor: text;
}

.vertical-text {
  cursor: vertical-text;
}

.alias {
  cursor: alias;
}

.copy {
  cursor: copy;
}

.move {
  cursor: move;
}

.no-drop {
  cursor: no-drop;
}

.not-allowed {
  cursor: not-allowed;
}

.all-scroll {
  cursor: all-scroll;
}

.col-resize {
  cursor: col-resize;
}

.row-resize {
  cursor: row-resize;
}

.n-resize {
  cursor: n-resize;
}

.e-resize {
  cursor: e-resize;
}

.s-resize {
  cursor: s-resize;
}

.w-resize {
  cursor: w-resize;
}

.ns-resize {
  cursor: ns-resize;
}

.ew-resize {
  cursor: ew-resize;
}

.ne-resize {
  cursor: ne-resize;
}

.nw-resize {
  cursor: nw-resize;
}

.se-resize {
  cursor: se-resize;
}

.sw-resize {
  cursor: sw-resize;
}

.nesw-resize {
  cursor: nesw-resize;
}

.nwse-resize {
  cursor: nwse-resize;
}

/* Begin Custom Css For colum sort icon */
/* .rdt_TableCol_Sortable .sc-fzpans.cTsoWV,
.rdt_TableCol_Sortable .sc-fzpans.eKRQOE,
.rdt_TableCol_Sortable .sc-fzpans.gNLrQw,
.rdt_TableCol_Sortable .sc-fzpans.fmpvlt {
  opacity: 1 !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: relative;
  color: #f5f5f5;
}

.rdt_TableCol_Sortable .sc-fzpans.cTsoWV:before,
.rdt_TableCol_Sortable .sc-fzpans.eKRQOE:before {
  content: "\f0dc";
  position: relative;
  left: 7px;
  color: gray;
}

.rdt_TableCol_Sortable .sc-fzpans.fmpvlt:before {
  content: "\f0de";
  position: relative;
  left: 7px;
  color: gray;
}

.rdt_TableCol_Sortable .sc-fzpans.gNLrQw:before {
  content: "\f0de";
  position: relative;
  left: 7px;
  color: gray;
} */

/* End Custom Css For colum sort icon */

/* Begin Custom Css For colum sort icon */
/* .rdt_TableCol_Sortable .sc-fzpans.cTsoWV,
.rdt_TableCol_Sortable .sc-fzpans.eKRQOE,
.rdt_TableCol_Sortable .sc-fzpans.gNLrQw,
.rdt_TableCol_Sortable .sc-fzpans.fmpvlt {
  opacity: 1 !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: relative;
  color: #f5f5f5;
} */

.rdt_TableRow {
  /* height: 53px !important; */
  min-height: 53px;
  border-bottom-color: #cccccc !important;
}

.rdt_TableHeadRow {
  /* height: 53px !important; */
  min-height: 53px !important;
  background: #f5f5f5 !important;
  border-bottom-color: #cccccc !important;
}

.rdt_TableRow:hover {
  background: #f5f5f5 !important;
  outline-color: #f5f5f5 !important;
}

.rdt_TableCell {
  color: #1a1a1a !important;
  text-align: left;
}

.rdt_TableCell span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.support-table .lyve-datatable > div {
  display: block;
}

.permissions-table .lyve-datatable > div {
  display: block;
}

.rdt_TableCol {
  color: #1a1a1a !important;
  text-transform: capitalize !important;
}

/* .rdt_TableCol_Sortable:hover {
  color: #1a1a1a !important;
} */

.rdt_Pagination {
  height: 53px !important;
  min-height: 53px !important;
  border-top-style: none !important;
  border-top-width: 0px !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: "Roboto", sans-serif !important;
}

/* .rdt_TableCol_Sortable .sc-fzpans.cTsoWV:before,
.rdt_TableCol_Sortable .sc-fzpans.eKRQOE:before {
  content: "\f0dc";
  position: relative;
  left: 7px;
  color: #cccccc;
}

.rdt_TableCol_Sortable .sc-fzpans.fmpvlt:before {
  content: "\f0de";
  position: relative;
  left: 7px;
  color: #6ebe49 !important;
}

.rdt_TableCol_Sortable .sc-fzpans.gNLrQw:before {
  content: "\f0de";
  position: relative;
  left: 7px;
  color: #6ebe49 !important;
} */
/* End Custom Css For colum sort icon */

.cursors > div {
  float: left;
  box-sizing: border-box;
  background: #f2f2f2;
  border: 1px solid #ccc;
  width: 20%;
  padding: 10px 2px;
  text-align: center;
  white-space: nowrap;
}

.cursors > div:nth-child(even) {
  background: #eee;
}

.cursors > div:hover {
  opacity: 0.25;
}

/* Begin Empty Table */
.table.empty-table {
  border-bottom: none;
}

.table.empty-table thead tr {
  border-bottom: 1px solid #cccccc;
  background-color: #fafafa;
}

.table.empty-table thead tr > th {
  cursor: pointer;
  color: #1a1a1a;
  vertical-align: middle !important;
  border-bottom: 1px solid #cccccc !important;
  font: 500 14px/20px "Roboto", sans-serif !important;
}

.table.empty-table thead tr > th i {
  color: #cccccc;
}

.table.empty-table thead tr > th:hover {
  color: #1a1a1a;
}

.table.empty-table td,
.table.empty-table th {
  vertical-align: middle;
  border-top: none;
}

.table.empty-table tbody td {
  border-bottom: none !important;
  text-align: center;
  height: 280px;
  font-size: 20px;
}

/* End Empty Table */

/* Being Tooltip */
.f-tooltip {
  padding: 4px 30px;
}

.f-tooltip .multi-line {
  padding: 0 0;
}

.f-tooltip-bagde {
  padding: 0;
  font-size: 14px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  padding-top: 1px;
  position: relative;
  left: 4px;
  top: 0px;
}
.f-tooltip-bagde.light {
  background: #999999;
}
/* End Tooltip */

/* Begin TabNav Style */

.nav-tabs .nav-link.active {
  border-top: 3px solid #6ebe49;
  background-color: #f5f5f5;
  border-bottom: #f5f5f5;
}

/* End TabNav Style */

.btn-success .custom-month-range-wrapper {
  font-weight: 700;
  color: #c0e3b0;
}

.btn-success .custom-month-range-wrapper .custom-month-range .from-month,
.btn-success .custom-month-range-wrapper .custom-month-range .sep-hyphen,
.btn-success .custom-month-range-wrapper .custom-month-range .to-month {
  color: white;
}

/* Start SAML Federation Style */
.btn.btn-link.updateMetadata {
  color: #6ebe49;
  font-weight: 400;
  text-decoration: underline;
}

.btn.btn-primary.saml-apply {
  width: 80px;
}

.btn.btn-primary.saml-apply:disabled {
  background-color: #999999;
  border-color: #999999;
}

.btn.btn-link.saml-cancel {
  font-weight: 400;
  color: #616161;
}

.delete-prompt .modal-footer {
  background-color: #f2f2f2;
}

.delete-prompt .modal-header {
  border-bottom: 1px solid #dfdfdf !important;
}

.delete-prompt .modal-body {
  min-height: 200px;
}

.progress.saml-progress {
  height: 24px;
  position: relative;
  margin-left: 0px;
  border: 1px solid #cccccc;
  margin-bottom: 6px;
  width: 500px;
}

.progress.saml-progress .progress-bar {
  background-color: #6ebe49;
}

.fileForm {
  min-height: 100px;
}

/* End SAML Federation Style */
.user-role {
  border-radius: 3px;
  padding: 5px;
  border: 1px solid gray;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.user-role-selected {
  background-color: #e3fcd8;
  border: 1px solid #a9e58f;
}
.user-role-disabled {
  background-color: #e6e6e6;
  border: 1px solid #6e6e6e;
}

.user-role-heading {
  font-size: 15px;
  font-weight: 500;
}

.user-role-info {
  font-size: 12px;
}

.user-role-radio input:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.configureChange {
  font-weight: 500;
  font-size: 15px;
  padding: 1.5rem 0;
  overflow: hidden;
  white-space: nowrap;
}

.configure-link {
  cursor: pointer;
  color: #73b557;
  text-decoration: underline;
}
.support-page-icon {
  color: #737373;
  padding: 7px 0px;
  line-height: 26px;
  font-weight: 400;
  font-size: 20px;
}
.support-card-description {
  color: #737373;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
}

.support-page-title {
  font-size: 1.25rem;
  color: #6ebe49;
  font-weight: 400;
  line-height: 26px;
}

.support-page-title:hover {
  text-decoration: underline;
  color: #5ca63a;
}

.support-page-title:active {
  text-decoration: underline;
  color: #438027;
}

.support-page-header {
  font-size: 2rem;
  font-weight: 400;
  color: #1a1a1a;
}

.support-page-subtitle {
  font-size: 1.125rem;
  color: #4d4d4d;
}

.IAM-support-wrapper .tabs,
.IAM-support-wrapper .nav-tabs {
  padding-left: 32px;
  padding-top: 16px;
  padding-right: 32px;
  background-color: #d9d9d9;
  margin-left: -32px;
  margin-right: -32px;
}

.IAM-support-wrapper .nav-tabs .nav-link {
  height: 48px;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-10) !important;
  background-color: rgba(245, 245, 245, 0.25);
  margin-right: 5px;
  /*border-top: 3px solid transparent;*/
  border-radius: 0 !important;
  white-space: nowrap;
  text-transform: capitalize;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0;
  padding-left: 2rem;
  padding-right: 2rem;
}
.IAM-support-wrapper .nav-tabs .nav-link:hover {
  /*color: rgba(51, 51, 51, 1) !important;*/
  /*background-color: rgba(245, 245, 245, 1);*/
  color: var(--gray-14) !important;
}

.IAM-support-wrapper .nav-item a.nav-link.active {
  font-weight: 500;
  color: var(--gray-14) !important;
  background-color: #fff;
  opacity: 1;
  border-bottom: 3px solid var(--gray-14) !important;
}

.nav-item a.nav-link.active,
.nav-tabs .nav-link.active {
  border-top: 3px solid #6ebe49;
  font-weight: 500;
  color: #333333 !important;
  background-color: #f5f5f5 !important;
  border-bottom: #f5f5f5;
}

.nav-tabs .nav-link:focus {
  outline: none !important;
}

.nav-tabs {
  border-color: var(--gray-6);
}

.IAM-support-page-bodyContent {
  padding-top: 32px;
  padding-bottom: 32px;
}

.section-header {
  padding-top: 4rem;
}

.pdf-modal .modal-content {
  width: 90%;
}

.pdf-modal .modal-dialog {
  width: 90%;
  max-width: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.dropdown.month .btn.btn-secondary.dropdown-toggle {
  background: #ffffff;
  /*color: #4d4d4d;*/
  color: #2e2e2e;
  font-size: 13px;
  text-align: left;
  border: 1px solid #cccccc;
  /* font-weight: bold; */
}
.dropdown.month .dropdown-item {
  color: #2e2e2e;
  font-size: 13px;
  text-align: left !important;
  height: 30px;
  /* font-weight: bold; */
}

.dropdown.month .dropdown-item:hover {
  color: #2e2e2e !important;
  background: rgb(236, 235, 235);
  /* font-weight: bold; */
}

.dropdown.month .active {
  background: #edfae6;
  font-weight: bold;
  color: #6ebe49 !important;
}

.monthpickerdialog {
  background-color: transparent;
  top: 54px;
  right: 10px;
  width: 400px;
  height: 138px;
  position: absolute;
}

/** Update btn-sm */
.btn-group-sm > .btn,
.btn-sm {
  border-radius: 4px;
}

/** General Button with Icon STARTS **/

.button-icon {
  margin-right: "0.25rem";
  font-size: "1rem !important";
}

/** General Button with Icon ENDS **/

.dropbtn {
  color: #1a1a1a;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  background-color: rgba(244, 245, 249, 0);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #e6e6e6;
  border-radius: 4px;
}

.contextMenuIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
}

.card-subtitle {
  color: #bbbbbb;
  font-size: 0.9em;
}

.modal-subtitle {
  margin-top: -12px;
  color: #8f8f8f;
  font-size: 0.8em;
}

.modal-label {
  color: #4d4d4d;
  font-size: 0.9em;
}

/************** Permissions Styling ************************/
.checkmark-grey {
  color: #707070;
}
.checkmark-green {
  color: #6ebe49;
}
.pull-right {
  float: right;
}
.error-message {
  color: #dc3545;
}
.permissions-modal-header {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}
.custom-dropzone-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.serviceAccountCard {
  top: 70%;
  left: 50%;
  z-index: 1;
  position: absolute;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
  max-width: 301px;
  overflow: auto;
}

.serviceAccountBody {
  flex: 1 1 auto;
  min-height: 1px;
}

.serviceAccountCard p {
  padding: 1.25rem;
  padding-bottom: 0;
  color: #1a1a1a;
}

.serviceAccountCard hr {
  margin-top: 0;
  margin-bottom: 0;
}

#permission-page .sc-fzpans {
  position: absolute;
}

#permission-page .column-header {
  padding-left: 1.5rem;
}

#permission-page .row {
  padding-left: 2.5rem;
}

#permission-page .context-menu-item {
  padding-left: 0 !important;
}

/* .serviceAccount-badge {
  min-width: 40px;
  height: 24px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.05);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 6px;
} */

.bucket-create-compliance {
  background-color: #e9f7fc;
  font-size: 13px;
  color: #008dbf;
  border: 1px solid #a3e7ff;
  margin-bottom: 20px;
}

.delete-ul {
  padding-left: 20px;
}
.delete-header {
  font-weight: 500;
  margin: 5px 0 10px 0px;
}
​ .bucket-create-dropdown {
  color: #000000;
  border-color: #000000;
}

.bucket-list-buttons {
  border: none;
  color: #FFFFFF;
  background-color: #4D4D4D;
  height: 25px;
  padding: 0 5px;
  margin-right: 6px;
}
.bucket-list-buttons:hover {
  color: #FFFFFF ;
  background-color: #4D4D4D;
  text-decoration: none;
  cursor: auto;
}

.view-bucket-buttons {
  display: grid;
  place-items: center;
  border: none;
  background-color: #ebebeb;
  font-size: 14px;
  height: 24px;
  padding: 0 5px;
  margin-right: 6px;
  width: max-content;
  border-radius: 5px;
}
.VB-card-title {
  font-size: 20px;
  color: #1a1a1a;
  line-height: 26px;
}
.VB-card-description {
  font-size: 14px;
  color: #808080;
  line-height: 20px;
    padding-top: 8px;
}
.VB-card-details-a{
  color: #4D4D4D;
  font-size: 14px;
  line-height: 20px;
  margin-top: 3px;
}
.VB-card-details-b{
  color: #4D4D4D;
  font-size: 16px;
}

.greenTickIcon {
  color: #6ebe49;
  margin-left: 5px;
  font-size: 17px;
}

.view-bucket-card {
  width: 50%;
  min-width: 600px;
  margin-bottom: 15px;
}

.replication-rules-card > .card-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.replication-rules-card .replication-rules-card-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.replication-rules-card .replication-delete-button {
  padding: 0px;
  line-height: 1;
}
.view-bucket-heading-span{
  color: #737373;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.view-bucket-heading{
  color: #737373;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
}
.view-bucket-heading:hover{
  color: #1a1a1a;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
}

.view-bucket-name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  margin: 16px 0 4px 0;
  color: #1a1a1a;
}

.view-bucket-badges{
  margin-bottom: 32px;
}

.bucket-badge {
  display: grid;
  place-items: center;
  font-size: 14px;
  border: none;
  background-color: #f5f5f5;
  color: #ffffff;
  background-color: #4d4d4d;
  height: 24px;
  padding: 2px 6px;
  margin-right: 6px;
  width: max-content;
  border-radius: 4px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.retention-period {
  font-weight: 500;
  padding: 10px 0;
}

/* .create-bucket-overlay {
  background-color: #4D4D4D;
  color: #ffffff;
  min-width: 350px;
} */

/* .create-bucket-overlay .checks {
  display: inline-block;
  padding: 5px;
} */

/* .create-bucket-overlay .checks .icon-invalid {
  padding: 5px 10px 5px 0;
  color: #999999;
}
.create-bucket-overlay .checks .icon-valid {
  padding: 5px 10px 5px 0;
  color: #6ebe49;
} */

.checks {
  padding: 5px 0 0 0;
    font-size: 13px;
}
.icon {
  display: grid;
  place-items: center;
  padding: 0 5px 0 0;
  font-size: 16px;
}
 .checks .icon-invalid {
  color: #999999;
}
 .checks .icon-valid {
  color: #6ebe49;
}
.retention-values{
  width: 200px;
  height: 40px;
}
.retention-duration-error {
  color: #808080;
  font-size: 14px;
  line-height: 20px;
}
