$neutral-color-3: #000 !default; // Tooltip backgroud, button fills, map null points.
$neutral-color-60: var(--gray-14) !default;
$neutral-color-80: var(--gray-14) !default;
$axis-labels-font-size: 12px !default;
$font-family: Roboto, "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !default;
$colors: #434348 #7cb5ec #90ed7d #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b #91e8e1 !default;

@for $i from 1 through length($colors) {
  $color: nth($colors, $i);
  .tooltip-text-#{$i - 1} {
    color: $color;
    font-size: 10px;
  }
}

@import "highcharts/css/highcharts";
