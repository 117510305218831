.lyve-month-picker {
  display: flex;

  &__container {
    &:not(:first-of-type) {
      margin-left: 40px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__arrows {
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  &__year {
    flex: 1;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  &__month-container {
    display: grid;
    justify-items: center;
    margin: 16px 0;
  }

  &__month {
    margin-bottom: 8px;
    width: 40px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    padding: 2px 0;
    font-size: 12px;
    line-height: 20px;
    color: var(--gray-13);

    &-disabled {
      color: var(--gray-13);
      opacity: 0.45;
      cursor: not-allowed;
    }

    &-single {
      background-color: var(--gray-14);
      color: var(--white);
      border-radius: 24px;
    }

    &.lyve-month-picker__month-range-start {
      background-color: var(--gray-14);
      color: var(--white);
      border-radius: 24px 0 0 24px;

      &.lyve-month-picker__month-range-end {
        border-radius: 24px;
      }
    }

    &.lyve-month-picker__month-range-end {
      background-color: var(--gray-14);
      color: var(--white);
      border-radius: 0 24px 24px 0;
    }

    &.lyve-month-picker__month-range-in-between {
      background-color: var(--gray-4);
    }
  }
}