.fill-bucket-name {
  height: 100%;
  display: grid;
  grid-template-rows: 56px auto;

  .subtitle {
    color: var(--gray-8);
  }

  .region-label {
    padding-top: 26px;
    color: var(--gray-4);
  }

  .region-picker {
    margin-bottom: 24px;

    &-error {
      margin-bottom: 8px;
    }

    .lyve-select__control {
      background-color: var(--black);
      border: 1px solid var(--gray-10);
      color: var(--gray-2);
      stroke: var(--gray-8);

      &:focus {
        border: 1px solid var(--gray-2);
      }

      &-error {
        border: 1px solid var(--red-3);
      }
    }

    .lyve-select__placeholder {
      color: var(--gray-2);
    }

    .lyve-select__single-value {
      color: var(--gray-2);
    }

    .lyve-select__menu {
      background-color: var(--gray-12);
    }

    .lyve-select__option {
      color: var(--gray-2);
      background-color: var(--gray-12);
      padding: 8px 10px;
      border-radius: 4px;
      //margin-bottom: 10px;

      &:hover,&:active {
        background-color: var(--gray-13);
      }

      &--is-selected {
        background-color: var(--gray-14);
        &:hover {
          background-color: var(--gray-14);
        }
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
      color: var(--gray-4);
      font-size: 14px;
    }

    .controlled-input {
      background-color: var(--black);
      border: 1px solid var(--gray-10);
      color: var(--gray-2);
      margin-bottom: 24px;

      &:focus {
        border: 1px solid var(--gray-2);
      }

      &-error {
        border: 1px solid var(--red-3);
        margin-bottom: 8px;
      }

      &.is-invalid {
        border: 1px solid var(--red-3);
      }
    }
  }

  .name-error {
    display: flex;
    padding-bottom: 8px;

    .message {
      font-size: 13px;
      padding-left: 8px;
      line-height: 16px;
      color: var(--gray-6);
      width: fit-content;
    }

    &:last-child {
      padding-bottom: 0;
    }

    svg {
      min-width: 16px;
    }

    &.valid {
      svg {
        fill: var(--green-3);
      }
    }

    &.default {
      svg {
        fill: var(--gray-9);
      }
    }
  }

  .bucket-name-error {
    color: var(--red-3);
    font-size: 14px;
    padding-bottom: 24px;
  }

  .validations {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    flex: 1;
    background-color: var(--gray-12);
    max-width: 490px;
    position: absolute;
    top: 69px;
    z-index: 1;
  }

  button.default-button.btn-dark.create-bucket-btn {
    margin: 0;
    align-self: flex-start;
    &-loading {
      min-width: 50px;
    }
  }
}
