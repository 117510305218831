// local mixin
@mixin label($fs: 20px, $lh: 26px, $fw: 400) {
  color: var(--gray-14);
  font-size: $fs;
  //line-height: $lh;
  //height: $lh;
  font-weight: $fw;
  margin: 0;
}

.custom-paper-card {
  padding: 1.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

  .custom-card-body {
    cursor: pointer;
  }

  &.basic-card .card-body {
    padding: 24px 0 0;
  }

  &.table-card {
    padding: 0;

    .lyve-table-card {
      box-shadow: none;
    }
  }

  &.metric-card .card-body, &.table-card .card-body {
    padding: 0;
  }

  &.basic-card .spinner-container, &.table-card .spinner-container {
    padding: 20px 20px 30px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.metric-card .spinner-container {
    padding: 20px 20px 30px 0;
    display: flex;
    align-items: center;
    //justify-content: center;
  }


  .image-container {
    position: relative;
    background: #000;
    border-radius: 12px;
  }

  .card-img {
    border-radius: 4px 4px 0 0;

    &-overlay {
      padding: 0;
    }
  }

  &-title {
    font-size: 20px;
    padding: 0 15px 15px;
  }
}

.basic-card {
  .title {
    @include label;
    //margin-bottom: 24px;
  }

  &.mb-32 {
    margin-bottom: 32px;
  }
}

.metric-card {
  // variants
  &.primary {
    background-color: var(--white);
  }

  &.secondary {
    background-color: transparent;
    box-shadow: none;
  }

  &.no-shadow {
    box-shadow: none;
  }

  .label-section {
    @include label;
    line-height: 26px;
    // display: flex;
    // align-items: center;
  }

  .metric-value-section {
    @include label(48px, 56px, 500);
    line-height: 56px;
    height: 56px;
  }

  .metric-details-container {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    flex: 1;
  }

  img {
    width: 44px;
    height: 44px;
  }
}

.media-card {
  .resource-icon {
    width: 48px;
    height: 48px;
    // TODO: add padding after new icons

    // TODO: temporary CSS
    > i {
      font-size: 32px;
    }
  }

  .resource-url {
    color: var(--green-3);
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 8px 0;
    display: block;

    &:hover,
    &:focus,
    &:active {
      // TODO: Needs re-visit as green-theme has important css
      color: var(--green-3) !important;
    }
  }

  .description {
    color: var(--gray-10);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
  }
}

/* .card.support-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none;
  border-radius: 4px;
  text-decoration: none;
  color: #3661231a;

  &.ticket-resolution-box{
    background: transparent !important;
    border: none !important;
    box-shadow: 0 0 black !important;
  }

  &.card-header {
    margin-bottom: 0;
    border-bottom: 2px solid #eee;
    background: #fff;
    text-align: left;
    font: 500 25px/30px Roboto;
    letter-spacing: 0;
    min-height: 60px;
    line-height: 60px;
    opacity: 1;
    border-bottom: 1px solid #ebedf2;
    padding: 0;
  }

  &.card-header .card-title {
    line-height: 60px;
    padding: 0 25px;
    font-size: 1.2rem;
    font-weight: 500 !important;
    color: #737373;
  }

  label {
    color: #737373;
  }

  button, 
  a[role="button"] {
    text-align: center;
    font: 300 14px/20px Roboto;
    letter-spacing: 0;
    opacity: 1;
  }

  .icon-area {
    height: 48px;
    width: 48px;
    position: absolute;
    right: 25px;
    top: 46px;
  }
  
  .icon-area img {
    height: 48px;
    width: 48px;
  }
  
  h3 {
    font-size: 20px;
    letter-spacing: 0px;
    color: #1a1a1a;
    opacity: 1;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 26px;
    margin-bottom: 0;
  }
  
  h2 {
    font-size: 48px;
    letter-spacing: 0px;
    color: #1a1a1a;
    opacity: 1;
    line-height: 56px;
    font-weight: 500;
    margin-bottom: 0;
  }
} */
