@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes flash {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes slidedown {
  from {
    transform: translateY(-32px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideup {
  from {
    transform: translateY(24px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes connecting {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 25;
  }
}
@keyframes moving4 {
  from {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }

  to {
    transform: translate(73px, 43px);
    opacity: 0;
  }
}

.lcm-wrapper {
  width: 250px;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;

  svg {
    #cloud,
    #gear {
      opacity: 0;
      animation: slidedown 1s cubic-bezier(0.6, -0.28, 0.74, 0.05) forwards;
    }
    #ui {
      opacity: 0;
      animation: slideup .6s cubic-bezier(0.6, -0.28, 0.74, 0.05) .4s forwards;
    }
    #lines {
      opacity: 0;
      animation: fadein 1s linear 1s forwards;
    }
    #line1,
    #line2,
    #line3,
    #line4,
    #line5 {
      animation: connecting 1s linear infinite;
    }
    #dot1,
    #dot2,
    #dot5,
    #dot3,
    #dot4 {
      opacity: 0;
      animation: moving4 1s linear 1s infinite forwards;
    }
    #dot4 {
      animation-delay: 1.1s;
      animation-duration: 1.1s;
    }
    #dot3 {
      animation-delay: 1.4s;
      animation-duration: 1.2s;
    }
    #dot5 {
      animation-delay: 1.3s;
      animation-duration: 1.1s;
    }
    #dot2 {
      animation-delay: 1.4s;
      animation-duration: 1.2s;
    }
    #dot1 {
      animation-delay: 1.2s;
    }
  }
}

$lines: line1 line2 line3 line4 line5;
@each $line in $lines {
  .lcm-wrapper svg ##{$line}.pause {
    animation-play-state: paused;
  }
}

$dots: dot1 dot2 dot3 dot4 dot5;
@each $dot in $dots {
  .lcm-wrapper svg ##{$dot}.pause {
    animation-play-state: paused;
  }
}

$items: lines cloud gear ui;
@each $item in $items {
  .lcm-wrapper svg ##{$item}.pause {
    opacity: 1;
    animation: none;
  }
}

.lcm-container {
  display: flex;
  margin: 0;
  justify-content: center;
  align-content: center;
  height: 100vh;
  align-items: center;
  background: #f5f5f5;
}