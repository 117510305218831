.outer-area {
  background-color: lightgrey;
}

.inner-area {
  background-color: white;
}

.notification-info {
  color: #737373;
  font-size: 18px;
  font-weight: 400;
}

.loader-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}