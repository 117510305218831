.view-bucket-card {
	a.helplink {
		color: #497F31 !important;
		text-decoration: none;
	}
	
	a.helplink:hover {
		color: #375F25 !important;
		text-decoration: underline;
		cursor: pointer;
	}
	
	a.helplink:active {
		color: #253F18 !important;
		text-decoration: underline;
	}
}
