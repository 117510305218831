.support-container {
    .data-loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .spinner {
          color: var(--green-3);
          width: 48px;
          height: 48px;
        }
      }
}