.dashboard-wrapper {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (min-width: 576px) {
    .card-deck {
      margin-right: -8px;
      margin-left: -8px;
    }

    .card-deck .card {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  .dashboard-general-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    color: var(--gray-14);
    font-weight: 300;
  }

  .card-deck {
    margin-bottom: 32px;
    margin-top: 16px;
  }

  .submetrics-value {
    color: #1A1A1A;
    font-size: 32px;
    font-weight: 400;
    padding-left: 8px;
  }

  .submetrics-label {
    display: inline-block;
  }

  .dashboard-date-picker {
    width: 240px;
    font-size: initial;
    line-height: initial;
  }

  .__react_component_tooltip .multi-line {
    text-align: left;
    line-height: 20px;
  }

  .estimate-cost-card-tooltip {
    margin-left: -5px !important;
  }

  .dashboard-graph {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .dashboard-graph-title {
      font-size: 20px;
      line-height: 26px;
      color: var(--gray-14);
      font-weight: 300;
    }

    .csv-download-link {
      display: none;
    }

    .csv-download-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-12);
      width: 96px;
      height: 40px;
      font-family: "Roboto", sans-serif;
      line-height: 20px;
      border: 1px solid var(--gray-12);
      border-radius: 4px;
      margin-left: 16px;

      &:hover {
        color: var(--white);
        background-color: var(--gray-14);
        border-color: var(--gray-14);
      }
    }

    .csv-download-btn-disabled {
      background-color: transparent;
      border-color: var(--gray-14);
      color: var(--gray-14);
      opacity: 0.45;
      pointer-events: none;
    }
  }

  .label-section {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }

  .chart-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: -24px;
  }

  .estimated-cost-section {
    display: inline-flex;
    align-items: flex-start;
  }

  .estimated-cost-card {
    min-height: 127.2px;
  }


  .account-cancelled {
    color: #99760C;
    font-weight: 500;
    background-color: var(--yellow-1);
    font-size: 14px;
    line-height: 16px;
    padding: 4px 6px;
  }

  .estimated-cost-link {
    flex: 1;
  }

  .estimated-cost-warning-icon {
    margin-left: 16px;
  }

  .refresh-icon {
    margin: 10px 5px;
    cursor: pointer;
  }

  .subtext {
    color: var(--gray-14);
    font-size: 32px;
    font-weight: 400;
  }

}
