.lyve-page-title {
	display: flex;
    margin-bottom: 32px;
    
    &.m-title-remove {
        margin-bottom: 0px  
    }

    .heading-area {
        margin-right: 64px;

        .lyve-page-main-title {
            display: block;
            line-height: 32px;
            font-weight: 300;
            margin-bottom: 8px;
            font-size: 24px;
            color: #1a1a1a;

            &.m-remove {
                margin-bottom: 0;
            }
        }

        .lyve-page-sub-title {
            font-size: 16px;
            font-weight: 400;
            color: #737373;
            line-height: 24px;
            display: block;
            margin-bottom: 0;
        }
    }

    .button-area {
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: auto;

        &.m-remove {
            margin-left: 0;

            .toggle-container {
                top: 5px;
            }
        }
    }
}