.ADM-main {
  height: 100vh;
  width: 100vw;
  padding: 0 0 0 0;
  display: grid;
  place-items: center;
  background-color: black;
}
.ADM-card {
  text-align: center;
  width: 50%;
  border-radius: 5px;
  padding: 20px 50px;
  background-color: white;
}
.ADM-title {
  margin: 20px 0 0 0;
  padding: 0;
  font-size: 15px;
  font-weight: bold;
}
.ADM-body {
  margin: 15px 0 40px 0;
  padding: 0;
  font-size: 12px;
}
.ADM-btn {
  width: 20%;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 3px;
  text-align: center;
}
