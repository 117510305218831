@mixin font_label($color: var(--gray-12)) {
  color: $color;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
}

@mixin font_text {
  color: var(--gray-14);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
