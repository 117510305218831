a.helplink {
	font-size: 14px;
	font-weight: normal;
	color: #6ebe49 !important;
	text-decoration: none;
}

a.helplink:hover {
	color: #5ca63a !important;
	// text-decoration: underline;
	cursor: pointer;
}

a.helplink:active {
	color: #438027 !important;
	// text-decoration: underline;
}
