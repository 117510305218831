/* Scss Document */
/* @import url(./fonts/fonts-googleapis.css);
@import url(custom-style.css); */

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./material-ui-font/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(./material-ui-font/MaterialIcons-Regular.woff2) format("woff2"),
    url(./material-ui-font/MaterialIcons-Regular.woff) format("woff"),
    url(./material-ui-font/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/*Base Color Seagate Green*/
/*Dark Gray to White Color Family*/
/*Red Color Family - Error*/
/*Border Mixins*/
/*Buttons Mixins*/
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9,
h10,
.siteName,
.pageTitle,
.modalLabel,
.modalCenter,
.nameSignIn,
.orgName,
.username,
.formLabel,
.login.contactUs,
.loginFooter {
  font-family: "Roboto" !important;
  /*font-size: calc(16px + 6 * ((100vw - 320px) / 680));*/
}

/*Breakpoints*/
/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/
/* // Small devices (landscape phones, 576px and up)*/
/*// Medium devices (tablets, 768px and up)*/
/*// Large devices (desktops, 992px and up)*/
/*// Extra large devices (large desktops, 1200px and up)*/
/* Additional Breakpoints aside from BootStrap 4 Standard */
/*@media all and (min-width:1200px){ }*/
/*@media all and (min-width: 960px) and (max-width: 1199px) { } For Ipad Lanscape*/
/*For Ipad Portrait*/
/*For Mobile Landscape*/
/*@media all and (max-width: 599px) { }*/
/*======================*/
/*======Colors Theme======*/
/*======================*/
/*Base Color Seagate Green*/
.baseColor120 {
  background-color: #428027;
}

.baseColor110 {
  background-color: #5ca63a;
}

.baseColor100 {
  background-color: #6ebe49;
}

.baseColor90 {
  background-color: #a9de90;
}

.baseColor80 {
  background-color: #bce6a9;
}

.baseColor70 {
  background-color: #d0f2c0;
}

.baseColor60 {
  background-color: #edfae6;
}

/*Dark Gray to White Color Family*/
.priColor150 {
  background-color: #000000;
}

.priColor140 {
  background-color: #1a1a1a;
}

.priColor130 {
  background-color: #333333;
}

.priColor120 {
  background-color: #4d4d4d;
}

.priColor110 {
  background-color: #737373;
}

.priColor100 {
  background-color: #868589;
}

.priColor90 {
  background-color: #999999;
}

.priColor80 {
  background-color: #cccccc;
}

.priColor70 {
  background-color: #e6e6e6;
}

.priColor60 {
  background-color: #fafafa;
}

.priColor50 {
  background-color: #ffffff;
}

/*Red Color Family - Error*/
.secColor120 {
  background-color: #990915;
}

.secColor110 {
  background-color: #c21322;
}

.secColor100 {
  background-color: #dc1f2e;
}

.secColor90 {
  background-color: #f5b5ba;
}

.secColor80 {
  background-color: #ffebed;
}

/*======================*/
/*======Typography======*/
/*======================*/
.siteName {
  color: #1a1a1a;
  font-size: 40px;
  font-weight: 300;
}

.pageTitle {
  color: #737373;
  font-size: 26px;
  font-weight: 300;
}

.modalTitle {
  color: #1a1a1a;
  font-size: 22px;
  font-weight: 500;
}

.cardTitle {
  color: #1a1a1a;
  font-size: 16px;
}

.leadParagraph {
  color: #1a1a1a;
  font-size: 26px;
  font-weight: 300;
}

.lgText {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 500;
}

.rgText {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 400;
}

.ltText {
  color: #999999 !important;
  font-size: 13px !important;
}

.ltText.code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.siteName.baseC,
.pageTitle.baseC,
.modalTitle.baseC,
.cardTitle.baseC,
.leadParagraph.baseC,
.lgText.baseC,
.rgText.baseC {
  color: #6ebe49;
}

.siteName small.muted,
.pageTitle small.muted,
.modalTitle small.muted,
.cardTitle small.muted,
.leadParagraph small.muted,
.lgText small.muted,
.rgText small.muted {
  color: #999999;
  font-weight: 400;
}

.lessWidth {
  width: 300px !important;
}

a:link,
a:visited {
  color: #6ebe49;
}

a:hover,
a:active {
	color: #868589;
	text-decoration: none;
	font-weight: 500;
}

/*================*/
/*====BUTTONS=====*/
/*================*/
/*=====================================Base Color Button================================*/
button.btn i {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

button.btn > .small i {
  font-size: 10px !important;
  vertical-align: middle;
  margin-right: 2px;
}

button.btn.btn-base {
  background: #6ebe49;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
}

button.btn.btn-base.hovered,
button.btn.btn-base:hover {
  background: #5ca63a;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
}

button.btn.btn-base.pressed,
button.btn.btn-base:active {
  background: #428027;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
}

button.btn.btn-base:disabled,
button.btn.btn-base[disabled] {
  background: #428027;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
}

/* Small Size Button */
button.btn.btn-base.small,
button.btn.btn-base.small.hovered,
button.btn.btn-base.small:hover,
button.btn.btn-base.small.pressed,
button.btn.btn-base.small:active,
button.btn.btn-base.small:disabled,
button.btn.btn-base.small[disabled] {
  font-size: 13px;
  height: 34px;
  font-weight: 500;
}

/*====================================Base Color Button with Border======================*/
button.btn.btn-base.bordered {
  background: #6ebe49;
  color: white;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #5ca63a;
}

button.btn.btn-base.bordered.hovered,
button.btn.btn-base.bordered:hover {
  background: #6ebe49;
  color: black;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #5ca63a;
}

button.btn.btn-base.bordered.pressed,
button.btn.btn-base.bordered:active {
  background: #bce6a9;
  color: #428027;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #5ca63a;
}

button.btn.btn-base.bordered:disabled,
button.btn.btn-base.bordered[disabled] {
  background: #bce6a9;
  color: white;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #5ca63a;
  opacity: 0.4;
}

/* Small Size Button */
button.btn.btn-base.bordered.small,
button.btn.btn-base.bordered.small.hovered,
button.btn.btn-base.bordered.small:hover,
button.btn.btn-base.bordered.small.pressed,
button.btn.btn-base.bordered.small:active,
button.btn.btn-base.bordered.small:disabled,
button.btn.btn-base.bordered.small[disabled] {
  font-size: 13px;
  height: 34px;
  font-weight: 500;
}

/*====================================Primary Color Button with Border======================*/
button.btn.btn-primary.bordered {
  background: #ffffff;
  color: #737373;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #737373;
}
button.btn.btn-primary.bordered.disable {
  background: #dc1f2e;
  color: white;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #737373;
  opacity: 0.45;
}

button.btn.btn-primary.bordered.enable {
  background: green;
  color: white;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #737373;
  opacity: 0.45;
}

button.btn.btn-primary.bordered.hovered,
button.btn.btn-primary.bordered:hover {
  background: #e6e6e6;
  color: #737373;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #737373;
}

button.btn.btn-primary.bordered.pressed,
button.btn.btn-primary.bordered:active {
  background: #cccccc;
  color: #1a1a1a;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #737373;
}

button.btn.btn-primary.bordered:disabled,
button.btn.btn-primary.bordered[disabled] {
  background: #ffffff;
  color: #737373;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #737373;
  opacity: 0.4;
}

/* Small Size Button */
button.btn.btn-primary.bordered.small,
button.btn.btn-primary.bordered.small.hovered,
button.btn.btn-primary.bordered.small:hover,
button.btn.btn-primary.bordered.small.pressed,
button.btn.btn-primary.bordered.small:active,
button.btn.btn-primary.bordered.small:disabled,
button.btn.btn-primary.bordered.small[disabled] {
  font-size: 13px;
  height: 34px;
  font-weight: 500;
}

/*=====================================Secondary Color Button================================*/
button.btn.btn-secondaryL {
  background: #dc1f2e;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 0px !important;
}

button.btn.btn-secondaryL.hovered,
button.btn.btn-secondaryL:hover {
  background: #c21322;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 0px !important;
}

button.btn.btn-secondaryL.pressed,
button.btn.btn-secondaryL:active {
  background: #990915;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 0px !important;
}

button.btn.btn-secondaryL:disabled,
button.btn.btn-secondaryL[disabled] {
  background: #dc1f2e;
  color: #ffffff;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  opacity: 0.2;
  border: 0px !important;
}

/* Small Size Button */
button.btn.btn-secondaryL.small,
button.btn.btn-secondaryL.small.hovered,
button.btn.btn-secondaryL.small:hover,
button.btn.btn-secondaryL.small.pressed,
button.btn.btn-secondaryL.small:active,
button.btn.btn-secondaryL.small:disabled,
button.btn.btn-secondaryL.small[disabled] {
  font-size: 13px;
  height: 34px;
  font-weight: 500;
}

/*=====================================Secondary Color Button with Border================================*/
button.btn.btn-secondaryL.bordered {
  background: #ffffff;
  color: #dc1f2e;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #c21322 !important;
}

button.btn.btn-secondaryL.bordered.hovered,
button.btn.btn-secondaryL.bordered:hover {
  background: #ffebed;
  color: #c21322;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #c21322 !important;
}

button.btn.btn-secondaryL.bordered.pressed,
button.btn.btn-secondaryL.bordered:active {
  background: #f5b5ba;
  color: #990915;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #c21322 !important;
}

button.btn.btn-secondaryL.bordered:disabled,
button.btn.btn-secondaryL.bordered[disabled] {
  background: #ffffff;
  color: #dc1f2e;
  font-size: 14px;
  height: 42px;
  font-weight: 500;
  border: 1px solid #c21322 !important;
  opacity: 0.2;
}

/* Small Size Button */
button.btn.btn-secondaryL.bordered.small,
button.btn.btn-secondaryL.bordered.small.hovered,
button.btn.btn-secondaryL.bordered.small:hover,
button.btn.btn-secondaryL.bordered.small.pressed,
button.btn.btn-secondaryL.bordered.small:active,
button.btn.btn-secondaryL.bordered.small:disabled,
button.btn.btn-secondaryL.bordered.small[disabled] {
  font-size: 13px;
  height: 34px;
  font-weight: 500;
}

/*==================================== Button Icons ============================================*/
button.btn.btn-base-icon i {
  color: #6ebe49;
  background: transparent !important;
}

button.btn.btn-base-icon i:hover {
  color: #428027;
  background: transparent !important;
}

/*Input requirement list styling ENDS here */
/*Containers*/
/*Mainbody content containers*/
.bodyContent {
  background: #fafafa !important;
  padding-top: 16px;
  /* height: 100vh !important; */
  /* padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px; */
}

button.btn.btn-primary-icon i:hover {
  color: #000000;
  background: transparent !important;
}

button.btn.btn-secondaryL-icon i {
  color: #dc1f2e;
  background: transparent !important;
}

button.btn.btn-secondaryL-icon i:hover {
  color: #990915;
  background: transparent !important;
}

/*==================================== Button Link ============================================*/
button.btn.btn-base-link {
  color: #6ebe49;
}

button.btn.btn-base-link:hover,
button.btn.btn-base-link.hovered {
  color: #428027;
  text-decoration: underline;
}

button.btn.btn-base-link.pressed,
button.btn.btn-base-link:active {
  color: #428027;
  text-decoration: underline;
  font-weight: 600;
}

button.btn.btn-base-link.disabled,
button.btn.btn-base-link:disabled,
button.btn.btn-base-link[disabled] {
  color: #cccccc;
  text-decoration: underline;
  font-weight: 500;
}

.btn-base-icon i:hover {
  color: #428027;
  background: transparent !important;
}

.btn-primary-icon i {
  color: #333333;
  background: transparent !important;
}

.btn-primary-icon i:hover {
  color: #000000;
  background: transparent !important;
}

.btn-secondaryL-icon i {
  color: #dc1f2e;
  background: transparent !important;
}

.btn-secondaryL-icon i:hover {
  color: #990915;
  background: transparent !important;
}

/*===========================*/
/*======DROPDOWN BUTTONS=====*/
/*===========================*/
/*===================================Light Gray Dropdown===================================*/
.dropdown.ltGray .btn.btn-secondary.dropdown-toggle {
  background: #ffffff;
  /*color: #4d4d4d;*/
  color: #070707;
  font-size: 13px;
  text-align: left;
  border: 1px solid #cccccc;
}

.dropdown.ltGray .dropdown-item {
  color: #1a1a1a;
  font-size: 14px;
  text-align: left !important;
  height: 30px;
}

.dropdown-menu.show {
  width: 100%;
  position: absolute;
  top: 5px;
  left: 0px;
  transform: translate3d(0px, 35px, 0px);
  will-change: transform;
}

.dropdown.ltGray .dropdown-item:hover {
  background: #edfae6;
  font-weight: bold;
}

.Dropdown-control {
  font-size: 13px;
}

.dropdown-toggle::after {
  position: relative !important;
  right: 0 !important;
}

/*=================*/
/*======TABLES=====*/
/*=================*/
/*===========================Light Table Header Background and Hover Light Green==============*/
table.table.table-light.ltHeader tr th {
  background: #fafafa;
}

table.table.table-light tr td {
  font-size: 14px;
  height: 35px !important;
}

table.table.table-light tr:hover {
  background: #edfae6;
}

table.table.table-light tr td.tdLink {
  color: #6ebe49;
  font-weight: bold;
  cursor: pointer;
}

table.table.table-light tr td.tdLink:hover {
  color: #1a1a1a;
}

table.table.table-light.bordered {
  font-size: 14px;
  height: auto !important;
  border: 1px solid #e6e6e6;
}

/*===========================Dark Table Header Background and Hover Light Green==============*/
table.table.table-lightdrHeader tr td {
  font-size: 14px;
  height: 35px !important;
}

table.table.table-light.drHeader tr:hover {
  background: #6ebe49;
}

table.table.table-light.drHeader tr td.tdLink {
  color: #6ebe49;
  font-weight: bold;
  cursor: pointer;
}

table.table.table-light.drHeader tr:hover td.tdLink {
  color: #ffffff;
}

table.table.table-light.drHeader tr td.tdLink:hover {
  color: #1a1a1a;
}

table.table.table-light.bordered {
  font-size: 14px;
  height: auto !important;
  border: 1px solid #e6e6e6;
}

/*===========================Dark Table Header Background and Hover Green==============*/
table.table.table-light.drHeader tr th {
  background: #333333;
  color: #ffffff;
}

/*===========================All Dark Table Header and Rows with Hover Green==============*/
/*===========================Table Row Colored==============*/
/*-----------------------*/
/*------ALERT MESSAGE----*/
/*-----------------------*/
/*=============================Positive Alert Message=========================*/
.alert.base {
  background: #edfae6;
  color: #428027;
  padding: 10px;
  border: 1px solid #6ebe49;
}

.alert.base a:link,
.alert.base a:visited {
  font-weight: bold;
  color: #428027;
  text-decoration: none;
}

.alert.base a:hover {
  font-weight: bold;
  color: #428027;
  text-decoration: underline;
}

/*=============================Neutral Alert Message=========================*/
.alert.neutral {
  background: #e6e6e6;
  color: #333333;
  padding: 10px;
  border: 1px solid #999999;
}

.alert.neutral a:link,
.alert.neutral a:visited {
  font-weight: bold;
  color: #4d4d4d;
  text-decoration: none;
}

.alert.neutral a:hover {
  font-weight: bold;
  color: #4d4d4d;
  text-decoration: underline;
}

/*=============================Negative Alert Message=========================*/
.alert.negative {
  background: #ffebed;
  color: #990915;
  padding: 10px;
  border: 1px solid #dc1f2e;
}

.alert.negative a:link,
.alert.negative a:visited {
  font-weight: bold;
  color: #990915;
  text-decoration: none;
}

.alert.negative a:hover {
  font-weight: bold;
  color: #990915;
  text-decoration: underline;
}

/*--------------------------------------------------------------------*/
/*--------------------------CARDS DESIGNS ----------------------------*/
/*--------------------------------------------------------------------*/
/*======================Card with Basic Color===============================*/
.card.basic {
  background: #ffffff;
  box-shadow: 0px 0px 10px #3661231a;
  border-radius: 6px;
  padding: 15px 15px 0 15px;
  margin: 20px 10px 0 0px;
}

.card.basic.dashboard {
  height: 122px;
}

.card.basic.chart {
  height: 500px;
}

.card-link a,
.card a {
  border: 0px;
  color: #6ebe49;
}

.card-link a:hover,
.card a:hover {
  border: 0px;
  color: #000000;
  text-decoration: none;
  opacity: 1;
}

/*========================Card with Color Blocking on Footer================*/
.card.basic .card-blocking-bottom {
  border-top: 1px solid #868589;
  background: #ffffff;
  color: #4d4d4d;
  border-radius: 0 0 6px 6px;
  margin-top: 10px !important;
  margin: -12px;
  padding: 15px;
}

.card.basic .card-blocking-bottom.primary {
  background: #000000;
  color: #ffffff;
  border-radius: 0 0 6px 6px;
  margin-top: 10px !important;
  margin: -12px;
  padding: 15px;
}

.card.basic .card-blocking-bottom.base {
  background: #6ebe49;
  margin: -12px;
  padding: 15px;
  color: #ffffff;
  border-radius: 0 0 6px 6px;
  margin-top: 10px !important;
  margin: -12px;
  padding: 15px;
}

/*========================Card with Color Blocking on Header================*/
.card.basic .card-blocking-top.base .cardTitle,
.card.basic .card-blocking-top.primary .cardTitle {
  color: #ffffff;
}

.card.basic .card-blocking-top {
  border-bottom: 1px solid #868589;
  background: #ffffff;
  color: #4d4d4d;
  border-radius: 6px 6px 0px 0px;
  margin: -12px -12px 15px -12px;
  padding: 15px;
}

.card.basic .card-blocking-top.primary {
  background: #000000;
  color: #ffffff;
  border-radius: 6px 6px 0px 0px;
  margin: -12px -12px 15px -12px;
  padding: 15px;
}

.card.basic .card-blocking-top.base {
  background: #6ebe49;
  margin: -12px;
  padding: 15px;
  color: #ffffff;
  border-radius: 6px 6px 0px 0px;
  margin: -12px -12px 15px -12px;
  padding: 15px;
}

/*--------------------------------------------------------------------*/
/*-----------SIDE BAR CUSTOM STYLING FOR UI KIT GUIDE ONLY -----------*/
/*--------------------------------------------------------------------*/
nav.lt-nav .navbar-brand {
  color: #ffffff !important;
}

.btn.btn-info i {
  color: #343434;
  font-size: 35px;
}

.collapse.list-unstyled {
  background: #292929;
}

.collapse.list-unstyled li > a {
  color: #ffffff !important;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  perspective: 1500px;
}

#sidebar ul li.active > a,
.collapse.list-unstyled li.active > a.dropdown-item {
  color: #ffffff !important;
  /*background-color:#303030 !important;*/
  border-bottom: 2px solid #898989 !important;
  font-weight: 500 !important;
  /*
      color:#212121 !important;
  	background-color:#6ebe49 !important;
  	border-radius: 0px 20px 20px 0px !important;
  	width: 80% !important;*/
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #898989 !important;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #303030;
  color: #fff;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom left;
}

#sidebar.active {
  margin-left: -250px;
  transform: rotateY(100deg);
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #303030;
}

#sidebar ul.components {
  padding: 20px 0;
  /*border-bottom: 1px solid #898989;*/
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: #ffffff;
}

#sidebar ul li a:hover {
  color: #6ebe49;
  background: #242424;
  text-decoration: none;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  /*background: #303030;*/
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block !important;
  position: static !important;
  top: 50%;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #303030;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #343434;
}

a.article,
a.article:hover {
  background: #303030 !important;
  color: #fff !important;
  text-decoration: none;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  cursor: pointer;
}

#sidebarCollapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #555;
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
  transform: rotate(45deg) translate(2px, 2px);
}

#sidebarCollapse span:nth-of-type(2) {
  opacity: 0;
}

#sidebarCollapse span:last-of-type {
  transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
  transform: none;
  opacity: 1;
  margin: 5px auto;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
    transform: rotateY(90deg);
  }

  #sidebar.active {
    margin-left: 0;
    transform: none;
  }

  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }

  #sidebarCollapse.active span {
    margin: 0 auto;
  }

  #sidebarCollapse.active span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }

  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0;
  }

  #sidebarCollapse.active span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
}

/* popup window*/
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

/*// centering*/

/*----------------------------------------------*/
/*----------DATA TABLE CUSTOM STYLING ----------*/
/*----------------------------------------------*/

/* Pagination width fix */
.lyve-datatable + div {
	width: 100%;
}

/* Context Menu fix */
.lyve-table-card .lyve-datatable {
	overflow: visible;
}

/* Options Overlay */
.options-overlay {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 1px #e6e6e6;
}

.options-overlay > button {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  text-align: start;
}

button.overlay-option i {
  margin-right: 8px;
}

button.overlay-option {
  color: #1a1a1a !important;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  padding: 8px 16px;
  font-weight: 400;
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
}

button.overlay-option:hover {
  background-color: #f5f5f5;
}

button.overlay-option:focus {
  box-shadow: none !important;
}

.table-close-icon i {
  margin-right: 0px !important;
}

a.caseNumber {
  color: #497F31 !important;
  text-decoration: none;
}

a.caseNumber:hover {
  color: #375F25 !important;
  text-decoration: underline;
  cursor: pointer;
}

a.caseNumber:active {
  color: #253F18 !important;
  text-decoration: underline;
}

.highcharts-root {
  font-family: inherit !important;
}
