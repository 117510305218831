.card.base-card {
  border-bottom: 1px solid var(--gray-5);
  //box-shadow: 4px 4px 6px #00000066;
  box-shadow: none;
  opacity: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;

  .card-body {
    padding: 24px 0 0;
    display: flex;
    //button.default-button {
    //  font-size: 14px;
    //}
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: var(--gray-14);

    &-strip {
      border-left: 4px solid var(--green-3);
      padding-right: 8px;
    }
  }

  .description {
    color: var(--gray-9);
    font-size: 14px;
    //letter-spacing: 0.1px;
  }

  .dismiss-img {
    cursor: pointer;
  }
}