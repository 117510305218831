.delete-service-account-module {
  .delete-service-account-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 26px;
    color: var(--gray-13);
    overflow-wrap: break-word;
  }

  .delete-service-account-title2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 24px;
    color: var(--gray-13);
    overflow-wrap: break-word;
  }

  .delete-service-account-subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .service-account-label {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-10);
    margin-bottom: 8px;
  }

  .service-account-name {
    font-size: 13px;
    margin: 0;
    overflow-wrap: break-word;
  }

  .validation-error {
    font-size: 11px;
    color: var(--red-3);
    margin-top: 6px;
  }

  .spinner {
    width: 20px;
    height: 20px;
  }
}