.create-partner-customer {
    .checks {
        padding: 8px 0 0 0;
        font-size: 14px;
        color: #737373;
    }
    .checks .checks-icon {
        display: grid;
        place-items: center;
    }
    .checks .invalid {
        color: #cccccc;
    }
    .checks .valid {
        color: #6ebe49;
    }
}