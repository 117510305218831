a.helplink {
	color: #6ebe49 !important;
	text-decoration: none;
}

a.helplink:hover {
	color: #5ca63a !important;
	// text-decoration: underline;
	cursor: pointer;
}

a.helplink:active {
	color: #438027 !important;
	// text-decoration: underline;
}
.view-mfa-card {
	width: 70%;
	min-width: 600px;
	margin-bottom: 15px;
}
.mfa-alert-info {
	border: 0px !important;
}