.tag {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;

  &-primary {
    background-color: #F1FFEB;
    color: #5CA63A;
  }

  &-secondary {
    background-color: #E7EFFF;
    color: #5D83D4;
  }
}
