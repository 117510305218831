.customer-subaccount-wrapper {
	margin-top: 32px;

	.ticket-filter {
		width: 200px;
	}

	.filter-div {
		display: flex;
		align-items: center;
	}

	.filter-span {
		margin-right: 8px;
	}

	.button-area {
		display: flex;
		align-items: center;
	}

	.external-link {
		color: var(--gray-14);
		margin-right: 10px;

		&:hover {
			font-weight: 400;
			text-decoration: underline;
		}
	}

	.data-loader {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.spinner {
			color: var(--green-3);
			width: 48px;
			height: 48px;
		}
	}

	.heading-area {
		margin-right: 64px;

		.lyve-page-main-title {
			display: block;
			line-height: 32px;
			font-weight: 300;
			margin-bottom: 24px !important;
			font-size: 24px;
			color: #1a1a1a;

			&.m-remove {
				margin-bottom: 0;
			}
		}

		.lyve-page-sub-title {
			font-size: 16px;
			font-weight: 400;
			color: #737373;
			line-height: 24px;
			display: block;
			margin-bottom: 0;

			.helplink {
				color:  #497F31 !important;
				&:hover {
					color: #375F25 !important;
					font-weight: 400 !important;
					text-decoration: underline;
				}
				&:active {
					color: #253F18 !important;
					font-weight: 400 !important;
					text-decoration: underline;
				}
			}
		}
	}
}

a.helplink {
	color: #6ebe49 !important;
	text-decoration: none;
}

a.helplink:hover {
	color: #5ca63a !important;
	// text-decoration: underline;
	cursor: pointer;
}

a.helplink:active {
	color: #438027 !important;
	// text-decoration: underline;
}

.subaccount-action-modal {
	.acc-name {
		color: #4D4D4D;
		font-size: 14px;
		line-height: 20px;
	}

	.acc-value {
		display: inline-block;
		padding-top: 6px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: var(--gray-14);
	}

	.confirm-action-btn {
		margin-right: 10px;
	}

	label {
		text-transform: none !important;
	}

	.form-group {
		margin-bottom: 0;
	}
}
