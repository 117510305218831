.account-api-confirmation {
  .field-container {
    margin-top: 24px;
  }

  .warning-message-section {
    background-color: var(--red-1);
    border: 1px solid var(--red-2);
    align-items: center;
    padding: 5px;
    border-radius: 3px;
  }

  .warning {
    color: var(--red-3);
    font-size: 12px;
    margin: 0;
  }

  .field-label {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-12);
  }

  .field-value {
    color: var(--black);
    font: normal normal bold 14px/20px Roboto;
    display: flex;
    align-items: flex-end;
  }

  .field-value.service-account-name {
    overflow-wrap: break-word;
  }

  .secret-key-container {
    display: flex;

    .form-group {
      flex: 1;
    }

    .form-label {
      display: none;
    }

    textarea {
      border-radius: 4px 0 0 4px;
      margin: 0;
      resize: none;
      height: 40px;
    }

    button {
      border-radius: 0 4px 4px 0;
    }
  }

  .copy-endpoint-btn {
    cursor: pointer;
    margin-left: 0.5rem;

    &:hover {
      background: #E6E6E6 0% 0% no-repeat padding-box;
    }

    &:active {
      background: #CCCCCC 0% 0% no-repeat padding-box;
    }

  }
}