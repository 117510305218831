/* Variables */

:root {
  // Neutral
  --white: #ffffff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #e6e6e6;
  --gray-6: #cccccc;
  --gray-7: #b3b3b3;
  --gray-8: #999999;
  --gray-9: #808080;
  --gray-10: #737373;
  --gray-11: #616161;
  --gray-12: #4d4d4d;
  --gray-13: #333333;
  --gray-14: #1a1a1a;
  --gray-15: #737373;
  --black: #000000;

  // Primary
  --green-1: #f1ffeb;
  --green-2: #cef0c0;
  --green-3: #6ebe49;
  --green-4: #5ca63a;
  --green-5: #438027;

  // Semantic
  --blue-1: #e9f7fc;
  --blue-2: #c0e4f2;
  --blue-3: #00a1dd;
  --blue-4: #007aa6;

  --red-1: #ffebed;
  --red-2: #f0c0c4;
  --red-3: #dc1f2e;
  --red-4: #c21322;
  --red-5: #990915;
  --red-6: #f93946;

  --yellow-1: #fff6db;
  --yellow-2: #f0e4c0;
  --yellow-3: #f8be15;
  --yellow-4: #8f6b00;

  // grid
  --min-screen-width: 1366px;
  --max-screen-width: 1920px;
  
  // navigation
  --sidebar-width-expanded: 224px;
  --sidebar-width-collapsed: 56px;
  --top-navbar-height: 40px;

  // modal
  --modal-width-small: 640px;
  --modal-width-large: 1300px;

  //banner
  --top-banner-height: 40px;
}
