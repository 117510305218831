.service-account-confirmation {
  .confirmation-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 26px;
    color: var(--gray-13);
  }

  .field-container {
    margin-top: 24px;
  }

  .warning-message-section {
    background-color: var(--red-1);
    border: 1px solid var(--red-2);
    align-items: center;
    padding: 5px;
    border-radius: 3px;
  }

  .warning {
    color: var(--red-3);
    font-size: 12px;
    margin: 0;
  }

  .field-label {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-12);
  }

  .field-value {
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
  }
  .field-value.service-account-name{
    overflow-wrap: break-word;
  }

  .secret-key-container {
    display: flex;

    .form-group {
      flex: 1;
    }

    .form-label {
      display: none;
    }

    textarea {
      border-radius: 4px 0 0 4px;
      margin: 0;
      resize: none;
      height: 40px;
    }

    button {
      border-radius: 0 4px 4px 0;
    }
  }
}
