.error-permission-module {
  .error-permission-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 26px;
    color: var(--gray-13);
  }

  .error-permission-subtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .permission-label {
    font-size: 11px;
    color: var(--gray-10);
    margin-bottom: 3px;
  }

  .permission-name {
    font-size: 13px;
    margin: 0;
  }

  .validation-error {
    font-size: 11px;
    color: var(--red-3);
    margin-top: 6px;
  }

  .spinner {
    width: 20px;
    height: 20px;
  }
}