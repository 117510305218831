@import "../../theme/mixins";

@mixin pseudo_elem {
  &:focus {
    border: 1px solid var(--gray-14);
    box-shadow: none;
  }

  &:disabled &[readonly] {
    background-color: var(--gray-5);
    border: 1px solid var(--gray-5);
    color: var(--gray-9);
  }

  &.is-invalid {
    border-color: var(--red-3) !important;
    background-image: none;

    &:focus {
      box-shadow: none;
    }
  }
}

@mixin custom_input {
  @include font_text;
  @include pseudo_elem;
  background-color: var(--white);
  border: 1px solid var(--gray-6);
  padding: 7px 12px;
}

@mixin base_checkbox_style {
  width: 16px;
  height: 16px;
  display: inline-block;
  visibility: visible;
  border: 1px solid var(--gray-7);
  appearance: initial;
  -moz-appearance: initial;
  -webkit-appearance: initial;
  padding: 0;
}

.custom-form-group {
  .label {
    @include font_label;
    margin-bottom: 8px;
  }

  .form-check-label {
    @include font_text;
    cursor: pointer;
  }

  .form-check {
    padding-left: 24px;
  }

  .form-check-input {
    margin-left: -24px;
    outline: none;
  }

  label.custom-check {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 5px;
    margin: 0;
  }

  input,
  select {
    @include custom_input;
    height: 40px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    @include base_checkbox_style;

    &::before {
      background-color: var(--white);
    }

    &:disabled::before {
      background-color: var(--gray-7);
    }
  }

  input[type="checkbox"] {
    border-radius: 4px;

    &:checked + .custom-check.checkbox {
      border-radius: 4px;
      background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z'/></svg>");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-color: var(--green-3);
      border-color: var(--green-3);
    }

    &:disabled + .custom-check.checkbox {
      background-color: var(--gray-7);
      border-color: var(--gray-7);
    }
  }

  input[type="radio"] {
    border-radius: 50%;

    &:checked + .custom-check.radio {
      border-radius: 50%;
      border: 5px solid var(--green-3);
    }

    &:disabled:checked + .custom-check.radio {
      background-color: var(--white);
      border-color: var(--gray-7);
    }

    & ~ .form-check-label {
      color: var(--gray-14);
    }
  }

  textarea {
    @include custom_input;
  }

  .invalid-feedback {
    @include font_label(var(--red-3));
    text-transform: none;
    margin-top: 8px;
    display: unset;
  }
}
