.view-bucket-badges {
  justify-content: space-between;
  width: 50%;
  min-width: 600px;
  flex-direction: column;
}

.copy-btn-wrapper {
  background-color: #E6E6E6;
  margin-top: 8px;
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 14px;
    color: var(--gray-14);
    padding-left: 7px;
    line-height: 20px;
  }
}

.copy-endpoint-div {
  padding-right: 32px;
}

.bucket-header-card-text.col{
  padding-left: 0px;
}

.copy-endpoint-icon {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  text-align: center;
  background-color: initial;
  margin-left: 8px;
  display: inline-block;
  position: absolute;
  top: -1px;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
      background-color: #e6e6e6 !important;
  }

  &:active {
    background-color: #cccccc !important;
  }
}

.bucket-details-options {
  width: 50%;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .view-bucket-badges {
    width: 100%;
    min-width: 0px;
    margin-bottom: 24px !important;
    flex-direction: column;
    margin-left: auto;
  }

  .view-bucket-buttons-wrapper {
    flex-direction: column;
  }

  .bucket-badge-wrapper {
    margin: 16px 0px;
  }

}

.view-bucket-container {
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 0;
}

.view-bucket-title {
  display: flex;
  align-items: center;
}

.view-bucket-name-title {
  font: 300 24px/32px Roboto;
  color: #1a1a1a;
}

.view-bucket-region {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 500;
}

.globe-icon {
  margin-right: 5px;
}

.view-bucket-region-text {
  display: flex;
  place-items: center;
  border: none;
  background-color: #ebebeb;
  font-size: 14px;
  height: 24px;
  padding: 0 5px;
  width: max-content;
  border-radius: 5px;
}

.view-bucket-badge {
  display: grid;
  place-items: center;
  font-size: 14px;
  border: none;
  background-color: #f5f5f5;
  color: #ffffff;
  background-color: #4d4d4d;
  height: 24px;
  padding: 2px 6px;
  width: max-content;
  border-radius: 4px;
  margin-left: 6px;
}

.view-bucket-header {
  display: flex;
  flex-flow: row;
  margin-bottom: 16px;
  background: white;
  
  .card{
    margin-right:auto;
    box-shadow: none !important;
  }
  .card-body {
    padding: 10px 16px 10px 16px !important;
  }
}

.bucket-header-card-title {
  font: 400 14px/20px Roboto !important;
  color: #4d4d4d;
  margin-bottom: 6px !important;
}

.bucket-header-card-text {
  font: 500 14px/20px Roboto;
  color: #1a1a1a;
  padding: none;
}

.view-bucket-tabs {
  height: 48px;
  margin-bottom:24px !important;
  border-bottom: 2px solid #cccccc !important;

  .nav-item,
  a.nav-link {
    font: 500 14px/20px Roboto;
    color: #737373;
  }

  .nav-link:hover, .nav-link.active {
    color: #1a1a1a;
    border: none !important;
  }

  .nav-link.active {
    border-bottom: 2px solid #1a1a1a !important;
  }
}

.data-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    color: var(--green-3);
    width: 48px;
    height: 48px;
  }
}