.lyve-alert {
  padding: 12px 16px !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0 !important;

  .alert-heading {
    font-size: 14px;
    margin-bottom: 0px;
  }

  p {
    margin-bottom: 15px;
  }

  ul,ol {
    margin-left: -16px;
    margin-bottom: 0;
  }

  &.alert-info {
    color: var(--gray-14);
    background-color: #E7EFFF;
    border-color: #CFDEFF;
  }

  &.alert-danger {
    color: var(--gray-14);
    background-color: var(--red-1);
    border-color: var(--red-2);
  }

  &.alert-warning {
    color: var(--gray-14);
    background-color: var(--yellow-1);
    border-color: var(--yellow-2);
  }

  &.alert-success {
    color: var(--gray-14);
    background-color: var(--green-1);
    border-color: var(--green-2);
  }
}