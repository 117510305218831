.custom-metric-card {
    &.primary {
      height: 122px;
      padding: 15px 15px 0;
  
      //.body-container {
      //  padding: 15px 15px 0;
      //}
      .card-body {
        padding: 0;
      }
    }
  }
  
  .customer-distribution-card {
    .card-body {
      padding: 0;
    }
  }
  
  .lyve-badge {
    background: var(--gray-5) 0 0 no-repeat padding-box;
    border-radius: 4px;
    padding: 3px 15px;
    display: block;
  }

  .dashboard-datepicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    flex-direction: row;

    .dashboard-datepicker-title {
      font-size: 20px;
      line-height: 24px;
      color: var(--gray-14);
      font-weight: 300;
    }

    .csv-download-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-12);
      min-width: 96px;
      height: 40px;
      font-family: "Roboto", sans-serif;
      line-height: 20px;
      border: 1px solid var(--gray-12);
      border-radius: 4px;
      margin-left: 16px;

      &:hover {
        color: var(--white);
        background-color: var(--gray-14);
        border-color: var(--gray-14);
      }
    }

    .csv-download-btn-disabled {
      background-color: transparent;
      border-color: var(--gray-14);
      color: var(--gray-14);
      opacity: 0.45;
      padding: 0px 5px;
      pointer-events: none;
    }
  }

  .partner-dashboard-wrapper {
    margin: 32px 0px;
  }