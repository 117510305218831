.input-with-rules {
  position: relative;
}

.input-rules-tooltip {
  max-width: 496px !important;
  word-break: break-all !important;
}

.input-validators {
  &-focused {
    box-shadow: 0 5px 10px #0000001F;
    border-radius: 4px;
    background: white;
    padding: 10px;
    position: absolute;
    top: 71px;
    width: 100%;
    z-index: 1;
  }

  &-hidden {
    display: none;
  }

  .validation-block {
    display: flex;
    padding-bottom: 8px;

    .message {
      font-size: 13px;
      padding-left: 8px;
      line-height: 16px;
      color: var(--gray-12);
      width: fit-content;
    }

    &:last-child {
      padding-bottom: 0;
    }

    svg {
      min-width: 16px;
    }

    &.valid {
      svg {
        fill: var(--green-3);
      }
    }

    &.invalid {
      svg {
        fill: var(--gray-6);
      }
    }
  }
}
