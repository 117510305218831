.expiry-setting-modal {
    &.modal-dialog-centered {
        top: 10px;
        align-items: flex-start;
     }
     
    .title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: var(--gray-14);
    }

    .subtitle {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: var(--gray-14);
        margin-top: 28px;
        margin-bottom: 26px;
    }

    button.default-button.btn-primary {
        height: 32px;
    }
}