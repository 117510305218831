.create-bucket-modal {
	.region-api-loader {
		display: flex;
		align-items: center;
		.spinner-container {
			.lyve-spinner {
				width: 10px;
				height: 10px;
				color: var(--gray-14);
				border-width: 2px;
				vertical-align: inherit;
			}
		}
	}
	.loading-text {
		font-size: 14px;
		padding-left: 6px;
	}
}

.create-bucket-module {
	.create-bucket-title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 24px;
		line-height: 26px;
		color: var(--gray-14);
	}

	.validation-container {
		margin: 4px 0 16px;
		padding: 1px;
	}

	.validation-item {
		display: flex;
		align-items: flex-start;
		margin-right: 4px;
		font-size: 11px;
		color: var(--gray-11);
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.validation-error {
		font-size: 11px;
		color: var(--red-3);
		margin-top: 6px;
	}

	.validation-icon {
		font-size: 14px;
		margin-right: 3px;
		color: var(--gray-6);
		flex: 0 0 14px;
		margin-top: 1px;

		&.valid {
			color: var(--green-3);
		}
	}

	.submit-button {
		font-size: 12px;
		text-transform: capitalize;
		padding: 10px 16px;
		font-weight: 500;
		line-height: 20px;
		background-color: var(--green-3);
	}

	.spinner {
		width: 20px;
		height: 20px;
	}
}

a.helplink {
	color: #6ebe49 !important;
	text-decoration: none;
}

a.helplink:hover {
	color: #5ca63a !important;
	// text-decoration: underline;
	cursor: pointer;
}

a.helplink:active {
	color: #438027 !important;
	// text-decoration: underline;
}

.compliance-area {
	margin-bottom: 32px;
	.compliance-notice {
		font-size: 14px;
		height: 20px;
		font-weight: 400;
		color: var(--gray-12);
		.compliance-notice-bold-text {
			font-weight: 500;
		}
	}
}

.compliance-mode-area {
	margin-bottom: 16px;
	.cm-heading {
		color: var(--gray-12);
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 4px;
	}
	.cm-para {
		color: var(--gray-9);
		font-size: 12px;
		font-weight: 400;
	}
}

.form-group {
	.form-label {
		font-size: 14px;
		color: var(--gray-12);
		line-height: 20px;
	}

	.error {
		color: #999999;
		font-size: 14px;
		line-height: 20px;
	}
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
	border-right-color: #4d4d4d;
}

.checks{
	i.icon{
		margin: 3px 5px 0 0;
		font-size: 14px;
	}
}

.create-bucket-title {
	font-size: 20px;
	line-height: 26px;
	font-weight: 500;
	color: var(--gray-14);
}

.bucket-form-title-section {
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0px 24px;

	.bucket-form-close {
		cursor: pointer;
		z-index: 1;
		background: transparent;
		border: 1px solid transparent;
		outline: none;
		padding: 0;
		border-radius: 4px;

		&:focus,
		&:focus-within {
			border: 1px solid var(--gray-13);
		}
	}
}